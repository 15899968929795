import type { AxiosResponse } from 'axios';
import Cookies from 'js-cookie';
import { ACCESS_TOKEN_COOKIE_NAME } from '~/constants/config';
import { getSubId } from '~/utils';
import { defineStore } from 'pinia';
import { wrapPiniaStore } from '@devhacker/shared/utils/wrapPiniaStore';

export const useFoldersStore = wrapPiniaStore(
  defineStore('folders', {
    state: () => ({
      items: [] as Array<IFolderItem>,
      total: 0,
    }),

    actions: {
      async getFolders() {
        const subId = getSubId();
        const { $authApi } = useNuxtApp();
        if (subId) {
          const accessToken = Cookies.get(ACCESS_TOKEN_COOKIE_NAME) as string;

          try {
            const [respFolders, respDefaultFavorites]: Array<AxiosResponse> = await Promise.all([
              $authApi.fetchFolders(subId, accessToken),
              $authApi.getDefaultFavorites(subId, accessToken),
            ]);

            if (respFolders.status === 200 && respDefaultFavorites.status === 200) {
              const folders = respFolders.data.data
                .map((folderItem: any) => ({
                  id: folderItem.id,
                  name: folderItem.name,
                  total: folderItem.favorites.length,
                }))
                .sort((a: IFolderItem, b: IFolderItem) => {
                  if (a.id !== null && b.id !== null) {
                    return b.id - a.id;
                  }
                  return 0;
                });

              const favoritesInFolders = respFolders.data.data.reduce(
                (count: number, folder: any) => {
                  count += folder.favorites.length;
                  return count;
                },
                0,
              );

              const defaultFolder: IFolderItem = {
                id: null,
                name: 'Общее',
                total: respDefaultFavorites.data.meta.total + favoritesInFolders,
              };

              this.setFolders({
                items: [defaultFolder, ...folders],
                total: respFolders.data.meta.total,
              });
            }
          } catch (error) {
            console.error(error);
          }
        }
      },

      async deleteFolder(folder: IFolderItem) {
        const subId = getSubId();
        if (subId) {
          const accessToken = Cookies.get(ACCESS_TOKEN_COOKIE_NAME) as string;
          try {
            const { $authApi } = useNuxtApp();
            return await $authApi.deleteFolder(folder.id, subId, accessToken);
          } catch (error) {
            console.error(error);
          }
        }
      },

      async addFolder(name: string) {
        const subId = getSubId();
        if (subId) {
          const accessToken = Cookies.get(ACCESS_TOKEN_COOKIE_NAME) as string;
          try {
            const { $authApi } = useNuxtApp();
            return await $authApi.addFolder(name, subId, accessToken);
          } catch (error) {
            console.error(error);
          }
        }
      },

      async renameFolder(name: string, folderId: number) {
        const subId = getSubId();
        if (subId) {
          const accessToken = Cookies.get(ACCESS_TOKEN_COOKIE_NAME) as string;
          try {
            const { $authApi } = useNuxtApp();
            return await $authApi.renameFolder({ name, folderId }, subId, accessToken);
          } catch (error) {
            console.error(error);
          }
        }
      },

      setFolders(folders: { items: Array<IFolderItem>; total: number }) {
        // TODO: Check if reactivity working without vue set
        this.items = folders.items;
        this.total = folders.total;
      },

      clearFolders() {
        this.$reset();
      },
    },
  }),
);

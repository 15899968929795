import { getSubId } from '~/utils/index';

const routesNeedCheck = [
  'profile-edit',
  'profile-settings',
  'profile-favorites',
  'profile-notifications',
];

export default defineNuxtPlugin((nuxtApp) => {
  const route = useRoute();
  if (route.name) {
    return;
  }
  if (!routesNeedCheck.includes(route.name)) {
    return;
  }

  const subId = getSubId();

  if (!subId || Number(route.params.userId) !== subId) {
    console.warn('redirect to /');
    location.href = '/';
  }
});

<template>
  <component :is="container">
    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>
  </component>
</template>

<script setup lang="ts">
  import { isDevelopment } from './constants/config';

  // Тут мы включаем layout-ы через NuxtLayout/NuxtPage
  // Однако мы не можем вынести сюда любые стили/компоненты
  // Потому что app.vue общий для сайта и для AMP версии
  // Поэтому тут нужно грузить AppContainer асинхронно
  const AppContainer = defineAsyncComponent(() => import('~/containers/AppContainer.vue'));
  const AmpAppContainer = defineAsyncComponent(() => import('~/containers/AppContainer.amp.vue'));

  const nuxtApp = useNuxtApp();
  const container = computed(() => (nuxtApp.$isAMP ? AmpAppContainer : AppContainer));

  const defaultCoverUrl =
    'https://lifehacker.ru/wp-content/plugins/lh-og-generator/src/PublicArea/static/img/default_cover.jpg';
  const defaultDescription = 'Лайфхакер — советы и лайфхаки, продуктивность, вдохновение, здоровье';

  // Global seo
  useHead({
    htmlAttrs: {
      lang: 'ru-RU',
    },
    title: 'Лайфхакер — советы и лайфхаки, продуктивность, вдохновение, здоровье',
    link: [
      // favicon
      { rel: 'apple-touch-icon', sizes: '180x180', href: '/apple-touch-icon.png?v=2' },
      { rel: 'icon', type: 'image/png', sizes: '32x32', href: '/favicon-32x32.png?v=2' },
      { rel: 'icon', type: 'image/png', sizes: '16x16', href: '/favicon-16x16.png?v=2' },
      { rel: 'shortcut icon', href: '/favicon.ico?v:2' },
      // favicon end

      // Снижает нагрузку на локальный сервер т.к. этот файл
      // обрабатывается через nuxt и не засоряет логи
      { rel: 'manifest', href: isDevelopment ? '' : '/site.webmanifest?v:2' },
      { rel: 'profile', href: 'http://gmpg.org/xfn/11' },
      { rel: 'dns-prefetch', href: '//s.w.org/' },
      {
        rel: 'alternate',
        type: 'application/rss+xml',
        title: 'Лайфхакер &raquo; Лента',
        href: '//lifehacker.ru/feed/',
      },
      { rel: '//api.w.org/', href: '//lifehacker.ru/api/' },
      {
        rel: 'wlwmanifest',
        type: 'application/wlwmanifest+xml',
        href: '//lifehacker.ru/wp-includes/wlwmanifest.xml',
      },
      {
        hid: 'image_src',
        rel: 'image_src',
        href: '//lifehacker.ru/wp-content/plugins/lh-og-generator/src/PublicArea/static/img/default_cover.jpg',
      },
    ],

    meta: [
      { charset: 'utf-8' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1' },

      // отключает изменение html разметки на ios
      { name: 'format-detection', content: 'telephone=no,date=no,address=no,email=no,url=no' },
      { hid: 'description', name: 'description', content: defaultDescription },
      { hid: 'al:ios:app_name', name: 'al:ios:app_name', content: 'Лайфхакер' },
      { name: 'apple-itunes-app', content: 'app-id=943373933' },
      { hid: 'al:ios:app_store_id', name: 'al:ios:app_store_id', content: '943373933' },

      // BEGIN OG
      { hid: 'og:image', property: 'og:image', content: defaultCoverUrl },
      {
        hid: 'og:publisher',
        property: 'og:publisher',
        content: '//www.facebook.com/lifehacker.ru',
      },
      { hid: 'og:site_name', property: 'og:site_name', content: 'Лайфхакер' },
      { hid: 'og:type', property: 'og:type', content: 'website' },
      { hid: 'og:url', property: 'og:url', content: 'https://lifehacker.ru' },
      { hid: 'og:title', property: 'og:title', content: defaultDescription },
      { hid: 'og:description', property: 'og:description', content: defaultDescription },
      // END OG

      { hid: 'twitter:site', name: 'twitter:site', content: '@ru_lh' },
      { hid: 'twitter:creator', name: 'twitter:creator', content: '@ru_lh' },
      { hid: 'msapplication-TileImage', name: 'msapplication-TileImage', content: '#da532c' },
      { hid: 'theme-color', name: 'theme-color', content: '#eb5e42' },
      { hid: 'apple-itunes-app', name: 'apple-itunes-app', content: 'app-id=943373933' },
      { hid: 'telegram:channel', name: 'telegram:channel', content: '@lifehackerru' },
      { hid: 'view-transition', name: 'view-transition', content: 'same-origin' },
    ],
  });
</script>

import {
  createAxiosLikeFetch,
  type AxiosLikeFetchResponse,
} from '@devhacker/shared/libs/fetch/createAxiosLikeFetch';
import { createLoggerInterceptors } from '@devhacker/shared/libs/logger';
import { AUTH_API_URL } from '~/constants/config';
import { TIMEOUT } from '~/constants';
import type { components } from '~/types/services/auth';
import { authErrorHandler } from '@/utils/errorHandlerAuth';
import { getAccessToken } from '~/utils';
import { authRequestInterceptor } from '~/utils/interceptorsRequestAuth';
import type { CheckCodeResponse, RequestCodeResponse } from '~/types/api/auth';

type ApiParams = {
  page?: number;
  limit?: number;
  post_ids?: string;
  filter__is_viewed?: number;
};

export interface IAuthApi {
  fetchFavorites: (params: ApiParams, integerId: number) => Promise<AxiosLikeFetchResponse<object>>;
  fetchFolders: (integerId: number, accessToken: string) => Promise<AxiosLikeFetchResponse<object>>;
  addFolder: (
    name: string,
    integerId: number,
    accessToken: string,
  ) => Promise<AxiosLikeFetchResponse<object>>;
  deleteFolder: (
    folderId: number,
    integerId: number,
    accessToken: string,
  ) => Promise<AxiosLikeFetchResponse<object>>;
  renameFolder: (
    payload: any,
    integerId: number,
    accessToken: string,
  ) => Promise<AxiosLikeFetchResponse<object>>;

  patchFavorite: (
    favoriteId: number,
    folderId: number,
    integerId: number,
    accessToken: string,
  ) => Promise<AxiosLikeFetchResponse<object>>;
  getDefaultFavorites: (
    integerId: number,
    accessToken: string,
  ) => Promise<AxiosLikeFetchResponse<object>>;
  addArticleToFavorites: (
    postId: number,
    folderId: number,
    integerId: number,
    accessToken: string,
  ) => Promise<AxiosLikeFetchResponse<object>>;
  removeArticleFromFavorites: (
    favoriteId: number,
    integerId: number,
    accessToken: string,
  ) => Promise<AxiosLikeFetchResponse<object>>;
  getUserData: (accessToken: string, integerId: number) => Promise<AxiosLikeFetchResponse<object>>;

  patchUserProperty: (integerId: number, payload: any) => Promise<AxiosLikeFetchResponse<object>>;
  patchUser: (integerId: number, payload: any) => Promise<AxiosLikeFetchResponse<object>>;

  removeUserSocialAccount: (
    accessToken: string,
    integerId: number,
    accountName: string,
  ) => Promise<AxiosLikeFetchResponse<object>>;

  removeUserAccount: (accessToken: string, integerId: number, payload: any) => Promise<any>;

  fetchPublicUserData: (integerId: string) => Promise<
    AxiosLikeFetchResponse<{
      data: components['schemas']['UserPublic'];
    }>
  >;
  fetchPublicUserComments: (
    integerId: string,
    params: ApiParams,
  ) => Promise<
    AxiosLikeFetchResponse<{
      data?: CommentItem[];
      meta?: components['schemas']['Meta'];
    }>
  >;
  fetchPublicUserPosts: (
    integerId: string,
    params: ApiParams,
  ) => Promise<
    AxiosLikeFetchResponse<{
      data: IArticle[];
      meta?: components['schemas']['LhBlogMeta'];
      success: boolean;
    }>
  >;
  authGoogleOneTap: (payload: any) => Promise<AxiosLikeFetchResponse<object>>;
  fetchUserLocation: () => Promise<AxiosLikeFetchResponse<object>>;
  requestCode: (email: string) => Promise<RequestCodeResponse>;
  checkCode: (email: string, code: string) => Promise<CheckCodeResponse>;
  logout: () => Promise<any>;
}

export const createAuthApi = ({ $logger, $store, route }: any): IAuthApi => {
  const loggerInterceptors = createLoggerInterceptors($logger, 'pusher');
  const authFetchWithoutAuthInterceptors = createAxiosLikeFetch({
    baseURL: AUTH_API_URL,
    timeout: TIMEOUT,
    credentials: 'include',
    ...loggerInterceptors,
  });
  const authFetch = createAxiosLikeFetch({
    baseURL: AUTH_API_URL,
    timeout: TIMEOUT,
    credentials: 'include',
    onRequest: [loggerInterceptors.onRequest, (config) => authRequestInterceptor(config)],
    onRequestError: [
      loggerInterceptors.onRequestError,
      (config) => authErrorHandler(config, route),
    ],
    onResponse: loggerInterceptors.onResponse,
    onResponseError: [
      loggerInterceptors.onResponseError,
      (config) => authErrorHandler(config, route),
    ],
  });

  return {
    fetchFavorites: (params, integerId) => {
      const url = `/api/v1/users/${integerId}/favorites`;

      return authFetch(url, {
        params,
      });
    },

    fetchFolders: (integerId: number, _accessToken: string) => {
      const url = `api/v1/users/${integerId}/folders`;
      // по умолчанию запрашиваем 100 папок
      // TO DO: переделать на получение партиями
      // по 10 штук (или по количеству умещаемуся в выпадающем меню)
      return authFetch(url, {
        params: {
          page: 0,
          limit: 100,
        },
      });
    },

    // добавить новую папку
    addFolder: (name: string, integerId: number, _accessToken: string) => {
      const url = `api/v1/users/${integerId}/folders`;
      return authFetch(url, { method: 'post', body: { name } });
    },

    // удалить папку
    deleteFolder: (folderId: number, integerId: number, _accessToken: string) => {
      const url = `/api/v1/users/${integerId}/folders/${folderId}`;
      return authFetch(url, { method: 'delete' });
    },

    // переименовать папку
    renameFolder: ({ name, folderId }, integerId: number, _accessToken: string) => {
      const url = `/api/v1/users/${integerId}/folders/${folderId}`;
      return authFetch(url, { method: 'patch', body: { name } });
    },

    // перенести избранное в другую папку
    patchFavorite: (
      favoriteId: number,
      folderId: number,
      integerId: number,
      _accessToken: string,
    ) => {
      const url = `/api/v1/users/${integerId}/favorites/${favoriteId}`;
      return authFetch(url, { method: 'patch', body: { folderId } });
    },

    // получить избранные статьи, находящиеся вне папки
    getDefaultFavorites: (integerId: number, _accessToken: string) => {
      const url = `api/v1/users/${integerId}/favorites`;
      return authFetch(url, {
        params: {
          folder_ids: 0,
        },
      });
    },

    addArticleToFavorites: (
      postId: number,
      folderId: number = 0,
      integerId: number,
      _accessToken: string,
    ) => {
      const url = `/api/v1/users/${integerId}/favorites`;
      return authFetch(url, { method: 'post', body: { postId, folderId } });
    },

    removeArticleFromFavorites: (favoriteId, integerId, _accessToken) => {
      const url = `/api/v1/users/${integerId}/favorites/${favoriteId}`;
      return authFetch(url, { method: 'delete' });
    },

    getUserData: (_accessToken, subId) => {
      const url = `/api/v1/users/${subId}`;
      return authFetch(url, { method: 'patch' });
    },

    patchUserProperty: (integerId, payload) => {
      const url = `/api/v1/users/${integerId}`;
      const body = { [payload.property]: payload.value };

      return authFetch(url, { method: 'patch', body });
    },

    patchUser: (integerId, payload) => {
      const url = `/api/v1/users/${integerId}`;
      return authFetch(url, { method: 'patch', body: payload });
    },

    removeUserSocialAccount: (_accessToken, integerId, accountName) => {
      const url = `/api/v1/users/${integerId}/${accountName}/remove`;
      return authFetch(url, { method: 'patch' });
    },

    removeUserAccount: (_accessToken, integerId, payload) => {
      const url = `/api/v1/users/${integerId}/archive/push`;
      const body = {
        reason_code: payload.code,
        reason_comment: payload.comment,
      };
      return authFetch(url, { method: 'patch', body });
    },

    fetchPublicUserData: (integerId) => {
      const url = `/api/v1/public/users/${integerId}`;
      return authFetchWithoutAuthInterceptors(url);
    },

    fetchPublicUserComments: (integerId, params) => {
      const url = `/api/v1/public/users/${integerId}/comments`;
      return authFetchWithoutAuthInterceptors(url, { params });
    },

    fetchPublicUserPosts: (integerId, params) => {
      const url = `/api/v1/public/users/${integerId}/posts`;
      return authFetchWithoutAuthInterceptors(url, { params });
    },

    authGoogleOneTap: (params: any) => {
      const url = '/id/auth/google/callback';
      return authFetchWithoutAuthInterceptors(url, { params });
    },

    fetchUserLocation() {
      return authFetch('api/v1/auth/location');
    },

    requestCode(email: string) {
      return authFetch('api/v1/email/code/request', { params: { email }, method: 'post' });
    },

    checkCode(email: string, code: string) {
      return authFetch('api/v1/email/code/verify', { params: { email, code }, method: 'post' });
    },

    logout: async () => {
      const accessToken = getAccessToken();

      if (accessToken) {
        try {
          const headers = {
            Authorization: `Bearer ${accessToken}`,
            'x-auth-app': 'web',
          };

          return authFetchWithoutAuthInterceptors('/api/v1/auth/revoke-token', {
            method: 'patch',
            headers,
          });
        } catch (err) {
          console.error(err);
          return `auth.ts logout: ${err}`;
        }
      }

      console.error('Токена не существует');
    },
  };
};

import { createAxiosLikeFetch } from '@devhacker/shared/libs/fetch/createAxiosLikeFetch';
import { createLoggerInterceptors } from '@devhacker/shared/libs/logger';
import { parseData } from '@devhacker/shared/utils/parseData';
import { formattingItemSection, formattingSeoSection } from '../utils/formattingData';
import type { FetchTypeBySlugResponse } from './health/fetchTypeBySlug';
import type { FetchMenusResponse } from './health/fetchMenus';
import type { FetchCategoryResponse } from './health/fetchCategory';
import type { FetchHomeResponse } from './health/fetchHome';
import type { FetchByTagResponse } from './health/fetchByTag';
import type { FetchArticlesResponse } from './health/fetchArticles';
import { WORDPRESS_API_URL } from '~/constants/config';
import { formatToOldApiInterceptor } from '~/utils/content/formatToOldApi';
import { getApiEndpointDependingOnRoute, getParamsByRoute } from '~/utils/apiUtils';
import {
  FetchArticlesSchema,
  FetchByTagSchema,
  fetchCategorySchema,
  FetchHomeSchema,
  FetchMenusSchema,
  FetchSinglePostSchema,
  FetchTypeBySlugSchema,
} from '~/schemas/health/health';

const PATHS = {
  HOME_POSTS: '/home',
  LIST_ARCHIVE: '/list',
  AUTHOR: '/author',
  TOPICS: '/category',
  TAGS: '/tag',
  STREAMS: '/streams',
  TOP: '/top',
  POSTS_BY_DATE: '/posts-by-date',
};

// * временно увеличенный таймаут, т.к бэк лагает на некоторых запросах
const TIMEOUT = 40000;

export const createHealthApi = ({ $logger }: any) => {
  const loggerInterceptors = createLoggerInterceptors($logger, 'health');
  const healthFetch = createAxiosLikeFetch({
    baseURL: `${WORDPRESS_API_URL}/api/health/v1`,
    timeout: TIMEOUT,
    ...loggerInterceptors,
  });

  return {
    async fetchArticles(route: any, page: number, limit?: number) {
      const url = getApiEndpointDependingOnRoute(route, PATHS);
      const params = {
        page,
        limit,
        ...getParamsByRoute(route),
      };
      const response = await healthFetch(url, { params });
      response.data = parseData(response.data, FetchArticlesSchema) as FetchArticlesResponse;
      return formatToOldApiInterceptor(response);
    },
    async fetchHome(limit?: number) {
      const response = await healthFetch('/home', { params: { limit } });

      if (response.status === 200) {
        const parsedContent = [
          {
            id: 1,
            parser_type: 'html_parser',
            text: '',
            type: 'img',
            attributes: {
              decoding: 'async',
              loading: 'lazy',
              class: 'border alignnone wp-image-1776956 size-medium',
              title:
                '\u041C\u0443\u0434\u0440\u043e\u0441\u0442\u044c \u043e\u0442 \u043f\u0435\u0447\u0435\u043d\u044c\u043a\u0438',
              src: 'https://cdn.lifehacker.ru/wp-content/uploads/2024/04/photo_2024-04-22-19.52.29_1713801451-630x630.jpeg',
              alt: '\u041F\u0435\u0447\u0435\u043d\u044c\u043a\u0430 \u0441 \u043f\u0440\u0435\u0434\u0441\u043a\u0430\u0437\u0430\u043d\u0438\u0435\u043c',
              width: '630',
              height: '630',
            },
            children: [],
          },
          {
            id: 2,
            parser_type: 'html_parser',
            text: '\r\n\r\n\u0421\u0435\u0433\u043e\u0434\u043d\u044f \u043f\u0435\u0447\u0435\u043d\u044c\u043a\u0430 \u043f\u043e\u0434\u0430\u0440\u0438\u043b\u0430 \u043d\u0430\u043c \u043c\u043e\u0442\u0438\u0432\u0438\u0440\u0443\u044e\u0449\u0443\u044e \u0446\u0438\u0442\u0430\u0442\u0443. \u0427\u0435\u043c \u043e\u043d\u0430 \u0443\u0434\u0438\u0432\u0438\u0442 \u0432 \u0441\u043b\u0435\u0434\u0443\u044e\u0449\u0438\u0439 \u0440\u0430\u0437 \u2014 \u043d\u0435 \u0437\u043d\u0430\u0435\u0442 \u043d\u0438\u043a\u0442\u043e. \u0427\u0442\u043e\u0431\u044b \u043d\u0435 \u043f\u0440\u043e\u043f\u0443\u0441\u0442\u0438\u0442\u044c \u0435\u0451 \u043f\u043e\u0441\u0442\u044b,\u00a0\u043f\u043e\u0434\u043f\u0438\u0441\u044b\u0432\u0430\u0439\u0442\u0435\u0441\u044c \u043d\u0430 ',
            type: '#text',
            attributes: [],
            children: [],
          },
          {
            id: 3,
            parser_type: 'html_parser',
            text: '',
            type: 'a',
            attributes: {
              href: 'https://t.me/+3o3GPLSyGmM3ZjIy',
              target: '_blank',
              rel: 'noopener',
            },
            children: [
              {
                id: 4,
                parser_type: 'html_parser',
                text: '\u0442\u0435\u043b\u0435\u0433\u0440\u0430\u043c\u2011\u043a\u0430\u043d\u0430\u043b \u041b\u0430\u0439\u0444\u0445\u0430\u043a\u0435\u0440\u0430',
                type: '#text',
                attributes: [],
                children: [],
              },
            ],
          },
          {
            id: 5,
            parser_type: 'html_parser',
            text: '. \u0410 \u0435\u0449\u0451 \u0442\u0430\u043c \u043c\u044b \u043f\u0443\u0431\u043b\u0438\u043a\u0443\u0435\u043c \u0441\u0442\u0430\u0442\u044c\u0438 \u043e\u0431\u043e \u0432\u0441\u0451\u043c, \u0447\u0442\u043e \u0443\u043b\u0443\u0447\u0448\u0430\u0435\u0442 \u0436\u0438\u0437\u043d\u044c, \u0438 \u0434\u043e\u0441\u0442\u0443\u043f\u043d\u043e \u043e\u0442\u0432\u0435\u0447\u0430\u0435\u043c \u043d\u0430 \u0441\u043b\u043e\u0436\u043d\u044b\u0435 \u0432\u043e\u043f\u0440\u043e\u0441\u044b.\r\n\r\n',
            type: '#text',
            attributes: [],
            children: [],
          },
          {
            id: 46,
            parser_type: 'gutenberg_parser',
            text: '',
            type: 'lh__read-also',
            attributes: {
              title:
                '\u0415\u0441\u043b\u0438 \u044f \u0442\u0435\u0431\u0435 \u043d\u0435 \u043d\u0440\u0430\u0432\u043b\u044e\u0441\u044c, \u0438\u0437\u0432\u0438\u043d\u0438, \u044f \u043d\u0435 \u0438\u0441\u043f\u0440\u0430\u0432\u043b\u044e\u0441\u044c',
              emoji: '\uD83D\uDD25',
              items: [
                {
                  title:
                    '\u041A\u0430\u043a \u0438\u0437\u0431\u0430\u0432\u0438\u0442\u044c\u0441\u044f \u043e\u0442 \u043f\u043e\u0442\u0440\u0435\u0431\u043d\u043e\u0441\u0442\u0438 \u0432\u0441\u0435\u043c \u043d\u0440\u0430\u0432\u0438\u0442\u044c\u0441\u044f',
                  url: 'https://pre.lifehacker.ru/vsem-nravitsya/',
                },
                {
                  title:
                    '14 \u043D\u0435\u043e\u0447\u0435\u0432\u0438\u0434\u043d\u044b\u0445 \u043f\u0440\u0438\u0447\u0438\u043d, \u043f\u043e\u0447\u0435\u043c\u0443 \u0432\u044b \u043c\u043e\u0436\u0435\u0442\u0435 \u043d\u0435 \u043d\u0440\u0430\u0432\u0438\u0442\u044c\u0441\u044f \u043b\u044e\u0434\u044f\u043c',
                  url: 'https://pre.lifehacker.ru/neochevidnye-prichiny-antipatii/',
                },
                {
                  title:
                    '8 \u043D\u0430\u0441\u0442\u043e\u0440\u0430\u0436\u0438\u0432\u0430\u044e\u0449\u0438\u0445 \u043f\u0440\u0438\u0437\u043d\u0430\u043a\u043e\u0432, \u0447\u0442\u043e \u0432\u044b \u0441\u043b\u0438\u0448\u043a\u043e\u043c \u0441\u0442\u0430\u0440\u0430\u0435\u0442\u0435\u0441\u044c \u043d\u0440\u0430\u0432\u0438\u0442\u044c\u0441\u044f \u0434\u0440\u0443\u0433\u0438\u043c',
                  url: 'https://pre.lifehacker.ru/chrezmernoe-stremlenie-nravitsya-drugim/',
                },
              ],
            },
            children: [],
          },
        ];
        if (response.data.items.length) {
          response.data.items[0].parsedContent = parsedContent;
          response.data = parseData(response.data, FetchHomeSchema) as FetchHomeResponse;
          return formatToOldApiInterceptor(response);
        }
        return {
          data: [],
        };
      } else {
        // @ts-ignore
        throw new Error(response);
      }
    },
    // include может включать в себя через запятую posts,data,meta,seo
    async fetchCategory(
      slug: string,
      include: string = 'data',
      params: object = {},
    ): Promise<FetchCategoryResponse> {
      const response = await healthFetch(`/category/${slug}?include=${include}`, { params });
      const parsedResponse = parseData(response.data, fetchCategorySchema);
      parsedResponse.items = parsedResponse.items?.map(formattingItemSection);
      if (parsedResponse?.seo) {
        parsedResponse.seo = formattingSeoSection(parsedResponse.seo);
      }

      return parsedResponse;
    },
    async fetchByTag(
      slug: string,
      include: string = 'data',
      params: object = {},
    ): Promise<FetchByTagResponse> {
      const response = await healthFetch(`/tag/${slug}?include=${include}`, { params });
      const parsedResponse = parseData(response.data, FetchByTagSchema);
      parsedResponse.items = parsedResponse.items?.map(formattingItemSection);
      return parsedResponse;
    },
    async fetchSinglePost(slug: string) {
      const response = await healthFetch(`/single-post/${slug}`);
      const validateData = parseData(response.data, FetchSinglePostSchema);
      const transformResponse = {
        ...response,
        data: validateData,
      };
      return formatToOldApiInterceptor(transformResponse, true);
    },
    async fetchTypeBySlug(slug: string): Promise<FetchTypeBySlugResponse> {
      const response = await healthFetch(`/type-by-slug/?slug=${slug}`);
      return parseData(response.data, FetchTypeBySlugSchema);
    },
    async fetchMenus(): Promise<FetchMenusResponse> {
      const response = await healthFetch('/menus');
      return parseData(response.data, FetchMenusSchema);
    },
  };
};

export type IHealthApi = ReturnType<typeof createHealthApi>;

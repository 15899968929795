import { TALKER_API_URL } from '~/constants/config';
import { TIMEOUT } from '~/constants';
import {
  createAxiosLikeFetch,
  type AxiosLikeFetchResponse,
} from '@devhacker/shared/libs/fetch/createAxiosLikeFetch';
import { createLoggerInterceptors } from '@devhacker/shared/libs/logger';

export interface ITalkerApi {
  fetchLastComments: () => Promise<AxiosLikeFetchResponse<object>>;
  fetchCommentsCount: (postIds: Array<number>) => Promise<AxiosLikeFetchResponse<object>>;
  fetchComments: (
    id: number,
    limit: number,
    sortType: 'trending' | 'oldest',
  ) => Promise<AxiosLikeFetchResponse<object>>;
  fetchAmpComments: (
    id: number,
    sortType: 'trending' | 'oldest',
  ) => Promise<AxiosLikeFetchResponse<object>>;
}

export const createTalkerApi = ({ $logger }: any): ITalkerApi => {
  const talkerFetch = createAxiosLikeFetch({
    baseURL: TALKER_API_URL,
    timeout: TIMEOUT,
    headers: {
      'Content-Type': 'application/json',
      'x-app': 'lifehacker-web',
    },
    ...createLoggerInterceptors($logger, 'talker'),
  });

  return {
    fetchComments: (postId, limit = 4, sortType = 'trending') => {
      const url = `/api/v2/comments/post/${postId}?sort=${sortType}&format_ver=1&limit=${limit}`;
      return talkerFetch(url);
    },
    fetchAmpComments: (postId, sortType = 'trending') => {
      const url = `/api/v2/comments/post/${postId}?sort=${sortType}&format_ver=1`;
      return talkerFetch(url);
    },

    fetchLastComments: (limit = 4) => {
      const url = `/api/comments/last?limit=${limit}`;
      return talkerFetch(url);
    },

    fetchCommentsCount: async (postIds) => {
      // НЕ УБИРАТЬ!!!
      // это нужно для того, чтобы гугл-бот не падал с ошибкой

      try {
        const url = '/api/comments/count';
        const response = await talkerFetch(url, { params: { post_ids: postIds.join() } });
        return response;
      } catch (error) {
        console.error('fetchCommentsCount', error);
        return {
          success: true,
          data: [],
        };
      }
    },
  };
};

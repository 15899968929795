import {
  createAxiosLikeFetch,
  type AxiosLikeFetchResponse,
} from '@devhacker/shared/libs/fetch/createAxiosLikeFetch';
import { createLoggerInterceptors } from '@devhacker/shared/libs/logger';
import { parseData } from '@devhacker/shared/utils/parseData';
import { TIMEOUT } from '~/constants';
import { QUIZ_API_URL } from '~/constants/config';
import {
  getQuickPollSchema,
  getQuickPollStatisticsSchema,
  setQuickPollAnswerIdSchema,
  setQuickPollViewedSchema,
} from '~/schemas/quiz/quiz';

export interface IQuizApi {
  getQuickPoll: (quickPollId: number) => Promise<AxiosLikeFetchResponse<object>>;
  getQuickPollStatistics: (quickPollId: number) => Promise<AxiosLikeFetchResponse<object>>;
  setQuickPollAnswerId: (quickPollAnswerId: number) => Promise<AxiosLikeFetchResponse<object>>;
  setQuickPollViewed: (quickPollId: number) => Promise<AxiosLikeFetchResponse<object>>;
}

export const createQuizApi = ({ $logger }: any): IQuizApi => {
  const quizFetch = createAxiosLikeFetch({
    baseURL: QUIZ_API_URL,
    timeout: TIMEOUT,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Accept: '*/*',
    },
    ...createLoggerInterceptors($logger, 'quiz'),
  });

  return {
    getQuickPoll: async (quickPollId: number) => {
      const url = `api/quickpolls/${quickPollId}`;

      const response = await quizFetch(url);
      response.data.data = parseData(response.data.data, getQuickPollSchema);
      return response;
    },
    setQuickPollAnswerId: async (quickPollAnswerId: number) => {
      const url = `api/stats/answer/click/${quickPollAnswerId}`;
      const response = await quizFetch(url, { method: 'post' });
      response.data = parseData(response.data, setQuickPollAnswerIdSchema);
      return response;
    },
    getQuickPollStatistics: async (quickPollId: number) => {
      const url = `api/stats/quickpolls/full/${quickPollId}`;
      const response = await quizFetch(url);
      response.data = parseData(response.data, getQuickPollStatisticsSchema);
      return response;
    },
    setQuickPollViewed: async (quickPollId: number) => {
      const url = `api/stats/quickpolls/view/${quickPollId}`;
      const response = await quizFetch(url, { method: 'post' });
      response.data = parseData(response.data, setQuickPollViewedSchema);
      return response;
    },
  };
};

import { clearUserToken, isRequiresAuth } from '@/utils';
import { leavePrivateChannel } from '~/components/Pusher/websockets-functions';
import { useIndexStore } from '~/store';

export function authErrorHandler(config: any, route: ReturnType<typeof useRoute>) {
  if (config.response) {
    // Запрос был сделан, и сервер ответил кодом состояния
    // который выходит за пределы диапазона 2xx
    switch (config.response.status) {
      case 401:
      case 403:
      case 404:
        clearUserToken();
        useIndexStore().clearUser();
        return isRequiresAuth(route) ? safeRedirect('/', config.response.status) : undefined;
    }
  }

  leavePrivateChannel();
}

function safeRedirect(path: string, code: number) {
  console.warn('[auth error] redirect to', path);
  import.meta.client ? (location.href = path) : navigateTo(path, { redirectCode: code });
}

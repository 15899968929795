import type { NuxtAppOptions } from '@nuxt/types';
import type { Route } from 'vue-router';
import { convertTimezoneOffsetToTimezone } from '@devhacker/shared/utils/date';
import type { IronMaidenApi } from '~/services/ironMaiden';
import type { IWordpressApi } from '~/services/wordpress/wordpressApi';
import { isHealthPath } from '~/utils/router';

interface ARTICLE_ROUTES {
  HOME_POSTS: string;
  LIST_ARCHIVE?: string;
  AUTHOR: string;
  TOPICS: string;
  TAGS: string;
  STREAMS: string;
  TOP: string;
  POSTS_BY_DATE: string;
}

const LEGACY_ARTICLE_ROUTES = [
  'author-all',
  // 'topics-all',
  'tag-all',
  'stream-all',
  'top-all',
  'archive-year',
  'archive-month',
  'archive-day',
];

export const getUrl = (path: string, params: string = ''): string => {
  const removeFirstSlash = (value: string): string =>
    value.startsWith('/') ? value.slice(1) : value;
  return `${path}/${removeFirstSlash(params)}`;
};

export const getArticleFetchApi = (
  route: Route,
  context: NuxtAppOptions,
): IWordpressApi | IronMaidenApi => {
  if (isHealthPath(route.path)) {
    return context.$healthApi;
  }

  return LEGACY_ARTICLE_ROUTES.includes(route.name ?? '')
    ? context.$wordpressApi
    : context.$ironMaidenApi;
};

export function getApiEndpointDependingOnRoute(route: Route, paths: ARTICLE_ROUTES): string {
  const { pathMatch } = route.params;

  switch (route.name) {
    case 'list-all':
      return isHealthPath(route.path)
        ? getUrl(paths.LIST_ARCHIVE as string)
        : getUrl(paths.HOME_POSTS);
    case 'author-all':
      return getUrl(paths.AUTHOR, pathMatch);
    case 'topics-all':
      return getUrl(paths.TOPICS, pathMatch.replace(/[\\/]+$/g, ''));
    case 'tag-all':
      return getUrl(paths.TAGS, pathMatch);
    case 'stream-all':
      return getUrl(paths.STREAMS, pathMatch);
    case 'top-all':
      return getUrl(paths.TOP, pathMatch ? pathMatch.replace(/[\\/]+$/g, '') : 'week');
    case 'archive-year':
      return getUrl(paths.POSTS_BY_DATE, route.params.year);
    case 'archive-month':
      return getUrl(paths.POSTS_BY_DATE, `${route.params.year}/${route.params.month}`);
    case 'archive-day':
      return getUrl(
        paths.POSTS_BY_DATE,
        `${route.params.year}/${route.params.month}/${route.params.day}`,
      );
  }
  return getUrl(paths.HOME_POSTS);
}

export const getParamsByRoute = (route: Route) => {
  switch (route.name) {
    case 'list-all':
      return { route: 'list' };
    case 'archive-day':
    case 'archive-month':
    case 'archive-year': {
      const timezoneOffsetByMinutes = new Date().getTimezoneOffset();
      const timezoneOffset = convertTimezoneOffsetToTimezone(timezoneOffsetByMinutes);

      return { timezoneOffset };
    }
    default:
      return null;
  }
};

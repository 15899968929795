import {
  createAxiosLikeFetch,
  type AxiosLikeFetchResponse,
} from '@devhacker/shared/libs/fetch/createAxiosLikeFetch';
import { createLoggerInterceptors } from '@devhacker/shared/libs/logger';
import { SPECIAL_API_URL } from '~/constants/config';
import { TIMEOUT } from '~/constants';

export interface ISpecialApi {
  askQuestion: (
    user_id: number,
    name: string,
    text: string,
  ) => Promise<AxiosLikeFetchResponse<object>>;
}

export const createSpecialApi = ({ $logger }: any): ISpecialApi => {
  const searchFetch = createAxiosLikeFetch({
    baseURL: SPECIAL_API_URL,
    timeout: TIMEOUT,
    ...createLoggerInterceptors($logger, 'special'),
  });

  return {
    askQuestion: (user_id, name, text) => {
      const url = '/api/asklh/question-post';
      return searchFetch(url, {
        method: 'post',
        body: { user_id, name, text },
      });
    },
  };
};

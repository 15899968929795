import { defineStore } from 'pinia';
import { wrapPiniaStore } from '@devhacker/shared/utils/wrapPiniaStore';

const DEFAULT: RecipesVisibilityData = {
  ingredients: [],
  dishTypes: [],
  cuisines: [],
  keywords: [],
  cookingMethods: [],
};

export const useVisibilityDataStore = wrapPiniaStore(
  defineStore('visibilityData', {
    state: () => ({
      byPath: {} as Record<string, RecipesVisibilityData>,
    }),

    getters: {
      currentRecipesVisibilityData: (state): RecipesVisibilityData => {
        const route = useRoute();
        return state.byPath[route.fullPath] ?? DEFAULT;
      },
    },

    actions: {
      setRecipesVisibilityData(payload: { fullPath: string; data: RecipesVisibilityData }) {
        // TODO: Check if reactivity working without vue set
        this.byPath[payload.fullPath] = payload.data;
      },
    },
  }),
);

import type { RouteRecordNameGeneric } from 'vue-router';

type Fn = (name: string | null | undefined | RouteRecordNameGeneric) => boolean;

export const isHomePage: Fn = (name) => name === 'index';
export const isHomeHealth: Fn = (name) => name === 'health-home';

export const isArticlePage: Fn = (name) =>
  typeof name === 'string' && ['single', 'single-topics'].includes(name);

export const isPreviewPage: Fn = (name) => name === 'preview';

export const isTopPage: Fn = (name) => name === 'top-all';

export const isArchivePage: Fn = (name) => {
  if (typeof name !== 'string') return false;
  return [
    'index',
    'topics-all',
    'author-all',
    'tag-all',
    'list-all',
    'stream-all',
    'top-all',
  ].includes(name);
};

export const isArchiveByDatePage: Fn = (name) => {
  return (
    typeof name === 'string' && ['archive-year', 'archive-month', 'archive-day'].includes(name)
  );
};

export const isPublicProfilePage: Fn = (name) => {
  return typeof name === 'string' && ['profile-posts', 'profile-comments'].includes(name);
};

export const isListAllPage: Fn = (name) => name === 'list-all';

export const isPublicProfilePostsPage: Fn = (name) => name === 'profile-posts';

export const isAmpPage: Fn = (name) => name === 'single-amp';

export const isAmpPageEndsWith: Fn = (path) => path.endsWith('/amp/');

// Рецепты
// Главная страница в рецептах
export const isRecipesMainPage: Fn = (name) => name === 'recipes-main-page';

// внутренняя страница рецепта?
export const isTheRecipePage: Fn = (name) => name === 'TheRecipe';

// амп версия рецепта?
export const isTheRecipeAmpPage: Fn = (name) => name === 'TheRecipeAmp';

// Архивная страница рецепта?
export const isRecipesPage: Fn = (name) => name === 'recipes' || name === 'recipes-with-page';

// Предпросмотр рецепта
export const isRecipePreviewPage: Fn = (name) => name === 'recipe-preview';

// Любая страница рецепта? (кроме поиска)
export const isAnyRecipePage: Fn = (name) => {
  return (
    isTheRecipePage(name) ||
    isRecipesPage(name) ||
    isRecipePreviewPage(name) ||
    isRecipesMainPage(name) ||
    isTheRecipeAmpPage(name)
  );
};

export const isHealthPath: Fn = (path) => typeof path === 'string' && path.startsWith('/health/');

export const isHealthMain: Fn = (name) => name === 'health-home';

export const isHealthArticleOrSubCategoryRoute: Fn = (name) =>
  typeof name === 'string' && name === 'health-article';

export const isHealthCategoryOrPageRoute: Fn = (name) =>
  typeof name === 'string' && name === 'health-section';

export const isHealthAboutProjectPage: Fn = (path) =>
  typeof path === 'string' && path.startsWith('/health/o-proekte/');

export const isHealthExpertsPage: Fn = (path) =>
  typeof path === 'string' && path.startsWith('/health/eksperty/');

export const getQueryValue = <T>(value: T | T[]) =>
  (Array.isArray(value) ? value.at(0) : value) ?? '';

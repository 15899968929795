import type { OldUserData } from '~/types';
import { isObject } from '~/utils';

type OldAvatar = {
  size: string;
  url: string;
};

export const normalizeUserData = (data: IUser): OldUserData => {
  const def: OldUserData = {
    displayName: 'unknown',
    name: 'unknown',
    email: 'unknown',
    archivedAt: null,
    integerId: null,
    sub: null,
    fbId: null,
    vkId: null,
    avatars: {},
    avatar: null,
  };

  if (!isObject(data)) {
    return def;
  }

  const avatars: OldUserData['avatars'] = {};
  if (data.avatars && data.avatars.length) {
    data.avatars.forEach((avatar: IUserAvatarBeforeNormalization): void => {
      avatar && (avatars[avatar.size] = avatar.url);
    });
  }

  const notFbAvatar: string | null = avatars.middle || avatars.small;
  const avatar: string | null = data.fb_id
    ? `https://graph.facebook.com/${data.fb_id}/picture?type=large`
    : notFbAvatar;

  return {
    avatar,
    avatars,
    displayName: data.display_name || def.displayName,
    name: data.display_name || def.name,
    email: data.email || def.email,
    archivedAt: data.archived_at || def.archivedAt,
    integerId: data.integer_id || def.integerId,
    sub: data.integer_id || def.sub,
    fbId: data.fb_id || def.fbId,
    vkId: data.vk_id || def.vkId,
  };
};

import { stringify } from 'qs';

import { createLoggerInterceptors } from '@devhacker/shared/libs/logger';
import { parseData } from '@devhacker/shared/utils/parseData';
import type { FetchMenusResponse } from './health/fetchMenus';
import { WORDPRESS_API_URL } from '~/constants/config';
import { TIMEOUT } from '~/constants';
import type { paths as RESPONSE_TYPES } from '~/types/services/wordpress';
import type { WidgetName } from '~/constants/widgets';
import { getApiEndpointDependingOnRoute, getParamsByRoute } from '~/utils/apiUtils';
import { fetchRecipesSchema } from '~/schemas/wordpress/fetchRecipesSchema';
import { FetchMenusSchema } from '~/schemas/health/health';
import {
  type AxiosLikeFetchResponse,
  createAxiosLikeFetch,
} from '@devhacker/shared/libs/fetch/createAxiosLikeFetch';

const URL_PREFIX = '/api/20/v1';

const PATHS = {
  HOME_POSTS: '/home-posts',
  AUTHOR: '/author',
  TOPICS: '/topics',
  TAGS: '/tags',
  STREAMS: '/streams',
  TOP: '/top',
  POSTS_BY_DATE: '/posts-by-date',
  SINGLE_POST: '/single-post',
  PREVIEW: '/preview',
  SEO: '/seo',
  MAIN_MENU: '/main-menu',
  SCRIPTS: '/scripts',
  FOOTERS: '/footers',
  MENUS: '/menus',
  WIDGETS: '/widgets',
  ADSFORM: '/ads-form',
};

const removeFirstSlash = (value: string): string =>
  value.startsWith('/') ? value.slice(1) : value;
const getUrl = (path: string, params: string = '', prefix: string = URL_PREFIX): string => {
  return `${prefix}${path}/${removeFirstSlash(params)}`;
};

type SubscribeToNewsletterResponseData =
  | {
      success: true;
      data: {
        result: true;
      };
    }
  | {
      success: false;
      data: {
        is_error: true;
        message: string;
      };
    };

type TFetchRecipes = {
  recipes: RecipeFromServer[];
  title?: string;
  totalPage?: number;
  totalRecipes?: number;
  currentPage?: number;
  isUserLoggedIn?: boolean;
  taxonomies?: {
    cuisines?: number[];
    dishTypes?: number[];
    cookingMethods?: number[];
    ingredients?: number[];
    keywords?: number[];
  };
  seo?: RecipesSeo;
};

// TODO: удалить отдельное объявление и перенеси внутрь
export interface IWordpressApi {
  fetchArticles: (
    route: any,
    page: number,
    limit?: number,
  ) => Promise<AxiosLikeFetchResponse<object>>;
  fetchHeadScriptsFromServer: (path: string) => Promise<AxiosLikeFetchResponse<object>>;
  fetchSeo: (
    routePath: string,
    page: number,
    limit: number,
  ) => Promise<AxiosLikeFetchResponse<object>>;
  fetchAppBoxData: (articleId: number, appUrl: string) => Promise<AxiosLikeFetchResponse<object>>;
  fetchWidgets: (
    includes: WidgetName[],
  ) => Promise<
    AxiosLikeFetchResponse<
      RESPONSE_TYPES['/widgets']['get']['responses']['200']['content']['application/json']
    >
  >;
  sendTypoData: (data: { action: string }) => Promise<AxiosLikeFetchResponse<object>>;
  fetchRecommendedArticles: (
    articleId: number,
    limit: number,
  ) => Promise<
    AxiosLikeFetchResponse<{
      items: IArticle[];
    }>
  >;
  subscribeToNewsletter: (
    action: string,
    type: string,
    email: string,
  ) => Promise<AxiosLikeFetchResponse<SubscribeToNewsletterResponseData>>;
  fetchRecipesCount: (pathname: string) => Promise<AxiosLikeFetchResponse<object>>;
  fetchFilterMenus: () => Promise<AxiosLikeFetchResponse<object>>;
  fetchMenus: () => Promise<FetchMenusResponse>;
  fetchFilterSettings: (pathname: string) => Promise<AxiosLikeFetchResponse<object>>;
  searchIngredients: (searchValue: string) => Promise<AxiosLikeFetchResponse<object>>;
  fetchRecipes: (page: number, pathname: string, limit?: number) => Promise<TFetchRecipes>;
  fetchRecipe: (pathname: string) => Promise<AxiosLikeFetchResponse<FullRecipeFromServer>>;
  fetchRecipePreview: (
    id: number,
    type: 'public-recipe-preview' | 'admin-recipe-preview',
    hash?: string,
  ) => Promise<
    AxiosLikeFetchResponse<{
      data: {
        recipe: Omit<FullRecipeFromServer, 'url' | 'commentsEnabled'>;
        commentsEnabled: boolean;
      };
    }>
  >;
  fetchRecipeWidgets: (
    pathname: string,
    includes: WidgetName[],
  ) => Promise<
    AxiosLikeFetchResponse<{
      'recipes-above-header': WidgetInRecipes[];
      'recipes-below-header': WidgetInRecipes[];
      'recipes-list-sidebar': WidgetInRecipes[];
      'recipe-sidebar': WidgetInRecipes[];
      'recipes-above-comments': WidgetInRecipes[];
      'recipes-below-comments': WidgetInRecipes[];
      'recipes-below-ingredients': WidgetInRecipes[];
      'recipes-between-posts': WidgetInRecipes[];
      visibilityData?: RecipesVisibilityData;
    }>
  >;
  fetchRecipeScripts: () => Promise<AxiosLikeFetchResponse<Array<TWidget>>>;
  defineIpv: () => Promise<AxiosLikeFetchResponse<any>>;
  fetchIsLoggedInWordpress: () => Promise<AxiosLikeFetchResponse<{ logged: boolean }>>;
}

type Params = {
  $logger: any;
  version: string;
};

export const createWordpressApi = ({ $logger, version }: Params): IWordpressApi => {
  const wordpressFetch = createAxiosLikeFetch({
    baseURL: WORDPRESS_API_URL,
    timeout: TIMEOUT,
    ...createLoggerInterceptors($logger, 'wordpress'),
  });

  const defaultParams = version.length ? { version } : {};

  const getParams = (params: any = {}) => ({ ...defaultParams, ...params });

  return {
    fetchArticles: (route, page, limit = 30) => {
      const url = getUrl(getApiEndpointDependingOnRoute(route, PATHS));

      const params = getParams({
        page,
        limit,
        ...getParamsByRoute(route),
      });

      return wordpressFetch(url, { params });
    },

    fetchSeo: (routePath, page, limit) => {
      const url = getUrl(PATHS.SEO, routePath);

      const params = getParams({
        page,
        limit,
      });
      return wordpressFetch(url, { params });
    },

    fetchHeadScriptsFromServer: (path) => {
      const url = getUrl(PATHS.SCRIPTS);
      return wordpressFetch(url, { params: getParams({ path }) });
    },

    fetchAppBoxData: (postId, appUrl) => {
      const url = '/wp-admin/admin-ajax.php?action=refresh-appbox';
      return wordpressFetch(url, {
        method: 'POST',
        body: { postId, url: appUrl },
      });
    },

    // * valibot
    fetchMenus() {
      const url = getUrl(PATHS.MENUS);
      return wordpressFetch(url).then(({ data }) => parseData(data, FetchMenusSchema));
    },

    sendTypoData(data) {
      const url = '/wp-admin/admin-ajax.php';
      return wordpressFetch(url, {
        method: 'POST',
        body: stringify(data),
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      });
    },

    fetchWidgets(includes: WidgetName[]) {
      const url = getUrl(PATHS.WIDGETS);
      return wordpressFetch(url, {
        params: getParams({
          includes: includes.join(','),
        }),
      });
    },

    fetchRecommendedArticles: (articleId, limit) => {
      const url = `/api/20/v1/recommended-posts/${articleId}`;
      return wordpressFetch(url, { params: getParams({ limit }) });
    },

    subscribeToNewsletter(action, type, email) {
      const headers = { 'Content-Type': 'application/x-www-form-urlencoded' };
      const url = '/wp-admin/admin-ajax.php';
      const body = stringify({
        action,
        type,
        email,
        agree: true,
      });
      return wordpressFetch(url, { method: 'POST', body, headers });
    },

    fetchRecipesCount: (pathname) => {
      const url = `/api/recipes/v1/query${pathname}?count`;
      return wordpressFetch(url);
    },

    fetchFilterMenus: () => {
      const url = '/api/recipes/v1/filter/menu';
      return wordpressFetch(url);
    },

    fetchFilterSettings: (pathname) => {
      const url = `/api/recipes/v1/filter/route${pathname}`;
      return wordpressFetch(url);
    },

    searchIngredients: (searchValue) => {
      const url = `/api/recipes/v1/filter/ingredients/${searchValue}`;
      return wordpressFetch(url);
    },

    // * valibot
    fetchRecipes: async (page, pathname, limit = 25) => {
      // encode на случай если путь содержин латиницу
      const url = encodeURI(`/api/recipes/v1/query${pathname}`);
      const response = await wordpressFetch(url, {
        params: getParams({
          page,
          limit,
        }),
      });

      const parsedResponse = parseData(response.data, fetchRecipesSchema) as TFetchRecipes;

      return parsedResponse;
    },

    fetchRecipe: (pathname) => {
      const url = `/api/recipes/v1/recipe/${pathname}`;
      return wordpressFetch(url);
    },

    fetchRecipePreview: (id, type, hash) => {
      const url = `/api/recipes/v1/query/recipe-preview/${id}`;
      return wordpressFetch(url, {
        headers: {
          ...useRequestHeaders(),
        },
        params: getParams({
          preview_type: type,
          ...(hash
            ? {
                preview_type_hash: hash,
              }
            : {}),
        }),
      });
    },

    // * valibot
    fetchRecipeWidgets: (pathname, includes) => {
      const url = '/api/recipes/v1/widgets';
      return wordpressFetch(url, {
        params: getParams({
          pathname,
          includes: includes.join(','),
        }),
      });
    },

    fetchRecipeScripts: () => {
      const url = '/api/recipes/v1/scripts/';
      return wordpressFetch(url);
    },

    defineIpv: () => {
      const url = '/api/whois';
      return wordpressFetch(url);
    },

    fetchIsLoggedInWordpress() {
      return wordpressFetch('/api/logged');
    },
  };
};

// Это глобальный middleware. Почему? Потому что в options api какого-то
// хуя не работает обычный, так-же не работает fetch
// поэтому временно это глобальный middleware, потом перепишем на хук

import { getArticleFetchApi } from '~/utils/apiUtils';
import { getActivePage } from '@/utils';
import { isArchivePage } from '~/utils/router';
import { usePageStore } from '~/store/pagesData';
import { useSeoStore } from '~/store/seo';
import { useAdfoxStore } from '~/store/adfoxParams';
import type { RouteLocationNormalizedGeneric } from 'vue-router';
import type { IWordpressApi } from '~/api/wordpress';

export default defineNuxtRouteMiddleware(async (to) => {
  if (import.meta.client) return;
  if (!isArchivePage(to?.name as string)) return;

  const app = useNuxtApp();
  const { $wordpressApi, $logger, runWithContext } = app;

  const log = $logger.withScope('🏛️ fetchArchivePageData');
  const pageStore = usePageStore();
  const seoStore = useSeoStore();
  const adfoxStore = useAdfoxStore();

  const pagesDataRoute = to.fullPath.replace(to.hash, '');
  const isLoadedArticlesForCurrentRoute = pageStore.pagesData[pagesDataRoute];

  log('isLoadedArticlesForCurrentRoute', Boolean(isLoadedArticlesForCurrentRoute));
  if (isLoadedArticlesForCurrentRoute) return;

  const pageNumber = getActivePage(to);
  log('pageNumber', pageNumber);

  const fetchApi = getArticleFetchApi(to, app);
  const fetchArticles = fetchApi.fetchArticles(to, pageNumber);

  const isList = to.name === 'list-all';
  const articlesWithSeoPromise = Promise.all([
    fetchArticles,
    isList ? Promise.resolve({}) : fetchSeo(to, $wordpressApi),
  ]);

  try {
    const [{ status, data }, seo] = await articlesWithSeoPromise;

    log('data', data);
    if (seo) log('seo', seo);

    if (status !== 200) {
      console.error('fetchArchivePageData response status', status);
      throw createError({ statusCode: status, data: data });
    }

    // редирект на канонический url, у главной нет data.url и редирект зацикливается
    if (data.url && data.url !== to.path) {
      return runWithContext(() => navigateTo(data.url, { redirectCode: 301, external: true }));
    }

    if (to.name === 'author-all' && status === 200 && data?.items?.[0]?.author?.integer_id) {
      console.warn('redirect to', data.items[0].author.profile);
      return runWithContext(() =>
        navigateTo(data.items[0].author.profile, { redirectCode: 301, external: true }),
      );
    }

    const { items, totalPages, title, adfoxParams, type, id, icon, breadcrumb } = data;
    // console.info('🔥 data < middleware/fetchArchivePageData.global.ts', data);

    pageStore.addArchiveData({
      path: pagesDataRoute,
      data: {
        type,
        id,
        totalPages,
        articles: items,
        title: title || '',
        icon,
        breadcrumb,
      },
    });

    const seoCorrect = seo?.title ? seo : data.seo;
    seoStore.addSeoByPath(pagesDataRoute, seoCorrect);
    adfoxStore.addAdfoxParamsByPath(pagesDataRoute, adfoxParams);
  } catch (err: any) {
    console.info('🔥 err < middleware/fetchArchivePageData.global.ts', err);

    if (err.statusCode === 301 && err.data?.data?.location) {
      console.warn('redirect to 301', err.data?.data?.location);
      return runWithContext(() =>
        navigateTo(err.data?.data?.location, { redirectCode: 301, external: true }),
      );
    }

    return abortNavigation(err);
  }
});

function fetchSeo(to: RouteLocationNormalizedGeneric, $wordpressApi: IWordpressApi): Promise<any> {
  const { page = 1, limit = 30 } = to.query;
  return $wordpressApi.fetchSeo(to.path, page as number, limit as number).catch(() => {});
}

import { formattingIMApitoDefaultApi, formatIMArticleList } from '../formattingData';
import type { AxiosLikeFetchResponse } from '@devhacker/shared/libs/fetch/createAxiosLikeFetch';

export const formatToOldApiInterceptor = (
  response: AxiosLikeFetchResponse<{ data: unknown }>,
  isSinglePost?: boolean,
) => {
  if (isSinglePost) {
    return { ...response, data: formattingIMApitoDefaultApi(response.data) };
  }

  return { ...response, data: formatIMArticleList(response.data) };
};

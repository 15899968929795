<template>
  <DefaultErrorContaner
    :is-health="isHealth"
    :error="error"
  />
</template>

<script setup lang="ts">
  import { isHealthPath } from './utils/router';

  const DefaultErrorContaner = defineAsyncComponent(
    () => import('~/components/DefaultErrorContaner.vue'),
  );

  const { error } = defineProps({
    error: {
      required: true,
      type: Object,
    },
  });

  const route = useRoute();
  const isHealth = computed(() => isHealthPath(route.path));

  useServerSeoMeta({
    robots: 'noindex, nofollow',
  });

  const ssrContext = useNuxtApp().ssrContext;
  if (ssrContext) {
    ssrContext.event.node.res.statusCode = error.statusCode === 404 ? 404 : 503;
  }

  if (isHealth.value) {
    setPageLayout('health');
  }
</script>

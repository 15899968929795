import {
  createAxiosLikeFetch,
  type AxiosLikeFetchResponse,
} from '@devhacker/shared/libs/fetch/createAxiosLikeFetch';
import { createLoggerInterceptors } from '@devhacker/shared/libs/logger';
import { FULL_SEARCH_API_URL, WORDPRESS_API_URL } from '~/constants/config';
import { TIMEOUT } from '~/constants';

export interface ISearchApi {
  getSearchItemsByIds: (includes: Array<number>) => Promise<AxiosLikeFetchResponse<object>>;
}

export const createSearchApi = ({ $logger }: any): ISearchApi => {
  const searchFetch = createAxiosLikeFetch({
    baseURL: FULL_SEARCH_API_URL,
    timeout: TIMEOUT,
    ...createLoggerInterceptors($logger, 'search'),
  });

  return {
    getSearchItemsByIds: (includes: Array<number>) => {
      const url = '/fullsearch/solr/postId';
      const params = {
        includes: includes.join(),
        page: 1,
        limit: includes.length,
      } as any;

      return searchFetch(url, { params });
    },
  };
};

import { authErrorHandler } from './errorHandlerAuth';
import { getAccessToken, isValidAccessToken, setAccessToken, getSubId } from '@/utils/index';
import { TIMEOUT } from '~/constants';
import { normalizeUserData } from '~/libs/normalizeUserData';

import { AUTH_API_URL } from '~/constants/config';
import { useIndexStore } from '~/store';
import { useTalkerStore } from '@devhacker/shared/plugins/talker/store/talker';
import { createAxiosLikeFetch } from '@devhacker/shared/libs/fetch/createAxiosLikeFetch';

function refreshToken() {
  const accessToken = getAccessToken();

  const authFetch = createAxiosLikeFetch({
    baseURL: `${AUTH_API_URL}`,
    timeout: TIMEOUT,
    credentials: 'include',
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'x-auth-app': 'web',
    },
  });

  return authFetch;
}

export async function authRequestInterceptor(config: any) {
  const route = useRoute();
  const indexStore = useIndexStore();
  const talkerStore = useTalkerStore();

  const accessToken = getAccessToken();
  const isSendNow = indexStore.isSendRefreshToken;

  if (!isValidAccessToken() && accessToken && !isSendNow) {
    indexStore.setUserRTAction(true);

    try {
      const response = await refreshToken()('/api/v1/auth/refresh-token', { method: 'patch' });
      const newAccessToken = response?.data?.data?.access_token;
      setAccessToken(newAccessToken);
      indexStore.setUserRTAction(false);

      const userDataCurrent = indexStore.user;

      if (!userDataCurrent) {
        await indexStore.fetchUser(getSubId(), true);
        talkerStore.setUser(normalizeUserData(userDataCurrent));
      }
    } catch (error) {
      indexStore.setUserRTAction(false);
      authErrorHandler(error, route);
    }
  }
  // Подробнее об этом костыле в репозитории bh-frontend api/README-USERS.MD
  async function delay(): Promise<void> {
    await new Promise<void>((resolve) => {
      let timer = 0;
      const interval = setInterval(checkIsSendNow, 500);

      function checkIsSendNow() {
        const isSendNowActual = indexStore.isSendRefreshToken;
        if (!isSendNowActual) {
          clearInterval(interval);
          resolve();
        }

        if (timer >= 5) {
          clearInterval(interval);
          resolve();
        }
        timer += 0.5;
      }
    });
  }

  await delay();

  const accessTokenActual = getAccessToken();

  // работает только если делать через spread
  config.options.headers = {
    ...config.options.headers,
    'x-auth-app': 'web',
    ...(accessTokenActual ? { Authorization: `Bearer ${accessTokenActual}` } : {}),
  };
}

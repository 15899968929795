import type { BrowserInfo } from 'detect-browser';
import { PUSH_API_URL, WORDPRESS_API_URL } from '~/constants/config';
import { TIMEOUT } from '~/constants';
import type { ISubscription } from '~/plugins/push-notifications.client';

import {
  createAxiosLikeFetch,
  type AxiosLikeFetchResponse,
} from '@devhacker/shared/libs/fetch/createAxiosLikeFetch';
import { createLoggerInterceptors } from '@devhacker/shared/libs/logger';

export interface IPushApi {
  addSubscription: (
    { token, type, auth, p256dh }: ISubscription,
    { name, version, os }: BrowserInfo,
  ) => Promise<AxiosLikeFetchResponse<object>>;
  subscribe: (token: string) => Promise<AxiosLikeFetchResponse<{ success: boolean }>>;
  unsubscribe: (token: string) => Promise<AxiosLikeFetchResponse<object>>;
  getDeviceInfo: (token: string) => Promise<AxiosLikeFetchResponse<object>>;
  sendTestPush: (token: string) => Promise<AxiosLikeFetchResponse<object>>;
}

export const createPushApi = ({ $logger }: any): IPushApi => {
  const pushFetch = createAxiosLikeFetch({
    baseURL: PUSH_API_URL,
    timeout: TIMEOUT,
    ...createLoggerInterceptors($logger, 'push'),
  });

  return {
    addSubscription: ({ token, type, auth, p256dh }, { name, version, os }) => {
      const url = '/api/add-sub';
      const body = {
        token,
        type,
        auth,
        p256dh,
        user_agent: `${name} ${version} ${os}`,
      };
      return pushFetch(url, { method: 'post', body });
    },

    subscribe: (token) => {
      const url = '/api/subscribe';
      return pushFetch(url, { method: 'post', body: { token } });
    },

    unsubscribe: (token) => {
      const url = '/api/unsubscribe';
      return pushFetch(url, { method: 'post', body: { token } });
    },

    getDeviceInfo: (token) => {
      const url = '/api/get-device';
      return pushFetch(url, { method: 'post', body: { token } });
    },

    sendTestPush: (token) => {
      const url = '/api/send-to-token';
      const body = {
        token,
        title: document.title || 'test title',
        body: document.title || 'test body',
        wp_post_id: 1,
        link: 'https://lifehacker.ru/kak-ukrepit-zdorove/?utm_source=site&utm_campaign=push_desktop&utm_medium=push',
        picture: 'https://i.imgur.com/PQmkAEU.png',
      };
      return pushFetch(url, { method: 'post', body });
    },
  };
};

// модуль для UI элементов: шапки, меню и тд
// TODO: перенести сюда все что к этому относится

import PageScroll from '~/libs/PageScroll';
import { defineStore } from 'pinia';
import { wrapPiniaStore } from '@devhacker/shared/utils/wrapPiniaStore';

export interface UIState {
  isHeaderShow: boolean;
  isPrimaryMenuShow: boolean;
  isModalDeleteAccountShow: boolean;
}

export const useUIStore = wrapPiniaStore(
  defineStore('ui', {
    state: () => ({
      isHeaderShow: true,
      isPrimaryMenuShow: true,
      isModalDeleteAccountShow: false,
      isSafariPushDialogShow: false,
    }),

    getters: {},

    actions: {
      hideHeader() {
        this.isHeaderShow = false;
      },

      showHeader() {
        this.isHeaderShow = true;
      },

      hidePrimaryMenu() {
        this.isPrimaryMenuShow = false;
      },

      showPrimaryMenu() {
        this.isPrimaryMenuShow = true;
      },

      hideModalDeleteAccount() {
        this.isModalDeleteAccountShow = false;
      },

      showSafariPushDialog() {
        this.isSafariPushDialogShow = true;
        PageScroll.disable();
      },

      hideSafariPushDialog() {
        this.isSafariPushDialogShow = false;
        PageScroll.enable();
      },

      showModalDeleteAccount() {
        this.isModalDeleteAccountShow = true;
      },
    },
  }),
);

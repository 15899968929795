import { normalizeUserData } from '~/libs/normalizeUserData';
import {
  connectPrivateChannel,
  fetchUnviewedNotificationsCount,
} from '~/components/Pusher/websockets-functions';
import { useIndexStore } from '~/store';
import { _getSubId, isRequiresAuth, setAccessToken, getAccessToken } from '~/utils';
import { useTalkerStore } from '@devhacker/shared/plugins/talker/store/talker';

// TODO NUXT3: типизировать $store
export default defineNuxtPlugin(() => {
  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  const route = useRoute();
  const indexStore = useIndexStore();

  function authenticatedCallback(e: MessageEvent | CustomEvent) {
    let accessToken = '';

    if (e.type === 'message') {
      const event = e as MessageEvent;
      // ждем строку, чтобы сделать JSON,
      // поэтому отфильтровываем все остальное
      if (typeof event.data !== 'string') {
        return;
      }

      // игнорим события метрики
      if (event.data.startsWith('__')) {
        return;
      }

      try {
        const data: IAccessTokenData = JSON.parse(event.data);
        accessToken = data.accessToken;
        setAccessToken(accessToken);
      } catch (error) {}
    } else if (e.type === 'googleOneTapAuth') {
      const event = e as CustomEvent;
      accessToken = event.detail.accessToken;

      setAccessToken(accessToken);
    } else {
      return;
    }

    if (!accessToken) {
      return;
    }

    try {
      const user = indexStore.user;

      const subId = _getSubId(accessToken);

      if (!accessToken || !subId) {
        return;
      }

      fetchUserData().then(() => {
        if (isRequiresAuth(route)) {
          console.warn('redirect to /');
          location.href = '/';
          return;
        }

        if (!user) {
          connectPrivateChannel();
          fetchUnviewedNotificationsCount();
        }
      });
    } catch (error) {
      console.error(error);
    }
  }

  async function fetchUserData(): Promise<any> {
    const accessToken = getAccessToken();
    const subId = _getSubId(accessToken);
    const response = await indexStore.fetchUser(subId).catch((error: any) => {
      if (error.response.status === 403) {
        indexStore.clearUserAndRelatedData();

        if (isRequiresAuth(route)) {
          console.warn('redirect to /');
          location.href = '/';
          return;
        }
      }
    });

    const userInStore = indexStore.user;
    userInStore && useTalkerStore().setUser(normalizeUserData(userInStore));

    // Delayed login action
    indexStore.afterLoginAction && indexStore.afterLoginAction();

    return response;
  }

  async function Initialize(): Promise<void> {
    indexStore.fetchIsWpLoggedIn();
    indexStore.fetchUserLocation();

    window.addEventListener(
      'message',
      (e) => {
        authenticatedCallback(e);
      },
      false,
    );

    window.addEventListener(
      'googleOneTapAuth',
      (e) => {
        authenticatedCallback(e as CustomEvent);
      },
      false,
    );

    const accessToken = getAccessToken();
    const subId = _getSubId(accessToken);

    // авторизация по существующей cookie
    if (accessToken && subId) {
      fetchUserData().then(() => {
        connectPrivateChannel();
        fetchUnviewedNotificationsCount();
      });
    } else {
      if (isRequiresAuth(route)) {
        console.warn('redirect to /');
        location.href = '/';
        return;
      }
    }
  }

  onNuxtReady(async () => {
    Initialize();
  });
});

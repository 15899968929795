import type { RouteRecordNameGeneric } from 'vue-router';
import separateScriptsAndContent from '~/libs/separateScriptsAndContent';
import { getUniqueArrayByProp } from '~/utils/arrays';
import { trimWpDomainInPlace } from '~/utils/trimWpDomain';
import { defineStore } from 'pinia';
import { wrapPiniaStore } from '@devhacker/shared/utils/wrapPiniaStore';

type ArticlePageDataItem = {
  timestamp: number;
  article: ISingleArticle | null;
  visibilityData: readonly VisibilityCondition[];
};

type ArchivePageDataItem = {
  timestamp: number;
  articles: IArticle[];
  title: string;
  totalPages: number;
  visibilityData: readonly VisibilityCondition[];
  icon: string;
  breadcrumb?: IArticleCategory[];
};

export type PagesData = {
  [propName: string]: ArticlePageDataItem | ArchivePageDataItem | undefined;
};

type ArticlePayload = {
  article: ISingleArticle;
};
type ArchivePayload = {
  articles: IArticle[];
  totalPages: number;
  title: string;
  type?: 'category' | 'spec_projects';
  id?: number;
  icon: string;
  breadcrumb?: IArticleCategory[];
};

const isArchivePage = (
  item?: ArticlePageDataItem | ArchivePageDataItem,
): item is ArchivePageDataItem => (item as ArchivePageDataItem)?.articles !== undefined;

const isArticlePage = (
  item?: ArticlePageDataItem | ArchivePageDataItem,
): item is ArticlePageDataItem => (item as ArticlePageDataItem)?.article !== undefined;

export const usePageStore = wrapPiniaStore(
  defineStore('pageStore', {
    state: () => ({
      pagesData: {} as PagesData,
    }),

    getters: {
      currentPageData: (state) => {
        const route = useRoute();
        const pagesDataRoute = route.fullPath.replace(route.hash, '');
        return state.pagesData[pagesDataRoute];
      },
      currentArticles(): IArticle[] {
        const pageData = this.currentPageData;

        if (!isArchivePage(pageData)) return [];

        return pageData?.articles ?? [];
      },
      currentArticle(): ISingleArticle | null {
        const pageData = this.currentPageData;
        if (!isArticlePage(pageData)) return null;
        return pageData.article ?? null;
      },
      currentTitle(): string {
        const pageData = this.currentPageData;
        if (!isArchivePage(pageData)) return '';
        return pageData.title ?? '';
      },
      currentIcon(): string {
        const pageData = this.currentPageData;
        if (!isArchivePage(pageData)) return '';
        return pageData.icon ?? '';
      },
      currentTotalPages(): number {
        const pageData = this.currentPageData;
        if (!isArchivePage(pageData)) return 0;
        return pageData.totalPages ?? 0;
      },
      isJumperPost(): boolean {
        return this.currentArticle?.meta?.jumperScroller?.isActive ?? false;
      },
      isAdvertisingPost(): boolean {
        return this.currentArticle?.isAdvertising ?? false;
      },
      isPromo(): boolean {
        return this.currentArticle?.isPromo ?? false;
      },
      isTemplateTableOfContents(): boolean {
        return this.currentArticle?.template === 'toc';
      },
      isTemplateBroadside(): boolean {
        return this.currentArticle?.template === 'broadside';
      },
      isArticleTypePost(): boolean {
        return this.currentArticle?.type === 'post';
      },
      isArticleTypePage(): boolean {
        return this.currentArticle?.type === 'page';
      },
      currentVisibilityData(): readonly VisibilityCondition[] {
        return this.currentPageData?.visibilityData ?? [];
      },
    },

    actions: {
      getVisibilityDataForArticle(
        article: ISingleArticle,
        routeName: RouteRecordNameGeneric,
      ): VisibilityCondition[] {
        const visibilityData: VisibilityCondition[] = [];
        if (article.type === 'page') {
          visibilityData.push({
            type: 'post_type' as unknown as VisibilityCondition['type'],
            value: 'page',
          });
        } else if (article.isAdvertising) {
          visibilityData.push({
            type: 'post_type' as unknown as VisibilityCondition['type'],
            value: 'partner',
          });
        }
        article.categories.forEach((category) => {
          const correctCategory = routeName === 'health-article' ? 'category-health' : 'category';
          visibilityData.push({
            type: correctCategory as unknown as VisibilityCondition['type'],
            id: +category.id,
          });
        });
        article.tags.forEach((tag) => {
          visibilityData.push({
            type: 'post_tag' as unknown as VisibilityCondition['type'],
            id: tag.id,
          });
        });
        return visibilityData;
      },

      addArticleData(payload: { path: string; data: ArticlePayload; routeName: string }) {
        const { path, data, routeName } = payload;
        const { article } = data;

        const contentAndScripts = separateScriptsAndContent(article.structuredContent);
        const value: ISingleArticle = { ...article, ...contentAndScripts };
        const visibilityData: VisibilityCondition[] = this.getVisibilityDataForArticle(
          article,
          routeName,
        );

        const timestamp = new Date().getTime();
        trimWpDomainInPlace(value, ['tags.url', 'categories.url', 'meta.breadcrumb.url']);

        this.pagesData[path] = {
          timestamp,
          visibilityData: visibilityData,
          article: value,
        };
      },

      addArchiveData(payload: { path: string; data: ArchivePayload }) {
        const { path, data } = payload;
        const { articles, totalPages, title, type, id, icon, breadcrumb } = data;

        const timestamp = new Date().getTime();
        const visibilityData: VisibilityCondition[] = [];
        if (type && id) {
          visibilityData.push({
            id,
            type: type as unknown as VisibilityCondition['type'],
          });
        }

        const uniqArticles = getUniqueArrayByProp(articles, 'id') as IArticle[];
        trimWpDomainInPlace(uniqArticles, [
          'author.profile',
          'categories.url',
          'meta.breadcrumb.url',
        ]);

        this.pagesData[path] = {
          timestamp,
          title,
          totalPages,
          visibilityData,
          articles: uniqArticles,
          icon,
          breadcrumb,
        };
      },

      updateCurrentArticles(path: string, newArticles: IArticle[]) {
        const currentPage = this.pagesData[path];

        if (!isArchivePage(currentPage)) return;

        const currentArticles = this.currentArticles;
        currentPage.articles = getUniqueArrayByProp(
          [...currentArticles, ...newArticles],
          'id',
        ) as IArticle[];
      },
    },
  }),
);

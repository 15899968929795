import { YA_METRIKA_ID, YA_METRIKA_ID_RECIPE, YA_METRIKA_ID_HEALTH } from '../constants/config';
import {
  sendYandexMetrika,
  sendAnalyticsSnowPlow,
  sendCustomYandexMetrika,
} from '~/utils/sendAnalytics';
import type { YandexMetrikData } from '~/types/analytics/yandexMetrika';
import type { snowplowAnalytics } from '~/types/analytics/snowplow';
import { isAnyRecipePage, isHealthPath, isPreviewPage } from '~/utils/router';

export default defineNuxtPlugin((nuxtApp) => {
  const log = useNuxtApp().$logger.withScope('📈 data-layer');
  const route = useRoute();

  nuxtApp.provide('sendAnalyticsSnowPlow', (analyticsParams: snowplowAnalytics) => {
    // для превью не отправляем аналитику
    if (isPreviewPage(route.name)) {
      return;
    }

    log('sendAnalyticsSnowPlow', analyticsParams);
    sendAnalyticsSnowPlow(analyticsParams);
  });

  nuxtApp.provide('sendYandexMetrika', (analyticsParams: YandexMetrikData) => {
    // для превью не отправляем аналитику
    if (isPreviewPage(route.name)) {
      return;
    }

    // Для страниц по умолчанию
    let id = YA_METRIKA_ID;
    if (isAnyRecipePage(route.name)) {
      // Для страниц рецептов
      id = YA_METRIKA_ID_RECIPE;
    } else if (isHealthPath(route.path)) {
      // Для страниц здоровья
      id = YA_METRIKA_ID_HEALTH;
    }

    log('sendYandexMetrika', id, analyticsParams);
    sendYandexMetrika(id, analyticsParams);
  });

  nuxtApp.provide('sendCustomYandexMetrika', (params: YandexMetrikData) => {
    // Для страниц по умолчанию
    let id = YA_METRIKA_ID;
    if (isAnyRecipePage(route.name)) {
      // Для страниц рецептов
      id = YA_METRIKA_ID_RECIPE;
    } else if (isHealthPath(route.path)) {
      // Для страниц здоровья
      id = YA_METRIKA_ID_HEALTH;
    }

    log('sendCustomYandexMetrika', id, params);
    sendCustomYandexMetrika(id, params);
  });
});

import { parseData } from '@devhacker/shared/utils/parseData';
import { useNuxtApp } from '#app';
import {
  fetchReactionsSchema,
  updateReactionsSchema,
  updateLikeSchema,
  updateDislikeSchema,
} from '@/schemas/reactions/fetchReactionsSchema';
import { defineStore } from 'pinia';
import { wrapPiniaStore } from '@devhacker/shared/utils/wrapPiniaStore';

export type ReactionObserverItem = {
  id: string;
  intersectionObserver: IntersectionObserver | null;
  isShowHeartbeat: boolean;
  isBeforeLicked: boolean;
};

export interface ReactionsState {
  byId: Record<number, ReactionFromServer>;
  observers: Array<ReactionObserverItem>;
  isPending: boolean;
}

export const useReactionsStore = wrapPiniaStore(
  defineStore('reactions', {
    state: () => ({
      byId: {} as Record<number, ReactionFromServer>,
      observers: [] as Array<ReactionObserverItem>,
      isPending: false,
    }),

    getters: {
      getObservers:
        (state) =>
        (id: string): ReactionObserverItem | null => {
          return state.observers.find((el: ReactionObserverItem) => el.id === id) ?? null;
        },
      getLikes:
        (state) =>
        (id: number): number => {
          return state.byId[id]?.like_count ?? 0;
        },
      getDislikes:
        (state) =>
        (id: number): number => {
          return state.byId[id]?.dislike_count ?? 0;
        },
      getReactionsState:
        (state) =>
        (id: number): TReactionType | null => {
          return state.byId[id]?.user_state;
        },
      getReactions: (state) => (id: number) => {
        const reactionTypes: IReactionScheme[] = [
          { icon: 'heart', type: 'heart', title: 'сердечко' },
          { icon: 'thumbs_up', type: 'thumbs_up', title: 'палец вверх' },
          { icon: 'on_fire', type: 'on_fire', title: 'огонь' },
          { icon: 'thumbs_down', type: 'thumbs_down', title: 'палец вниз' },
          { icon: 'angry', type: 'angry', title: 'сердитый смайлик' },
        ];
        return reactionTypes.map((reactionScheme: IReactionScheme): IReaction => {
          const count: number =
            (state.byId[id] && state.byId[id][`${reactionScheme.type}_count`]) || 0;
          return { ...reactionScheme, count };
        });
      },
    },

    actions: {
      async fetchReactions(payload: number[]) {
        try {
          const { $reactionsApi } = useNuxtApp();
          const response = await $reactionsApi.fetchReactions(payload);
          const parsedResponse = parseData(response.data, fetchReactionsSchema);
          if (parsedResponse.success) {
            const reactions = parsedResponse.data;
            this.updateReactions(reactions);
          } else {
            // $sentry.captureException(parsedResponse);
          }
        } catch (error) {
          console.error(error);
          // $sentry.captureException(error);
        }
      },

      preUpdateReaction(id: number, user_state: TReactionType) {
        const prevReaction = { ...this.byId[id], post_id: id };
        const isSameType = prevReaction.user_state === user_state;
        const isSwapType = prevReaction.user_state && !isSameType;

        const prevCount = prevReaction.user_state
          ? prevReaction?.[`${prevReaction.user_state}_count`]
          : 0;
        const newCount = prevReaction?.[`${user_state}_count`] ?? 0;

        this.updateReactions([
          {
            ...prevReaction,
            user_state: isSameType ? null : user_state,
            ...(isSwapType
              ? {
                  [`${user_state}_count`]: newCount + 1,
                  [`${prevReaction.user_state}_count`]: prevCount - 1,
                }
              : isSameType
                ? { [`${user_state}_count`]: newCount - 1 }
                : { [`${user_state}_count`]: newCount + 1 }),
          },
        ]);
      },

      async updateReaction(id: number, user_state: TReactionType, isHealth: boolean) {
        if (this.isPending) return;
        this.isPending = true;
        this.preUpdateReaction(id, user_state);

        try {
          const { $reactionsApi } = useNuxtApp();
          const response = await $reactionsApi.updateReaction(id, user_state, isHealth);
          const parsedResponse = parseData(response.data, updateReactionsSchema);

          if (parsedResponse.success) {
            const reaction = parsedResponse.data;
            this.updateReactions([reaction]);
          } else {
            console.error(parsedResponse);
            throw new Error('Something went wrong');
          }
        } catch (error) {
          console.error(error);
          // $sentry.captureException(error);
        }

        this.isPending = false;
      },

      async updateLike(id: number) {
        if (this.isPending) return;
        this.isPending = true;
        this.preUpdateReaction(id, 'like');

        try {
          const { $reactionsApi } = useNuxtApp();
          const response = await $reactionsApi.updateLike(id);
          const parsedResponse = parseData(response.data, updateLikeSchema);

          if (parsedResponse.success) {
            const reaction = parsedResponse.data;
            this.updateReactions([reaction]);
          } else {
            throw new Error('Something went wrong');
          }
        } catch (error) {
          console.error(error);
          // $sentry.captureException(error);
        }

        this.isPending = false;
      },

      async updateDislike(id: number) {
        if (this.isPending) return;
        this.isPending = true;
        this.preUpdateReaction(id, 'dislike');

        try {
          const { $reactionsApi } = useNuxtApp();
          const response = await $reactionsApi.updateDislike(id);
          const parsedResponse = parseData(response.data, updateDislikeSchema);

          if (parsedResponse.success) {
            const reaction = parsedResponse.data;
            this.updateReactions([reaction]);
          } else {
            // $sentry.captureException(parsedResponse);
            throw new Error('Something went wrong');
          }
        } catch (error) {
          console.error(error);
          // $sentry.captureException(error);
        }

        this.isPending = false;
      },

      async refetchReactions() {
        const ids: number[] = Object.keys(this.byId).map((item) => +item);
        if (ids.length) {
          await this.fetchReactions(ids);
        }
      },

      createIntersectionObserver(payload: ReactionObserverItem) {
        this.addIntersectionObserver(payload);
      },

      disconnectIntersectionObserver() {
        this.observers.forEach((item) => {
          item.intersectionObserver?.disconnect();
        });
      },

      updateReactions(reactions: ReactionFromServer[]) {
        reactions.forEach((reaction: ReactionFromServer) => {
          // TODO: Check if reactivity working without vue set
          this.byId[reaction.post_id] = reaction;
        });
      },

      addIntersectionObserver(payload: ReactionObserverItem) {
        this.observers.push(payload);
      },

      setIsShowHeartbeat(id: string, value: boolean) {
        const item = this.observers.find((el) => el.id === id);
        if (item) {
          item.isShowHeartbeat = value;
        }
      },

      setIsBeforeLicked(id: string, value: boolean) {
        const item = this.observers.find((el) => el.id === id);
        if (item) {
          item.isBeforeLicked = value;
        }
      },
    },
  }),
);

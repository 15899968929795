// @ts-ignore
import { array, boolean, number, object, string, optional, unknown, nullish } from 'valibot';
import { AuthorExpertSchema, ImgSchema } from '../generalSchemas';

export const recipe = object({
  postId: number(),
  images: array(ImgSchema),
  title: string(),
  link: string(),
  totalTime: number(),
  activeTime: number(),
  commentsEnabled: boolean(),
});

const ContentSchema = object({
  id: number('Error on content id'),
  type: string('Error on content type'),
  attributes: object({}, 'Error on content attributes'),
  text: string('Error on content text'),
  children: array(unknown(), 'Error on content children'),
});

const RelatedSchema = object({
  postId: number('Error on postId'),
  title: string('Error on title'),
  link: string('Error on link'),
  images: array(ImgSchema, 'Error on images'),
});

const RecipeSeo = object({
  title: string('Error on seo title'),
  description: string('Error on seo description'),
  robots: string('Error on seo robots'),
  canonical: string('Error on seo canonical'),
  ogImage: string('Error on seo og_image'),
  schemaGraph: unknown(),
});

export const SingleRecipeSchema = object({
  postId: number('Error on postId'),
  title: string('Error on title'),
  description: array(ContentSchema, 'Error on description'),
  yield: number('Error on yield'),
  commentsEnabled: boolean('Error on commentsEnabled'),
  ingredientsCount: number('Error on ingredientsCount'),
  isUserLoggedIn: boolean('Error on isUserLoggedIn'),
  images: array(ImgSchema, 'Error on images'),
  ingredientsSections: array(
    object({
      title: string('Error on ingredientsSections title'),
      ingredients: array(
        object({
          title: string('Error on ingredients title'),
          amount: string('Error on ingredients amount'),
        }),
      ),
    }),
  ),
  author: AuthorExpertSchema,
  expert: AuthorExpertSchema,
  caption: optional(string('Error on caption')),
  customAuthors: optional(string('Error on customAuthors')),
  instructions: array(string('Error on instructions')),
  parsedInstructions: array(array(ContentSchema), 'Error on parsedInstructions'),
  breadcrumbs: array(
    object({
      title: string('Error on breadcrumbs title'),
      link: string('Error on breadcrumbs link'),
      own: nullish(string('Error on breadcrumbs own')),
    }),
  ),
  lastModified: optional(number('Error on last modified')),
  meta: optional(
    object({
      aggregateRating: optional(
        object({
          // Sometimes it's number, sometimes it's string who knows why
          ratingCount: optional(unknown()),
          ratingValue: optional(unknown()),
        }),
      ),
      keywords: optional(string('Error on meta keywords')),
      recipeCategory: optional(string('Error on meta recipe category')),
      recipeCuisine: optional(string('Error on meta recipe cuisine')),
    }),
  ),

  related: array(RelatedSchema, 'Error on related'),
  similar: array(RelatedSchema, 'Error on similar'),
  similarMore: optional(string('Error on similar more')),
  relatedMore: optional(string('Error on related more')),
  url: optional(string('Error on url')),

  seo: RecipeSeo,
  ingredients: optional(array(number('Error on ingredients'))),
  dishTypes: optional(array(number('Error on dishTypes'))),
  cuisines: optional(array(number('Error on cuisines'))),
  keywords: optional(array(number('Error on keywords'))),
  cookingMethods: optional(array(number('Error on cooking methods'))),
});

export const fetchRecipesSchema = object({
  title: string(),
  totalPage: number(),
  currentPage: number(),
  totalRecipes: number(),
  recipes: array(recipe),
  isUserLoggedIn: boolean(),
  page: string(),
  taxonomies: optional(
    object({
      cuisines: number(),
      dishTypes: number(),
      cookingMethods: number(),
      ingredients: number(),
      keywords: number(),
    }),
  ),
  seo: RecipeSeo,
});

import qs from 'qs';
import { createLoggerInterceptors } from '@devhacker/shared/libs/logger';
import { TIMEOUT } from '~/constants';
import { VIEWS_API_URL, WORDPRESS_API_URL } from '~/constants/config';
import {
  createAxiosLikeFetch,
  type AxiosLikeFetchResponse,
} from '@devhacker/shared/libs/fetch/createAxiosLikeFetch';

export interface IViewsApi {
  getArticleViewsCount: (articleIds: number[]) => Promise<AxiosLikeFetchResponse<object>>;
  incrArticleViewsCount: (articleId: number) => Promise<AxiosLikeFetchResponse<object>>;
  syncArticleViewsCount: (articleId: number) => Promise<
    AxiosLikeFetchResponse<{
      viewsCount: number;
    }>
  >;
}

export const createViewsApi = ({ $logger }: any): IViewsApi => {
  // Эта апишка ругается на CORS при локальной разработке
  // она на писана на GO и беки боятся ее менять
  const createViewsFetch = (baseURL: string, timeout: number = TIMEOUT) =>
    createAxiosLikeFetch({
      baseURL,
      timeout,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Accept: '*/*',
      },
      ...createLoggerInterceptors($logger, 'views'),
    });

  return {
    getArticleViewsCount: (articleIds) => {
      const api = createViewsFetch(VIEWS_API_URL);
      const body = qs.stringify({
        action: 'get-views',
        postIds: articleIds.join(),
      });

      return api('/get/', { method: 'post', body });
    },

    incrArticleViewsCount: (articleId) => {
      const api = createViewsFetch(VIEWS_API_URL);
      const body = qs.stringify({
        action: 'increase-views',
        postId: articleId,
      });
      return api('/incr/', { method: 'post', body });
    },

    syncArticleViewsCount: (articleId) => {
      const api = createViewsFetch(WORDPRESS_API_URL, 60000);
      const body = qs.stringify({
        action: 'sync_views',
        postId: articleId,
      });
      // хардкод, потому что нет prod/stage
      return api('/wp-admin/admin-ajax.php', { method: 'post', body });
    },
  };
};

import { defineStore } from 'pinia';
import { wrapPiniaStore } from '@devhacker/shared/utils/wrapPiniaStore';

export const DEFAULT_SEO = {
  title: 'Лайфхакер',
  description: 'Лайфхакер - советы и лайфхаки, продуктивность, технологии, здоровье',
  robots: '',
  canonical: '',
  schema_graph: '',
  schema_recipe: '',
  og: {
    image: '',
  },
  schema_howto: '',
};

export const useSeoStore = wrapPiniaStore(
  defineStore('seo', {
    state: () => ({
      byPath: {} as Record<string, Seo>,
      default: DEFAULT_SEO as Seo,
    }),

    getters: {
      currentSeo(state) {
        const route = useRoute(); // Using Nuxt's route via useNuxtApp
        const currentPath = route.fullPath.replace(route.hash, '');
        return state.byPath[String(currentPath)] ?? state.default;
      },
    },

    actions: {
      addSeoByPath(path: string, seo: Seo) {
        // TODO: Check if reactivity working without vue set
        this.byPath[path] = seo;
      },
    },
  }),
);

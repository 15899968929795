
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as ProfilePostsUKaIfa9owsMeta } from "/home/gitlab-runner/builds/-zGqAZ5s/0/devhacker/lh-website-client/pages/ProfilePosts.vue?macro=true";
import { default as ProfileCommentsjdZljBZLZYMeta } from "/home/gitlab-runner/builds/-zGqAZ5s/0/devhacker/lh-website-client/pages/ProfileComments.vue?macro=true";
import { default as ProfileEdityWrY1K10nFMeta } from "/home/gitlab-runner/builds/-zGqAZ5s/0/devhacker/lh-website-client/pages/ProfileEdit.vue?macro=true";
import { default as ProfileSettings5Z5SW1PxuJMeta } from "/home/gitlab-runner/builds/-zGqAZ5s/0/devhacker/lh-website-client/pages/ProfileSettings.vue?macro=true";
import { default as ProfileFavoritesJDqU3zMBdfMeta } from "/home/gitlab-runner/builds/-zGqAZ5s/0/devhacker/lh-website-client/pages/ProfileFavorites.vue?macro=true";
import { default as ProfileFavoritesEditLKwAhSPFROMeta } from "/home/gitlab-runner/builds/-zGqAZ5s/0/devhacker/lh-website-client/pages/ProfileFavoritesEdit.vue?macro=true";
import { default as ProfileNotificationsFnqKoJB8T1Meta } from "/home/gitlab-runner/builds/-zGqAZ5s/0/devhacker/lh-website-client/pages/ProfileNotifications.vue?macro=true";
import { default as ProfilewCrtTKMqCrMeta } from "/home/gitlab-runner/builds/-zGqAZ5s/0/devhacker/lh-website-client/pages/Profile.vue?macro=true";
import { default as Recipes0t40MUPRfnMeta } from "/home/gitlab-runner/builds/-zGqAZ5s/0/devhacker/lh-website-client/pages/Recipes.vue?macro=true";
import { default as TheRecipe_46amp19pRHWuX9TMeta } from "/home/gitlab-runner/builds/-zGqAZ5s/0/devhacker/lh-website-client/pages/TheRecipe.amp.vue?macro=true";
import { default as TheRecipe0vaY7E3MgZMeta } from "/home/gitlab-runner/builds/-zGqAZ5s/0/devhacker/lh-website-client/pages/TheRecipe.vue?macro=true";
import { default as ArticleNavigatorMqr8svPX2lMeta } from "/home/gitlab-runner/builds/-zGqAZ5s/0/devhacker/lh-website-client/pages/ArticleNavigator.vue?macro=true";
import { default as ArchivePagerZWVOe50d0Meta } from "/home/gitlab-runner/builds/-zGqAZ5s/0/devhacker/lh-website-client/pages/ArchivePage.vue?macro=true";
import { default as OldCourseslbOWeaEd7JMeta } from "/home/gitlab-runner/builds/-zGqAZ5s/0/devhacker/lh-website-client/pages/OldCourses.vue?macro=true";
import { default as OldArchivesNNXRkizIF8Meta } from "/home/gitlab-runner/builds/-zGqAZ5s/0/devhacker/lh-website-client/pages/OldArchives.vue?macro=true";
import { default as SingleArticle_46amplOjgzhzdsIMeta } from "/home/gitlab-runner/builds/-zGqAZ5s/0/devhacker/lh-website-client/pages/SingleArticle.amp.vue?macro=true";
import { default as YandbtmyrEEswhPIiMeta } from "/home/gitlab-runner/builds/-zGqAZ5s/0/devhacker/lh-website-client/pages/Yandbtm.vue?macro=true";
import { default as SearchArchivepZ72x8WpnnMeta } from "/home/gitlab-runner/builds/-zGqAZ5s/0/devhacker/lh-website-client/pages/SearchArchive.vue?macro=true";
import { default as HealthHomeUz93H3I5OIMeta } from "/home/gitlab-runner/builds/-zGqAZ5s/0/devhacker/lh-website-client/pages/HealthHome.vue?macro=true";
import { default as HealthServicesU2jtD4kR5oMeta } from "/home/gitlab-runner/builds/-zGqAZ5s/0/devhacker/lh-website-client/pages/HealthServices.vue?macro=true";
import { default as HealthNavigator7zYVyFOc4OMeta } from "/home/gitlab-runner/builds/-zGqAZ5s/0/devhacker/lh-website-client/pages/HealthNavigator.vue?macro=true";
export default [
  {
    name: ProfilewCrtTKMqCrMeta?.name,
    path: "/profile/user/:userId",
    meta: ProfilewCrtTKMqCrMeta || {},
    component: () => import("/home/gitlab-runner/builds/-zGqAZ5s/0/devhacker/lh-website-client/pages/Profile.vue"),
    children: [
  {
    path: "",
    redirect: "comments"
  },
  {
    name: "profile-posts",
    path: "posts",
    component: () => import("/home/gitlab-runner/builds/-zGqAZ5s/0/devhacker/lh-website-client/pages/ProfilePosts.vue")
  },
  {
    name: "profile-comments",
    path: "comments",
    component: () => import("/home/gitlab-runner/builds/-zGqAZ5s/0/devhacker/lh-website-client/pages/ProfileComments.vue")
  },
  {
    name: "profile-edit",
    path: "edit",
    meta: {"requiresAuth":true},
    component: () => import("/home/gitlab-runner/builds/-zGqAZ5s/0/devhacker/lh-website-client/pages/ProfileEdit.vue")
  },
  {
    name: "profile-settings",
    path: "settings",
    meta: {"requiresAuth":true},
    component: () => import("/home/gitlab-runner/builds/-zGqAZ5s/0/devhacker/lh-website-client/pages/ProfileSettings.vue")
  },
  {
    name: "profile-favorites",
    path: "favorites",
    meta: {"requiresAuth":true},
    component: () => import("/home/gitlab-runner/builds/-zGqAZ5s/0/devhacker/lh-website-client/pages/ProfileFavorites.vue")
  },
  {
    name: "profile-favorites-edit",
    path: "favorites-edit",
    meta: {"requiresAuth":true},
    component: () => import("/home/gitlab-runner/builds/-zGqAZ5s/0/devhacker/lh-website-client/pages/ProfileFavoritesEdit.vue")
  },
  {
    name: "profile-notifications",
    path: "notifications",
    meta: {"requiresAuth":true},
    component: () => import("/home/gitlab-runner/builds/-zGqAZ5s/0/devhacker/lh-website-client/pages/ProfileNotifications.vue")
  }
]
  },
  {
    name: "recipes-main-page",
    path: "/recipes/",
    meta: Recipes0t40MUPRfnMeta || {},
    component: () => import("/home/gitlab-runner/builds/-zGqAZ5s/0/devhacker/lh-website-client/pages/Recipes.vue")
  },
  {
    name: "recipes-with-page",
    path: "/recipes/:slug*/page/:activePage",
    props: true,
    meta: Recipes0t40MUPRfnMeta || {},
    component: () => import("/home/gitlab-runner/builds/-zGqAZ5s/0/devhacker/lh-website-client/pages/Recipes.vue")
  },
  {
    name: "recipes",
    path: "/recipes/:slug*",
    props: true,
    meta: Recipes0t40MUPRfnMeta || {},
    component: () => import("/home/gitlab-runner/builds/-zGqAZ5s/0/devhacker/lh-website-client/pages/Recipes.vue")
  },
  {
    name: "TheRecipeAmp",
    path: "/recipe/:slug/amp",
    meta: TheRecipe_46amp19pRHWuX9TMeta || {},
    component: () => import("/home/gitlab-runner/builds/-zGqAZ5s/0/devhacker/lh-website-client/pages/TheRecipe.amp.vue")
  },
  {
    name: "TheRecipe",
    path: "/recipe/:slug(.*)",
    props: true,
    meta: TheRecipe0vaY7E3MgZMeta || {},
    component: () => import("/home/gitlab-runner/builds/-zGqAZ5s/0/devhacker/lh-website-client/pages/TheRecipe.vue")
  },
  {
    name: "recipe-preview",
    path: "/preview/recipe/:id",
    props: true,
    meta: TheRecipe0vaY7E3MgZMeta || {},
    component: () => import("/home/gitlab-runner/builds/-zGqAZ5s/0/devhacker/lh-website-client/pages/TheRecipe.vue")
  },
  {
    name: "single-topics",
    path: "/topics/",
    component: () => import("/home/gitlab-runner/builds/-zGqAZ5s/0/devhacker/lh-website-client/pages/ArticleNavigator.vue")
  },
  {
    name: "topics-all",
    path: "/topics/:pathMatch(.*)",
    meta: ArchivePagerZWVOe50d0Meta || {},
    component: () => import("/home/gitlab-runner/builds/-zGqAZ5s/0/devhacker/lh-website-client/pages/ArchivePage.vue")
  },
  {
    name: "author-all",
    path: "/author/:pathMatch(.*)",
    meta: ArchivePagerZWVOe50d0Meta || {},
    alias: ["/health/author/:pathMatch(.*)"],
    component: () => import("/home/gitlab-runner/builds/-zGqAZ5s/0/devhacker/lh-website-client/pages/ArchivePage.vue")
  },
  {
    name: "tag-all",
    path: "/tag/:pathMatch(.*)",
    meta: ArchivePagerZWVOe50d0Meta || {},
    alias: ["/health/tag/:pathMatch(.*)"],
    component: () => import("/home/gitlab-runner/builds/-zGqAZ5s/0/devhacker/lh-website-client/pages/ArchivePage.vue")
  },
  {
    name: "list-all",
    path: "/list/",
    meta: ArchivePagerZWVOe50d0Meta || {},
    alias: ["/health/list/"],
    component: () => import("/home/gitlab-runner/builds/-zGqAZ5s/0/devhacker/lh-website-client/pages/ArchivePage.vue")
  },
  {
    name: "stream-all",
    path: "/stream/:pathMatch(.*)",
    meta: ArchivePagerZWVOe50d0Meta || {},
    alias: ["/health/stream/:pathMatch(.*)"],
    component: () => import("/home/gitlab-runner/builds/-zGqAZ5s/0/devhacker/lh-website-client/pages/ArchivePage.vue")
  },
  {
    path: "/top/",
    name: "top-redirect",
    redirect: "/top/week/"
  },
  {
    name: "top-all",
    path: "/top/:pathMatch(week.*|month.*|year.*|all.*)?",
    meta: ArchivePagerZWVOe50d0Meta || {},
    component: () => import("/home/gitlab-runner/builds/-zGqAZ5s/0/devhacker/lh-website-client/pages/ArchivePage.vue")
  },
  {
    name: "index",
    path: "/",
    meta: ArchivePagerZWVOe50d0Meta || {},
    component: () => import("/home/gitlab-runner/builds/-zGqAZ5s/0/devhacker/lh-website-client/pages/ArchivePage.vue")
  },
  {
    name: "courses",
    path: "/kursy/:pathMatch(.*)",
    component: () => import("/home/gitlab-runner/builds/-zGqAZ5s/0/devhacker/lh-website-client/pages/OldCourses.vue")
  },
  {
    name: "archive-year",
    path: "/:year([0-9]{4})",
    alias: ["/health/:year([0-9]{4})"],
    component: () => import("/home/gitlab-runner/builds/-zGqAZ5s/0/devhacker/lh-website-client/pages/OldArchives.vue")
  },
  {
    name: "archive-month",
    path: "/:year([0-9]{4})/:month([0-9]{1,2})",
    alias: ["/health/:year([0-9]{4})/:month([0-9]{1,2})"],
    component: () => import("/home/gitlab-runner/builds/-zGqAZ5s/0/devhacker/lh-website-client/pages/OldArchives.vue")
  },
  {
    name: "archive-day",
    path: "/:year([0-9]{4})/:month([0-9]{1,2})/:day([0-9]{1,2})",
    alias: ["/health/:year([0-9]{4})/:month([0-9]{1,2})/:day([0-9]{1,2})"],
    component: () => import("/home/gitlab-runner/builds/-zGqAZ5s/0/devhacker/lh-website-client/pages/OldArchives.vue")
  },
  {
    name: "preview",
    path: "/preview/:articleId",
    component: () => import("/home/gitlab-runner/builds/-zGqAZ5s/0/devhacker/lh-website-client/pages/ArticleNavigator.vue")
  },
  {
    name: "single-amp",
    path: "/:slug*/amp",
    meta: SingleArticle_46amplOjgzhzdsIMeta || {},
    component: () => import("/home/gitlab-runner/builds/-zGqAZ5s/0/devhacker/lh-website-client/pages/SingleArticle.amp.vue")
  },
  {
    name: "yandbtm",
    path: "/yandbtm",
    meta: YandbtmyrEEswhPIiMeta || {},
    component: () => import("/home/gitlab-runner/builds/-zGqAZ5s/0/devhacker/lh-website-client/pages/Yandbtm.vue")
  },
  {
    name: "search",
    path: "/search",
    component: () => import("/home/gitlab-runner/builds/-zGqAZ5s/0/devhacker/lh-website-client/pages/SearchArchive.vue")
  },
  {
    path: "/ext-search/",
    name: "ext-search",
    redirect: "search"
  },
  {
    name: "health-home",
    path: "/health/",
    meta: HealthHomeUz93H3I5OIMeta || {},
    component: () => import("/home/gitlab-runner/builds/-zGqAZ5s/0/devhacker/lh-website-client/pages/HealthHome.vue")
  },
  {
    name: "health-services",
    path: "/health/services",
    meta: HealthServicesU2jtD4kR5oMeta || {},
    component: () => import("/home/gitlab-runner/builds/-zGqAZ5s/0/devhacker/lh-website-client/pages/HealthServices.vue")
  },
  {
    name: "health-article-amp-category",
    path: "/health/:category/:slug/amp",
    meta: SingleArticle_46amplOjgzhzdsIMeta || {},
    component: () => import("/home/gitlab-runner/builds/-zGqAZ5s/0/devhacker/lh-website-client/pages/SingleArticle.amp.vue")
  },
  {
    name: "health-article-amp-categoryOrSlug",
    path: "/health/:categoryOrSlug/amp",
    meta: SingleArticle_46amplOjgzhzdsIMeta || {},
    component: () => import("/home/gitlab-runner/builds/-zGqAZ5s/0/devhacker/lh-website-client/pages/SingleArticle.amp.vue")
  },
  {
    name: "health-article",
    path: "/health/:category/:slug",
    meta: HealthNavigator7zYVyFOc4OMeta || {},
    component: () => import("/home/gitlab-runner/builds/-zGqAZ5s/0/devhacker/lh-website-client/pages/HealthNavigator.vue")
  },
  {
    name: "health-section",
    path: "/health/:categoryOrSlug",
    meta: HealthNavigator7zYVyFOc4OMeta || {},
    component: () => import("/home/gitlab-runner/builds/-zGqAZ5s/0/devhacker/lh-website-client/pages/HealthNavigator.vue")
  },
  {
    name: "single",
    path: "/:pathMatch(.*)",
    component: () => import("/home/gitlab-runner/builds/-zGqAZ5s/0/devhacker/lh-website-client/pages/ArticleNavigator.vue")
  }
]
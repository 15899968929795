//@ts-ignore
import { nanoid } from 'nanoid';
import { parseData } from '@devhacker/shared/utils/parseData';
import events from '../components/Pusher/websocket-events';
import allowedNotifications from '~/components/Pusher/allowed-notifications';
import { getUserAvatarUrlFromUser, openNotificationLink } from '~/components/Pusher/utils.client';
import { getAccessToken, getSubId, getUniqueListBy } from '~/utils';
import { FullSearchDataSchema } from '~/schemas/search';
import { defineStore } from 'pinia';
import { wrapPiniaStore } from '@devhacker/shared/utils/wrapPiniaStore';

type TBadge = {
  image: any;
  content: any;
  id: any;
  clickHandler: any;
  isError: any;
};

export type TNotification = {
  _id: string;
  redirect_link: string;
  path: string;
  is_read: boolean;
  payload: {
    content: string;
    extends: {
      postTitle: string;
      renderTitle: string;
      commentUserName: string;
      postType: string;
      message?: string;
      comment: {
        user: IUser;
      };
      like: {
        user: IUser;
      };
    };
  };
  event: string;
};

export interface PusherState {
  notifications: Array<TNotification>;
  badges: Array<TBadge>;
  notificationsRequested: boolean;
  unviewedNotificationsCount: number;
  posts: Array<IArticle>;
  isSendRefreshToken: boolean;
}

export const usePusherStore = wrapPiniaStore(
  defineStore('pusher', {
    state: () => ({
      notifications: [] as readonly TNotification[],
      badges: [] as TBadge[],
      notificationsRequested: false,
      unviewedNotificationsCount: 0,
      posts: [] as IArticle[],
      isSendRefreshToken: false,
    }),

    actions: {
      handleBadgeNotificationClick(notification: TNotification) {
        this.setReadToNotification(notification._id);
        openNotificationLink(notification);
      },

      async handleWsEvent(event: TNotification) {
        const notification = event;
        let displayName, photoUrl, message, likeText, dislikeText, text, eventText;
        const postTitle = notification?.payload?.extends?.postTitle || '';
        const isRecipe = notification?.payload?.extends?.postType === 'recipe';

        switch (notification.event) {
          case 'comment_reply':
            this.addNotification(notification);
            this.incrUnviewedNotificationsCount();

            displayName = notification?.payload?.extends?.comment?.user?.display_name;
            photoUrl = getUserAvatarUrlFromUser(notification?.payload?.extends?.comment?.user);
            eventText = notification.payload?.extends?.renderTitle;
            message =
              eventText ||
              `<b>${displayName}</b> ответил(а) вам под ${isRecipe ? 'рецептом' : 'статьёй'} <b>${postTitle}</b>`;

            return this.pushBadge({
              image: photoUrl,
              content: message,
              id: nanoid(),
              clickHandler: this.handleBadgeNotificationClick,
              isError: false,
            });

          case 'comment_publish':
            this.addNotification(notification);
            this.incrUnviewedNotificationsCount();

            displayName = notification.payload.extends.commentUserName;
            photoUrl = getUserAvatarUrlFromUser(notification.payload.extends.comment.user);

            eventText = notification.payload?.extends?.renderTitle;
            message =
              eventText ||
              `<b>${displayName}</b> оставил(а) комментарий к ${isRecipe ? 'рецепту' : 'статье'} <b>${postTitle}</b>`;

            return this.pushBadge({
              image: photoUrl,
              content: message,
              id: nanoid(),
              clickHandler: this.handleBadgeNotificationClick,
              isError: false,
            });

          case 'comment_rated':
            this.addNotification(notification);
            this.incrUnviewedNotificationsCount();

            displayName = notification.payload.extends.like.user.display_name;
            likeText = isRecipe ? events.comment_rated_like_recipe : events.comment_rated_like;
            dislikeText = isRecipe
              ? events.comment_rated_dislike_recipe
              : events.comment_rated_dislike;
            text = notification.payload.content === 'like' ? likeText : dislikeText;

            photoUrl = getUserAvatarUrlFromUser(notification.payload.extends.like.user);
            eventText = notification.payload?.extends?.renderTitle;
            message = eventText || `<b>${displayName}</b> ${text} <b>${postTitle}</b>`;

            return this.pushBadge({
              image: photoUrl,
              content: message,
              id: nanoid(),
              clickHandler: this.handleBadgeNotificationClick,
              isError: false,
            });

          case 'kolokolchick_update_meta':
            return this.fetchUnviewedNotificationsCount();

          case 'profile_updated':
            return this.pushBadge({
              image: null,
              content: notification.payload.extends.message,
              id: nanoid(),
              clickHandler: () => {},
              isError: false,
            });

          default:
        }
      },

      async fetchPosts({ postIds }: { postIds: number[] }) {
        const uniquePostIds = [...new Set(postIds)];
        const actualPostIds = this.posts.map((post) => +post.id);
        const newIds = uniquePostIds.filter((id) => !actualPostIds.includes(Number(id)));
        if (newIds.length < 1) {
          return;
        }

        try {
          const { $searchApi } = useNuxtApp();
          const response = await $searchApi.getSearchItemsByIds(newIds);

          if (response.status === 200) {
            const parsedResponse = parseData(response.data, FullSearchDataSchema);
            const RESPONSE_DATA = parsedResponse.data;
            this.updatePosts(RESPONSE_DATA);
          } else {
            throw new Error(response.data);
          }
        } catch (err) {
          this.handleError(err);
        }
      },

      setNotificationsRequestedAction() {
        this.setNotificationsRequested();
      },

      async fetchNotifications() {
        const integerId = getSubId();
        if (!integerId) {
          return;
        }

        const accessToken = getAccessToken();

        const response = await useNuxtApp().$pusherApi.fetchNotifications(
          accessToken,
          String(integerId),
          {
            page: 1,
            limit: 10,
          },
        );

        try {
          if (response.data.success) {
            const notifications = response.data.data;

            const commentNotifications = notifications.filter(
              (n: any) => n.event === 'comment_reply',
            );
            const likeNotifications = notifications.filter((n: any) => n.event === 'comment_rated');
            const postIds = [
              ...commentNotifications.map((c: any) => c.payload.extends.comment.post_ID),
              ...likeNotifications.map((like: any) => like.payload.extends.postId),
            ];

            this.updateNotifications(notifications);
            await this.fetchPosts({ postIds });
          } else {
            throw new Error(response.data);
          }
        } catch (err) {
          this.handleError(err);
        }
      },

      async fetchUnviewedNotificationsCount() {
        const integerId = getSubId();
        const accessToken = getAccessToken();

        const response = await useNuxtApp().$pusherApi.fetchNotifications(
          accessToken,
          String(integerId),
          {
            page: 1,
            limit: 1,
            filter__is_viewed: 0,
          },
        );

        try {
          if (response.data.success) {
            const { total } = response.data.meta;
            this.updateUnviewedNotificationsCount(total);
          } else {
            throw new Error(response.data);
          }
        } catch (err) {
          this.handleError(err);
        }
      },

      async resetUnviewedNotificationsCountOnServer() {
        const response = await useNuxtApp().$pusherApi.resetNotifications(getSubId());

        if (response.data.success) {
          this.resetUnviewedNotificationsCount();
        } else {
          console.error('store.pusher.ts', response);
        }
      },

      emptyNotifications() {
        this.notifications = [];
      },

      pushBadge({ image, content, id, clickHandler, isError }: TBadge) {
        this.badges.push({
          image,
          content,
          id,
          clickHandler,
          isError,
        });

        if (this.badges.length > 4) {
          this.shiftBadge();
        }
        setTimeout(() => this.removeBadge(id), 5000);
      },

      addNotification(payload: TNotification) {
        if (allowedNotifications.includes(payload.event)) {
          this.notifications = getUniqueListBy([payload, ...this.notifications], 'package_uuid');
        }
      },

      updateNotifications(payload: TNotification[]) {
        const allowedPayload = payload.filter((n: any) => allowedNotifications.includes(n.event));
        this.notifications = [...this.notifications, ...allowedPayload];
      },

      updatePosts(payload: IArticle[]) {
        this.posts = [...this.posts, ...payload];
      },

      updateUnviewedNotificationsCount(payload: number) {
        this.unviewedNotificationsCount = payload;
      },

      resetUnviewedNotificationsCount() {
        this.unviewedNotificationsCount = 0;
      },

      incrUnviewedNotificationsCount() {
        this.unviewedNotificationsCount += 1;
      },

      setNotificationsRequested() {
        this.notificationsRequested = true;
      },

      setReadToNotification(notificationId: string) {
        this.notifications = this.notifications.map((item) => {
          const is_read = item._id === notificationId ? true : item.is_read;
          return {
            ...item,
            is_read,
          };
        });
      },

      handleError(error: any) {
        const message = error?.msg || error?.message;

        this.pushBadge({
          image: null,
          content: message,
          id: nanoid(),
          clickHandler: null,
          isError: true,
        });
      },

      resetStore() {
        this.$reset();
      },

      shiftBadge() {
        this.badges.shift();
      },

      removeBadge(id: number) {
        this.badges = this.badges.filter((badge) => badge.id !== id);
      },
    },
  }),
);

import { newTracker, trackPageView, trackSelfDescribingEvent } from '@snowplow/browser-tracker';
import { TimezonePlugin } from '@snowplow/browser-plugin-timezone';

import { GeolocationPlugin } from '@snowplow/browser-plugin-geolocation';

import { SiteTrackingPlugin } from '@snowplow/browser-plugin-site-tracking';
import {
  LinkClickTrackingPlugin,
  refreshLinkClickTracking,
  enableLinkClickTracking,
} from '@snowplow/browser-plugin-link-click-tracking';

import { throttle } from 'throttle-debounce';
import {
  getPageObject,
  viewedPagePercent,
  percentageInit,
  resetThresholdsPage,
} from '~/utils/PercentagePageElement';

function onScroll(): void {
  const pagePercent = viewedPagePercent();

  if (pagePercent) {
    percentageSendAnalytics(getPageObject(), pagePercent);
  }
}

function percentageSendAnalytics(typeCategory: any, newValue: any) {
  const { thresholds } = typeCategory;

  const viewedThresholds = Object.keys(thresholds).filter(
    (threshold) => threshold <= newValue && !thresholds[threshold],
  );

  viewedThresholds.forEach((threshold) => {
    thresholds[threshold] = true;

    trackSelfDescribingEvent({
      event: {
        schema: 'iglu:dev.snowplow.simple/custom_data/jsonschema/1-0-0',
        data: {
          pageScroll: String(threshold),
        },
      },
    });
  });
}

export default defineNuxtPlugin((nuxtApp) => {
  newTracker('sp1', 'a.lifehacker.ru/lifehacker/', {
    appId: 'lh-snowplow',
    platform: 'web',
    cookieName: '_sp_',
    cookieSameSite: 'Lax',
    encodeBase64: false,
    respectDoNotTrack: false,
    eventMethod: 'post',
    bufferSize: 1,
    maxPostBytes: 50_000,
    cookieLifetime: 63_072_000,
    stateStorageStrategy: 'cookieAndLocalStorage',
    maxLocalStorageQueueSize: 2000,
    resetActivityTrackingOnPageView: true,
    connectionTimeout: 3500,
    anonymousTracking: false,
    withCredentials: true,
    contexts: {
      webPage: true,
      session: true,
      browser: false,
      performanceTiming: true,
      gaCookies: true,
      geolocation: false,
      clientHints: true,
    } as any,
    postPath: 'tracker',
    plugins: [
      LinkClickTrackingPlugin(),
      TimezonePlugin(),
      GeolocationPlugin(false),
      SiteTrackingPlugin(),
    ],
  });

  // ! delay
  // Временное отключение: https://app.clickup.com/t/862kc4tq3
  // enableActivityTracking({
  //   minimumVisitLength: 10,
  //   heartbeatDelay: 10,
  // });

  trackPageView();
  resetThresholdsPage();

  // ! clicks
  enableLinkClickTracking({ pseudoClicks: true, trackContent: true });
  // * snowplow предположительно под капотом навешивает на все ссылки при рендере событие onclick на аналитику
  // * из-за того, что у нас не все компоненты грузятся моментально (виджеты, комменты и пр) - на них не навешивается аналитика
  // * функция refreshLinkClickTracking перенавешивает события заново
  setInterval(() => {
    refreshLinkClickTracking();
  }, 1000);

  // ! scroll
  percentageInit();

  // Временно отключено https://app.clickup.com/t/86bz98ne5
  const throttledOnScroll = throttle(100, onScroll);
  window.addEventListener('scroll', throttledOnScroll);
});

import getSlugFromUrl from '@/utils/getSlugFromUrl';
import { useIndexStore } from '~/store';

export default defineNuxtPlugin(() => {
  // TODO NUXT3: log
  // const log = $logger.withScope('🍇 yandexMetrika');
  const app = useNuxtApp();
  try {
    setTimeout(() => {
      const userId = useIndexStore().userIntegerId;
      if (userId) {
        // TODO NUXT3: log
        // log('add user_id', userId);
        app.$sendCustomYandexMetrika({ user_id: userId });
      }
    }, 3000);
  } catch (err) {
    console.error(err, 'window.ym');
  }

  function linkClickHandler(event: any) {
    const currentSlug = getSlugFromUrl(window.location.href);
    const link = event.target.closest('a');
    if (!link) {
      return;
    }

    // TODO NUXT3: log
    // log('link click', link?.href);

    app.$sendCustomYandexMetrika({
      // * аналитик попросил сделать такую структуру
      outer_transition: { [currentSlug]: [link?.href] },
    });
  }

  document.addEventListener('click', linkClickHandler, { passive: false });
});

import { defineStore } from 'pinia';
import { wrapPiniaStore } from '@devhacker/shared/utils/wrapPiniaStore';

export const useServiceErrorsStore = wrapPiniaStore(
  defineStore('serviceErrors', {
    state: () => ({
      articleCommentsError: false,
      recipeCommentsError: false,
    }),

    getters: {
      articleCommentsServiceError(state): boolean {
        return state.articleCommentsError;
      },

      recipeCommentsServiceError(state): boolean {
        return state.recipeCommentsError;
      },
    },

    actions: {
      setArticleCommentsServiceError(isError: boolean) {
        this.articleCommentsError = isError;
      },

      setRecipeCommentsServiceError(isError: boolean) {
        this.recipeCommentsError = isError;
      },
    },
  }),
);

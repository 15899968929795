import PercentageViewed from '~/utils/percentageViewed';

type thresholdOptions = {
  [key: number]: boolean;
};

export interface PercentageObject {
  instance: PercentageViewed | null;
  key: string;
  category: string;
  thresholds: thresholdOptions;
}

// для всей страницы
const percentagePage: PercentageObject = {
  instance: null,
  key: 'page',
  category: 'Прокрутка страницы',
  thresholds: {
    25: false,
    50: false,
    75: false,
    100: false,
  } as thresholdOptions,
};

// для контента элемента
const percentageContent: PercentageObject = {
  instance: null,
  key: 'content',
  category: 'Прокрутка материала',
  thresholds: {
    25: false,
    50: false,
    75: false,
    100: false,
  } as thresholdOptions,
};

export function viewedPagePercent() {
  return percentagePage.instance ? percentagePage.instance.viewed : 0;
}
export function getPageObject() {
  return percentagePage;
}

export function viewedContentPercent() {
  return percentageContent.instance ? percentageContent.instance.viewed : 0;
}

export function getContentObject() {
  return percentageContent;
}

export function resetThresholdsPage() {
  percentagePage.thresholds = {
    25: false,
    50: false,
    75: false,
    100: false,
  };
}

export function percentageInit(selector?: string) {
  // отслеживаем всю страницу
  // через document.body
  if (!selector) {
    percentagePage.instance = new PercentageViewed(document.body);
  } else {
    // отправляем в параметры селектор элемента, который хотим отслеживать
    const singleArticleContent: HTMLElement | null = document.querySelector(selector);

    if (singleArticleContent) {
      percentageContent.instance = new PercentageViewed(singleArticleContent);
    }
  }
}

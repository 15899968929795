import { parseData } from '@devhacker/shared/utils/parseData';
import { trimWpDomainInPlace } from '~/utils/trimWpDomain';
import { isVisible } from '~/libs/widgetsVisibility';
import { isRecipesWidgetVisible } from '~/libs/recipesWidgetsVisibility';
import { cacheApiResponseWithRedis } from '@devhacker/shared/utils/cacheApiResponseWithRedis';
import {
  FETCH_HEALTH_WIDGETS_REDIS_KEY,
  FETCH_RECIPE_WIDGETS_REDIS_KEY,
  FETCH_WIDGETS_REDIS_KEY,
  WIDGETS_NAMES_IN_SERVER,
  type WidgetName,
} from '~/constants/widgets';
import { STRIP_WIDGET_PROPERTIES } from '~/constants/config';
import { isHealthPath } from '~/utils/router';
import { fetchRecipesWidgetsSchema } from '~/schemas/wordpress/recipeWidgetsSchema';
import { fetchWidgetsSchema } from '~/schemas/wordpress/widgetsSchema';
import { useVisibilityDataStore } from './visibilityData';
import { usePageStore } from './pagesData';
import { defineStore } from 'pinia';
import { wrapPiniaStore } from '@devhacker/shared/utils/wrapPiniaStore';

function addLocationWidget<T>(widgets: T[], type: string): T[] {
  return widgets.map((widget) => ({
    ...widget,
    location: type,
  }));
}

export interface IWidgetsState {
  sidebar: readonly TWidget[] | null;
  footer: readonly TWidget[] | null;
  aboveHeader: readonly TWidget[] | null;
  betweenPosts: readonly TWidget[] | null;
  belowHeader: readonly TWidget[] | null;
  afterExcerpt: readonly TWidget[] | null;
  aboveComments: readonly TWidget[] | null;
  belowComments: readonly TWidget[] | null;
  postDynamic: readonly TWidget[] | null;
  ampAfterFirstParagraph: readonly TWidget[] | null;
  leftSidebar: readonly TWidget[] | null;

  recipeSidebar: readonly WidgetInRecipes[] | null;
  recipesSidebar: readonly WidgetInRecipes[] | null;
  betweenRecipes: readonly WidgetInRecipes[] | null;
  aboveRecipeComments: readonly WidgetInRecipes[] | null;
  belowRecipeComments: readonly WidgetInRecipes[] | null;
  aboveRecipeHeader: readonly WidgetInRecipes[] | null;
  belowRecipeHeader: readonly WidgetInRecipes[] | null;
  insideRecipe: readonly WidgetInRecipes[] | null;

  healthSidebar: readonly TWidget[] | null;
  healthMainPage: readonly TWidget[] | null;
  healthAboveMenu: readonly TWidget[] | null;
  healthBelowMenu: readonly TWidget[] | null;
  healthAfterTheFirstParagraph: readonly TWidget[] | null;
  healthPostDynamic: readonly TWidget[] | null;
  healthAboveComments: readonly TWidget[] | null;
  healthBelowComments: readonly TWidget[] | null;
  healthFooter: readonly TWidget[] | null;
  healthScripts: readonly TWidget[] | null;
}

// Временное решение для уменьшения размера виджетов
// В конечном итоге это нужно делать со стороны бэкэнда чтобы в апи не грузить лишнего
const stripExtraPropertiesInPlace = (data: any) => {
  if (!STRIP_WIDGET_PROPERTIES) {
    return;
  }

  for (const key in data) {
    const widgets: Array<TWidget> | object = data[key];

    if (!Array.isArray(widgets)) {
      continue;
    }

    widgets?.forEach((widget: TWidget, widgetIndex: number) => {
      widget?.items?.forEach((item, itemIndex) => {
        delete item.author;
        delete item.expert;
        delete item.parsed_content;

        data[key][widgetIndex].items[itemIndex] = item;
      });
    });
  }
};

export const useWidgetsStore = wrapPiniaStore(
  defineStore('widgets', {
    state: (): IWidgetsState => ({
      sidebar: null,
      footer: null,
      aboveHeader: null,
      belowHeader: null,
      betweenPosts: null,
      afterExcerpt: null,
      aboveComments: null,
      belowComments: null,
      postDynamic: null,
      ampAfterFirstParagraph: null,
      leftSidebar: null,

      // * Recipes
      recipeSidebar: null,
      recipesSidebar: null,
      betweenRecipes: null,
      aboveRecipeComments: null,
      belowRecipeComments: null,
      aboveRecipeHeader: null,
      belowRecipeHeader: null,
      insideRecipe: null,

      // * Health
      healthSidebar: null,
      healthMainPage: null,
      healthAboveMenu: null,
      healthBelowMenu: null,
      healthAfterTheFirstParagraph: null,
      healthPostDynamic: null,
      healthAboveComments: null,
      healthBelowComments: null,
      healthFooter: null,
      healthScripts: null,
    }),

    getters: {
      isHealth: () => {
        const route = useRoute();
        return isHealthPath(route.path);
      },

      currentLeftSidebarWidgets(state) {
        const pageDataStore = usePageStore();
        return (
          state.leftSidebar?.filter((item) =>
            isVisible(pageDataStore.currentVisibilityData, item.visibility),
          ) ?? []
        );
      },
      currentSidebarWidgets(state): TWidget[] {
        const pageDataStore = usePageStore();
        const sidebar = this.isHealth ? state.healthSidebar : state.sidebar;
        return (
          sidebar?.filter((item) =>
            isVisible(pageDataStore.currentVisibilityData, item.visibility),
          ) ?? []
        );
      },
      currentAboveHeaderWidgets(state): TWidget[] {
        const pageDataStore = usePageStore();
        const aboveHeader = this.isHealth ? state.healthAboveMenu : state.aboveHeader;
        return (
          aboveHeader?.filter((item) =>
            isVisible(pageDataStore.currentVisibilityData, item.visibility),
          ) ?? []
        );
      },
      currentBelowHeaderWidgets(state): TWidget[] {
        const pageDataStore = usePageStore();
        const belowHeader = this.isHealth ? state.healthBelowMenu : state.belowHeader;
        return (
          belowHeader?.filter((item) =>
            isVisible(pageDataStore.currentVisibilityData, item.visibility),
          ) ?? []
        );
      },
      currentBetweenPostsWidgets(state) {
        const pageDataStore = usePageStore();
        return (
          state.betweenPosts?.filter((item) =>
            isVisible(pageDataStore.currentVisibilityData, item.visibility),
          ) ?? []
        );
      },
      currentAfterExcerptWidgets(state): TWidget[] {
        const pageDataStore = usePageStore();
        const afterExcerpt = this.isHealth
          ? state.healthAfterTheFirstParagraph
          : state.afterExcerpt;
        return (
          afterExcerpt?.filter((item) =>
            isVisible(pageDataStore.currentVisibilityData, item.visibility),
          ) ?? []
        );
      },
      currentAboveCommentsWidgets(state): TWidget[] {
        const aboveComments = this.isHealth ? state.healthAboveComments : state.aboveComments;
        const pageDataStore = usePageStore();
        return (
          aboveComments?.filter((item) =>
            isVisible(pageDataStore.currentVisibilityData, item.visibility),
          ) ?? []
        );
      },
      currentBelowCommentsWidgets(state): TWidget[] {
        const pageDataStore = usePageStore();
        const belowComments = this.isHealth ? state.healthBelowComments : state.belowComments;
        return (
          belowComments?.filter((item) =>
            isVisible(pageDataStore.currentVisibilityData, item.visibility),
          ) ?? []
        );
      },
      currentFooterWidgets(state): TWidget[] {
        const pageDataStore = usePageStore();
        const footer = this.isHealth ? state.healthFooter : state.footer;
        return (
          footer?.filter((item) =>
            isVisible(pageDataStore.currentVisibilityData, item.visibility),
          ) ?? []
        );
      },
      currentPostDynamicWidgets(state): TWidget[] {
        const pageDataStore = usePageStore();
        const postDynamic = this.isHealth ? state.healthPostDynamic : state.postDynamic;
        return (
          postDynamic?.filter((item) =>
            isVisible(pageDataStore.currentVisibilityData, item.visibility),
          ) ?? []
        );
      },
      currentAmpAfterFirstParagraphWidgets(state) {
        const enabledTypesWidget = ['parsed-html'];
        return (
          state.ampAfterFirstParagraph?.filter((item) => enabledTypesWidget.includes(item.type)) ??
          []
        );
      },

      // * RECIPES
      currentRecipeSidebar(state) {
        const visibilityStore = useVisibilityDataStore();
        return (
          state.recipeSidebar?.filter((item) =>
            isRecipesWidgetVisible(
              visibilityStore.currentRecipesVisibilityData,
              item?.visibility ?? null,
            ),
          ) ?? []
        );
      },
      currentRecipesSidebar(state) {
        const visibilityStore = useVisibilityDataStore();
        return (
          state.recipesSidebar?.filter((item) =>
            isRecipesWidgetVisible(
              visibilityStore.currentRecipesVisibilityData,
              item?.visibility ?? null,
            ),
          ) ?? []
        );
      },
      currentBetweenRecipes(state) {
        const visibilityStore = useVisibilityDataStore();
        return (
          state.betweenRecipes?.filter((item) =>
            isRecipesWidgetVisible(
              visibilityStore.currentRecipesVisibilityData,
              item?.visibility ?? null,
            ),
          ) ?? []
        );
      },
      currentAboveRecipeComments(state) {
        const visibilityStore = useVisibilityDataStore();
        return (
          state.aboveRecipeComments?.filter((item) =>
            isRecipesWidgetVisible(
              visibilityStore.currentRecipesVisibilityData,
              item?.visibility ?? null,
            ),
          ) ?? []
        );
      },
      currentBelowRecipeComments(state) {
        const visibilityStore = useVisibilityDataStore();
        return (
          state.belowRecipeComments?.filter((item) =>
            isRecipesWidgetVisible(
              visibilityStore.currentRecipesVisibilityData,
              item?.visibility ?? null,
            ),
          ) ?? []
        );
      },
      currentAboveRecipeHeader(state) {
        const visibilityStore = useVisibilityDataStore();
        return (
          state.aboveRecipeHeader?.filter((item) =>
            isRecipesWidgetVisible(
              visibilityStore.currentRecipesVisibilityData,
              item?.visibility ?? null,
            ),
          ) ?? []
        );
      },
      currentBelowRecipeHeader(state) {
        const visibilityStore = useVisibilityDataStore();
        return (
          state.belowRecipeHeader?.filter((item) =>
            isRecipesWidgetVisible(
              visibilityStore.currentRecipesVisibilityData,
              item?.visibility ?? null,
            ),
          ) ?? []
        );
      },
      currentInsideRecipe(state) {
        const visibilityStore = useVisibilityDataStore();
        return (
          state.insideRecipe?.filter((item) =>
            isRecipesWidgetVisible(
              visibilityStore.currentRecipesVisibilityData,
              item?.visibility ?? null,
            ),
          ) ?? []
        );
      },

      // * HEALTH
      currentHealthSidebar(state) {
        const pageDataStore = usePageStore();
        return state.healthSidebar?.filter(
          (item) => isVisible(pageDataStore.currentVisibilityData, item?.visibility) ?? [],
        );
      },
      currentHealthMainPage(state) {
        const pageDataStore = usePageStore();
        return state.healthMainPage?.filter(
          (item) => isVisible(pageDataStore.currentVisibilityData, item?.visibility) ?? [],
        );
      },
    },

    actions: {
      // Therese gotta be a better way to do it
      commitWidgetsDataWithLocation(data: any) {
        Object.keys(data).forEach((key) => {
          trimWpDomainInPlace(data[key], ['items.url', 'items.author.profile']);
        });

        // LH widgets
        data[WIDGETS_NAMES_IN_SERVER.sidebar] &&
          this.updateSidebarWidgets(
            addLocationWidget(
              data[WIDGETS_NAMES_IN_SERVER.sidebar],
              WIDGETS_NAMES_IN_SERVER.sidebar,
            ),
          );
        data[WIDGETS_NAMES_IN_SERVER.footer] &&
          this.updateFooterWidgets(
            addLocationWidget(data[WIDGETS_NAMES_IN_SERVER.footer], WIDGETS_NAMES_IN_SERVER.footer),
          );
        data[WIDGETS_NAMES_IN_SERVER.aboveHeader] &&
          this.updateWidgetsAboveHeader(
            addLocationWidget(
              data[WIDGETS_NAMES_IN_SERVER.aboveHeader],
              WIDGETS_NAMES_IN_SERVER.aboveHeader,
            ),
          );
        data[WIDGETS_NAMES_IN_SERVER.belowHeader] &&
          this.updateWidgetsBelowHeader(
            addLocationWidget(
              data[WIDGETS_NAMES_IN_SERVER.belowHeader],
              WIDGETS_NAMES_IN_SERVER.belowHeader,
            ),
          );
        data[WIDGETS_NAMES_IN_SERVER.betweenPosts] &&
          this.updateWidgetsBetweenPosts(
            addLocationWidget(
              data[WIDGETS_NAMES_IN_SERVER.betweenPosts],
              WIDGETS_NAMES_IN_SERVER.betweenPosts,
            ),
          );
        data[WIDGETS_NAMES_IN_SERVER.aboveComments] &&
          this.updateWidgetsAboveComments(
            addLocationWidget(
              data[WIDGETS_NAMES_IN_SERVER.aboveComments],
              WIDGETS_NAMES_IN_SERVER.aboveComments,
            ),
          );
        data[WIDGETS_NAMES_IN_SERVER.belowComments] &&
          this.updateWidgetsBelowComments(
            addLocationWidget(
              data[WIDGETS_NAMES_IN_SERVER.belowComments],
              WIDGETS_NAMES_IN_SERVER.belowComments,
            ),
          );
        data[WIDGETS_NAMES_IN_SERVER.afterExcerpt] &&
          this.updateWidgetsAfterExcerpt(
            addLocationWidget(
              data[WIDGETS_NAMES_IN_SERVER.afterExcerpt],
              WIDGETS_NAMES_IN_SERVER.afterExcerpt,
            ),
          );
        data[WIDGETS_NAMES_IN_SERVER.postDynamic] &&
          this.updateWidgetsPostDynamic(
            addLocationWidget(
              data[WIDGETS_NAMES_IN_SERVER.postDynamic],
              WIDGETS_NAMES_IN_SERVER.postDynamic,
            ),
          );
        data[WIDGETS_NAMES_IN_SERVER.ampAfterFirstParagraph] &&
          this.updateWidgetsAmpAfterFirstParagraph(
            addLocationWidget(
              data[WIDGETS_NAMES_IN_SERVER.ampAfterFirstParagraph],
              WIDGETS_NAMES_IN_SERVER.ampAfterFirstParagraph,
            ),
          );
        data[WIDGETS_NAMES_IN_SERVER.leftSidebar] &&
          this.updateWidgetsLeftSidebar(
            addLocationWidget(
              data[WIDGETS_NAMES_IN_SERVER.leftSidebar],
              WIDGETS_NAMES_IN_SERVER.leftSidebar,
            ),
          );

        // * Recipes widgets
        data[WIDGETS_NAMES_IN_SERVER.recipesAboveHeader] &&
          this.updateWidgetsAboveRecipeHeader(data[WIDGETS_NAMES_IN_SERVER.recipesAboveHeader]);
        data[WIDGETS_NAMES_IN_SERVER.recipesBelowHeader] &&
          this.updateWidgetsBelowRecipeHeader(data[WIDGETS_NAMES_IN_SERVER.recipesBelowHeader]);
        data[WIDGETS_NAMES_IN_SERVER.recipesListSidebar] &&
          this.updateWidgetsRecipesSidebar(data[WIDGETS_NAMES_IN_SERVER.recipesListSidebar]);
        data[WIDGETS_NAMES_IN_SERVER.recipeSidebar] &&
          this.updateWidgetsRecipeSidebar(data[WIDGETS_NAMES_IN_SERVER.recipeSidebar]);
        data[WIDGETS_NAMES_IN_SERVER.recipesAboveComments] &&
          this.updateWidgetsAboveRecipeComments(data[WIDGETS_NAMES_IN_SERVER.recipesAboveComments]);
        data[WIDGETS_NAMES_IN_SERVER.recipesBelowComments] &&
          this.updateWidgetsBelowRecipeComments(data[WIDGETS_NAMES_IN_SERVER.recipesBelowComments]);
        data[WIDGETS_NAMES_IN_SERVER.recipesBelowIngredients] &&
          this.updateWidgetsInsideRecipe(data[WIDGETS_NAMES_IN_SERVER.recipesBelowIngredients]);
        data[WIDGETS_NAMES_IN_SERVER.recipesBetweenPosts] &&
          this.updateWidgetsBetweenRecipes(data[WIDGETS_NAMES_IN_SERVER.recipesBetweenPosts]);

        // * Health widgets
        data[WIDGETS_NAMES_IN_SERVER.healthMainPage] &&
          this.updateWidgetsHealthMain(
            addLocationWidget(
              data[WIDGETS_NAMES_IN_SERVER.healthMainPage],
              WIDGETS_NAMES_IN_SERVER.healthMainPage,
            ),
          );
        data[WIDGETS_NAMES_IN_SERVER.healthSidebar] &&
          this.updateWidgetsHealthSidebar(
            addLocationWidget(
              data[WIDGETS_NAMES_IN_SERVER.healthSidebar],
              WIDGETS_NAMES_IN_SERVER.healthSidebar,
            ),
          );
        data[WIDGETS_NAMES_IN_SERVER.healthAboveMenu] &&
          this.updateWidgetsHealthAboveMenu(
            addLocationWidget(
              data[WIDGETS_NAMES_IN_SERVER.healthAboveMenu],
              WIDGETS_NAMES_IN_SERVER.healthAboveMenu,
            ),
          );
        data[WIDGETS_NAMES_IN_SERVER.healthBelowMenu] &&
          this.updateWidgetsHealthBelowMenu(
            addLocationWidget(
              data[WIDGETS_NAMES_IN_SERVER.healthBelowMenu],
              WIDGETS_NAMES_IN_SERVER.healthBelowMenu,
            ),
          );
        data[WIDGETS_NAMES_IN_SERVER.healthAboveComments] &&
          this.updateWidgetsHealthAboveComments(
            addLocationWidget(
              data[WIDGETS_NAMES_IN_SERVER.healthAboveComments],
              WIDGETS_NAMES_IN_SERVER.healthAboveComments,
            ),
          );
        data[WIDGETS_NAMES_IN_SERVER.healthBelowComments] &&
          this.updateWidgetsHealthBelowComments(
            addLocationWidget(
              data[WIDGETS_NAMES_IN_SERVER.healthBelowComments],
              WIDGETS_NAMES_IN_SERVER.healthBelowComments,
            ),
          );
        data[WIDGETS_NAMES_IN_SERVER.healthFooter] &&
          this.updateWidgetsHealthFooter(
            addLocationWidget(
              data[WIDGETS_NAMES_IN_SERVER.healthFooter],
              WIDGETS_NAMES_IN_SERVER.healthFooter,
            ),
          );
        data[WIDGETS_NAMES_IN_SERVER.healthAfterTheFirstParagraph] &&
          this.updateWidgetsHealthAfterFirstParagraph(
            addLocationWidget(
              data[WIDGETS_NAMES_IN_SERVER.healthAfterTheFirstParagraph],
              WIDGETS_NAMES_IN_SERVER.healthAfterTheFirstParagraph,
            ),
          );
        data[WIDGETS_NAMES_IN_SERVER.healthPostDynamic] &&
          this.updateWidgetsHealthPostDynamics(
            addLocationWidget(
              data[WIDGETS_NAMES_IN_SERVER.healthPostDynamic],
              WIDGETS_NAMES_IN_SERVER.healthPostDynamic,
            ),
          );
        data[WIDGETS_NAMES_IN_SERVER.healthScripts] &&
          this.updateWidgetsHealthScripts(data[WIDGETS_NAMES_IN_SERVER.healthScripts]);
        return data;
      },

      async fetchWidgets(widgetsToRequest: WidgetName[]) {
        const { $wordpressApi, $redisClient } = useNuxtApp();

        const { data } = await cacheApiResponseWithRedis(
          FETCH_WIDGETS_REDIS_KEY + JSON.stringify(widgetsToRequest),
          () => $wordpressApi.fetchWidgets(widgetsToRequest),
          $redisClient,
          60 * 60, // 1 hour
        );

        const parsedResponse = parseData(data, fetchWidgetsSchema);

        stripExtraPropertiesInPlace(parsedResponse);
        return this.commitWidgetsDataWithLocation(parsedResponse);
      },

      async fetchRecipeWidgets(widgetsToRequest: WidgetName[]) {
        const { $wordpressApi, $redisClient } = useNuxtApp();
        const route = useRoute();
        const visibilityStore = useVisibilityDataStore();

        const pathName = route.fullPath;

        const { data } = await cacheApiResponseWithRedis(
          FETCH_RECIPE_WIDGETS_REDIS_KEY + JSON.stringify(widgetsToRequest),
          () => $wordpressApi.fetchRecipeWidgets(pathName, widgetsToRequest),
          $redisClient,
          60 * 60, // 1 hour
        );

        const parsedResponse = parseData(data, fetchRecipesWidgetsSchema);

        this.commitWidgetsDataWithLocation(parsedResponse);

        visibilityStore.setRecipesVisibilityData({
          fullPath: pathName,
          data: {
            ingredients: parsedResponse?.visibilityData?.ingredients ?? [],
            dishTypes: parsedResponse?.visibilityData?.dishTypes ?? [],
            cuisines: parsedResponse?.visibilityData?.cuisines ?? [],
            keywords: parsedResponse?.visibilityData?.keywords ?? [],
            cookingMethods: parsedResponse?.visibilityData?.cookingMethods ?? [],
          },
        });
      },

      async fetchHealthWidgets(widgetsToRequest: WidgetName[]) {
        const { $wordpressApi, $redisClient } = useNuxtApp();

        const { data } = await cacheApiResponseWithRedis(
          FETCH_HEALTH_WIDGETS_REDIS_KEY + JSON.stringify(widgetsToRequest),
          () => $wordpressApi.fetchWidgets(widgetsToRequest),
          $redisClient,
          60 * 60, // 1 hour
        );

        const parsedResponse = parseData(data, fetchWidgetsSchema);

        stripExtraPropertiesInPlace(parsedResponse);
        return this.commitWidgetsDataWithLocation(parsedResponse);
      },

      updatePostWidgets({ cacheKey, data, $redisClient }) {
        stripExtraPropertiesInPlace(data.body);
        this.commitWidgetsDataWithLocation(data.body);

        $redisClient?.set(`${cacheKey}`, data, 60 * 60); // 1 hour
      },

      updateWidgetsLeftSidebar(payload: TWidget[]) {
        this.leftSidebar = payload;
      },
      updateSidebarWidgets(payload: TWidget[]) {
        this.sidebar = payload;
      },
      updateFooterWidgets(payload: TWidget[]) {
        this.footer = payload;
      },
      updateWidgetsAboveHeader(payload: TWidget[]) {
        this.aboveHeader = payload;
      },
      updateWidgetsBetweenPosts(payload: TWidget[]) {
        this.betweenPosts = payload;
      },
      updateWidgetsBelowHeader(payload: TWidget[]) {
        this.belowHeader = payload;
      },
      updateWidgetsAfterExcerpt(payload: TWidget[]) {
        this.afterExcerpt = payload;
      },
      updateWidgetsAboveComments(payload: TWidget[]) {
        this.aboveComments = payload;
      },
      updateWidgetsBelowComments(payload: TWidget[]) {
        this.belowComments = payload;
      },
      updateWidgetsPostDynamic(payload: TWidget[]) {
        this.postDynamic = payload;
      },
      updateWidgetsAmpAfterFirstParagraph(payload: TWidget[]) {
        this.ampAfterFirstParagraph = payload;
      },
      // * RECIPES
      updateWidgetsRecipeSidebar(payload: WidgetInRecipes[]) {
        this.recipeSidebar = payload;
      },
      updateWidgetsRecipesSidebar(payload: WidgetInRecipes[]) {
        this.recipesSidebar = payload;
      },
      updateWidgetsBetweenRecipes(payload: WidgetInRecipes[]) {
        this.betweenRecipes = payload;
      },
      updateWidgetsAboveRecipeComments(payload: WidgetInRecipes[]) {
        this.aboveRecipeComments = payload;
      },
      updateWidgetsBelowRecipeComments(payload: WidgetInRecipes[]) {
        this.belowRecipeComments = payload;
      },
      updateWidgetsAboveRecipeHeader(payload: WidgetInRecipes[]) {
        this.aboveRecipeHeader = payload;
      },
      updateWidgetsBelowRecipeHeader(payload: WidgetInRecipes[]) {
        this.belowRecipeHeader = payload;
      },
      updateWidgetsInsideRecipe(payload: WidgetInRecipes[]) {
        this.insideRecipe = payload;
      },
      // * Health
      updateWidgetsHealthMain(payload: TWidget[]) {
        this.healthMainPage = payload;
      },
      updateWidgetsHealthSidebar(payload: TWidget[]) {
        this.healthSidebar = payload;
      },
      updateWidgetsHealthAboveMenu(payload: TWidget[]) {
        this.healthAboveMenu = payload;
      },
      updateWidgetsHealthBelowMenu(payload: TWidget[]) {
        this.healthBelowMenu = payload;
      },
      updateWidgetsHealthAboveComments(payload: TWidget[]) {
        this.healthAboveComments = payload;
      },
      updateWidgetsHealthBelowComments(payload: TWidget[]) {
        this.healthBelowComments = payload;
      },
      updateWidgetsHealthFooter(payload: TWidget[]) {
        this.healthFooter = payload;
      },
      updateWidgetsHealthAfterFirstParagraph(payload: TWidget[]) {
        this.healthAfterTheFirstParagraph = payload;
      },
      updateWidgetsHealthPostDynamics(payload: TWidget[]) {
        this.healthPostDynamic = payload;
      },
      updateWidgetsHealthScripts(payload: TWidget[]) {
        this.healthScripts = payload;
      },
    },
  }),
);

export const TIMEOUT = 20000;

export const DEFAULT_VIQEO_PLAYER_ID = 2349;
export const DEFAULT_VIQEO_PROFILE_ID = 10099;

export const IS_NEW_YEAR_LOGO = false;

// Google
export const DISABLE_GOOGLE_ADS = false;
export const AMP_GOOGLE_ADSENSE_AD_CLIENT = 'ca-pub-4306008583934527';
export const AMP_GOOGLE_ADSENSE_AD_SLOT_DEFAULT = '1730685615';
export const AMP_GOOGLE_ADSENSE_AD_SLOT_RECIPE = '8805495315';

export const GOOGLE_CX = '001202538024458911449:tmn7mxctxjo';

/**
 Этот плагин загружает через сеть скрипты из зоны виджетов `СКРИПТЫ` админки /wp-admin
 Далее выполняет инлайновый JS или вставляет в head, если есть `src`
 Эти скрипты необходимы для отображения рекламных баннеров и брендирования
 На момент написания комментария это только ADFOX
 */
import type { IWordpressApi } from '~/api/wordpress';
import { Scripts } from '~/libs/Scripts';
import separateScriptsAndContent from '~/libs/separateScriptsAndContent';
import { useIndexStore } from '~/store';
import { useWidgetsStore } from '~/store/widgets';
import {
  isAnyRecipePage,
  isHealthAboutProjectPage,
  isHealthExpertsPage,
  isHealthPath,
} from '~/utils/router';
import type { Logger } from '@devhacker/shared/libs/logger';
import type { NuxtApp } from '#app';

const runInitialScripts = async ($wordpressApi: IWordpressApi, $logger: Logger): Promise<void> => {
  const indexStore = useIndexStore();
  const widgetsStore = useWidgetsStore();
  const log = $logger.withScope('head-content-from-server');

  let parsedHtmlWidgets: TWidget[] = [];
  const route = useRoute();

  // для страниц экспертов и о проекте не отображаем брендирование
  if (isHealthExpertsPage(route.path) || isHealthAboutProjectPage(route.path)) {
    log('isHealthExpertsPage or isHealthAboutProjectPage');
    return;
  }

  if (isAnyRecipePage(route.name as string)) {
    log('isAnyRecipePage');
    try {
      const { data, status } = await $wordpressApi.fetchRecipeScripts();
      if (status === 200) {
        parsedHtmlWidgets = data.filter((item) => item.type === 'parsed-html');
      }
    } catch (error) {
      console.error(error);
    }
  } else if (isHealthPath(route.path)) {
    log('isHealthPath');
    try {
      parsedHtmlWidgets =
        widgetsStore.healthScripts?.filter((item: TWidget) => item.type === 'parsed-html') || [];
    } catch (error) {
      console.error(error);
    }
  } else {
    log('regular');
    try {
      const { data, status } = await $wordpressApi.fetchHeadScriptsFromServer(route.path);
      if (status === 200) {
        parsedHtmlWidgets = data.widgets.filter((w: TWidget) => w.type === 'parsed-html');
      }
    } catch (error) {
      console.error(error);
    }
  }

  log('parsedHtmlWidgets', parsedHtmlWidgets);
  if (parsedHtmlWidgets.length) {
    const allInlineScripts: TScript[] = [];
    const allScripts: TScript[] = [];
    parsedHtmlWidgets.forEach((widget: TWidget) => {
      try {
        const { data } = widget;
        const { inlineScripts, scripts } = separateScriptsAndContent(data);
        allInlineScripts.push(...inlineScripts);
        allScripts.push(...scripts);
      } catch (error) {
        log.error('Error processing widget', widget, error);
      }
    });

    /**
     * когда загрузятся все src-скрипты и выполнятся инлайновые, делаем коммит
     * чтобы запустить отображение баннеров
     */

    log('parsedHtmlWidgets', parsedHtmlWidgets);
    new Scripts(allScripts, allInlineScripts, () => {
      log('scripts loaded');
      indexStore.updateIsHeadScriptsLoaded(true);
    }).addScripts();
  }
};

export default defineNuxtPlugin((nuxtApp) => {
  const { $wordpressApi, $logger } = nuxtApp as NuxtApp;
  onNuxtReady(() => {
    runInitialScripts($wordpressApi, $logger);
  });
});

import PageScroll from '~/libs/PageScroll';
import { clearUserToken, getAccessToken, getSubId } from '~/utils';

import { useNuxtApp } from '#app';
import { useReactionsStore } from './reactions';
import { useArticlesStore } from './articles';
import { useFoldersStore } from './folders';
import { AUTH_API_URL } from '~/constants/config';
import { TIMEOUT } from '~/constants';
import { useTalkerStore } from '@devhacker/shared/plugins/talker/store/talker';
import { defineStore } from 'pinia';
import { wrapPiniaStore } from '@devhacker/shared/utils/wrapPiniaStore';
import { isRequiresAuth } from '~/utils';

// TODO: split into multiple stores, user|ui|etc.
export const useIndexStore = wrapPiniaStore(
  defineStore('index', {
    state: (): IRootState => ({
      visibleModalWindows: [],
      user: null,
      isUserLoading: false,
      userIntegerId: null,
      isAuthenticatedInWordpress: false,
      isSubscribedToWebPushNotifications: false,
      isHeadScriptsLoaded: false,
      authMethodsRestricted: null,
      isSendRefreshToken: false,
      afterLoginAction: undefined,
    }),

    actions: {
      async fetchIsWpLoggedIn() {
        const { $wordpressApi } = useNuxtApp();
        try {
          const { data } = await $wordpressApi.fetchIsLoggedInWordpress();
          this.updateWordpressAuthentication(data.logged ?? false);
        } catch (err) {
          console.log('fetchIsLoggedInWordpress', err);
        }
      },

      async fetchUser(subId: number, createAxiosInstance = false) {
        const accessToken = getAccessToken();
        this.isUserLoading = true;

        if (createAxiosInstance) {
          const { data } = await useFetch(`${AUTH_API_URL}/api/v1/users/${subId}`, {
            timeout: TIMEOUT,
            headers: {
              Authorization: `Bearer ${accessToken}`,
              'x-auth-app': 'web',
            },
          });

          // @ts-expect-error no types for use fetch
          this.updateUser(data.value?.data);
          this.isUserLoading = false;
          return data;
        }

        const userData = await useNuxtApp().$authApi.getUserData(accessToken, subId);

        // @ts-expect-error no types for use fetch
        this.updateUser(userData.data.data);
        this.isUserLoading = false;
        return userData;
      },

      showNav() {
        this.visibleModalWindows = this.visibleModalWindows.filter(
          (visible) => visible === 'search',
        );
        this.visibleModalWindows.push('nav');
        PageScroll.disable();
      },

      hideNav() {
        this.visibleModalWindows = this.visibleModalWindows.filter((visible) => visible !== 'nav');
        PageScroll.enable();
      },

      updateUser(user: IUser) {
        this.user = Object.seal(user);
      },

      updateUserProperty({ property, value }: { property: keyof IUser; value: unknown }) {
        if (this.user) {
          // TODO: check weird typescript without typecast of property
          (this.user[property] as typeof value) = value;
        }
      },

      clearUser() {
        this.user = null;
        this.userIntegerId = null;
      },

      clearUserAndRelatedData() {
        this.clearUser();
        useArticlesStore().clearUserRelatedData();
        useFoldersStore().clearFolders();
      },

      updateWordpressAuthentication(isLoggedIn: boolean) {
        this.isAuthenticatedInWordpress = isLoggedIn;
      },

      subscribeToWebPushNotifications() {
        this.isSubscribedToWebPushNotifications = true;
      },

      unsubscribeFromWebPushNotifications() {
        this.isSubscribedToWebPushNotifications = false;
      },

      updateIsHeadScriptsLoaded(isHeadScriptsLoaded: boolean) {
        this.isHeadScriptsLoaded = isHeadScriptsLoaded;
      },

      addIntegerIdUser(subId: number) {
        this.userIntegerId = subId;
      },

      setAuthMethodsRestricted(isRestricted: boolean) {
        this.authMethodsRestricted = isRestricted;
      },

      async fetchUserLocation() {
        const { $authApi } = useNuxtApp();

        const { data } = await $authApi.fetchUserLocation();
        this.setAuthMethodsRestricted(data.isRestricted);
      },

      setUserRTAction(isSend: boolean) {
        this.isSendRefreshToken = isSend;
      },

      async logout() {
        const { $authApi } = useNuxtApp();
        const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

        await $authApi.logout();
        clearUserToken();
        this.clearUserAndRelatedData();
        useTalkerStore().clearUserData();

        if (isSafari) {
          window.location.reload();
        } else if (isRequiresAuth(useRoute())) {
          location.href = '/';
        }
      },

      async updateUserWithReactions(payload: { user: IUser }) {
        this.updateUser(payload.user);
        this.addIntegerIdUser(payload.user.integer_id);
        await useReactionsStore().refetchReactions();
      },

      async clearUserAndReactions() {
        this.clearUser();
        useReactionsStore().refetchReactions();
      },

      // TODO: check payload type
      patchUserProperty(payload: any) {
        const subId = getSubId();
        if (subId) {
          const { $authApi } = useNuxtApp();

          return $authApi
            .patchUserProperty(subId, payload)
            .then((resp: any) => {
              if (resp.status === 200) {
                this.updateUserProperty(payload);
                return Promise.resolve(resp);
              }
            })
            .catch((error: any) => Promise.reject(error));
        }
      },

      disableSocialAccount(payload: string) {
        const subId = getSubId();
        if (subId) {
          const { $authApi } = useNuxtApp();

          return $authApi.removeUserSocialAccount(getAccessToken(), subId, payload);
        }
      },

      deleteUserAccount(payload: { code: string; comment: string }) {
        const subId = getSubId();
        if (subId) {
          const { $authApi } = useNuxtApp();

          return $authApi.removeUserAccount(getAccessToken(), subId, payload);
        }
      },
    },
  }),
);

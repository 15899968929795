import { createAxiosLikeFetch } from '@devhacker/shared/libs/fetch/createAxiosLikeFetch';
import { createLoggerInterceptors } from '@devhacker/shared/libs/logger';
import { TIMEOUT } from '~/constants';

import { formattingIMApitoDefaultApi } from '~/utils/formattingData';
import { API_GATEWAY } from '~/constants/config';
import {
  FETCH_WIDGETS_REDIS_KEY,
  FETCH_HEALTH_WIDGETS_REDIS_KEY,
  FETCH_RECIPE_WIDGETS_REDIS_KEY,
  POST_WIDGETS,
  RECIPE_POST_WIDGETS,
  HEALTH_POST_WIDGETS,
} from '~/constants/widgets';

const headers = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
  'Accept-Encoding': 'identity',
};

export const createGatewayApi = ({ $logger, $redisClient, widgetsStore }: any) => {
  const gatewayFetch = createAxiosLikeFetch({
    baseURL: API_GATEWAY,
    timeout: TIMEOUT,
    headers,
    ...createLoggerInterceptors($logger, 'api-gateway'),
  });

  const log = $logger.withScope('api-gateway');

  const parseBodyItem = <T extends object>(item: T) => {
    try {
      item.body = JSON.parse(item.body);
      return item;
    } catch (caughtError) {
      log.error('failed to parse body in response', caughtError, item);
    }

    return { status: 500, body: null };
  };

  return {
    async getArticleData(slug: string) {
      // Страницы здоровья приходят без слэша, добавляем по необходимости
      const slugWithSlash = slug.startsWith('/') ? slug : `/${slug}`;

      const pipeline = [
        {
          method: 'GET',
          path: `/posts/by-slug/v2${slugWithSlash}`,
          headers,
        },
        {
          headers: {
            'x-app': 'lifehacker-web',
            ...headers,
          },
          method: 'GET',
          // format_ver=1 означает что ответ будет плоским, без children
          path: `/comments/by-post-slug${slugWithSlash}?format_ver=1&sort=oldest`,
        },
      ];

      const key = `${FETCH_WIDGETS_REDIS_KEY}${JSON.stringify(POST_WIDGETS)}`;
      const widgetsCache = await $redisClient?.get(key);

      if (!widgetsCache && !widgetsStore.footer) {
        pipeline.push({
          method: 'GET',
          path: `/posts/widgets/?includes=${POST_WIDGETS.join(',')}`,
          headers,
        });
      }

      log('pipeline', pipeline);
      const response = await gatewayFetch('batch-requests', {
        method: 'post',
        body: { pipeline },
      });

      const articleStatus = response.data?.[0].status;
      if (articleStatus && ![200, 301].includes(articleStatus)) {
        log.error('error', articleStatus);
        throw createError({
          statusCode: articleStatus,
          message: `article inside batch-requests returned ${articleStatus}`,
          stack: new Error().stack,
        });
      }

      const preparedResponse = {
        article: parseBodyItem(response.data[0]),
        comments: parseBodyItem(response.data[1]),
        widgets: widgetsCache ?? parseBodyItem(response.data[2]),
      };

      log('article', preparedResponse.article);
      log('comments', preparedResponse.comments);
      log('widgets', preparedResponse.widgets);

      if (preparedResponse.article.status === 200) {
        preparedResponse.article.body = formattingIMApitoDefaultApi(preparedResponse.article.body);
      }

      return preparedResponse;
    },
    async getHealthArticleData(slug: string) {
      const pipeline = [
        {
          method: 'GET',
          path: `/health/by-slug/${slug}`,
          headers,
        },
        {
          headers: {
            ...headers,
            'x-app': 'lifehacker-web',
          },
          method: 'GET',
          // format_ver=1 означает что ответ будет плоским, без children
          path: `/comments/by-health-slug/${slug}?format_ver=1&sort=oldest`,
        },
      ];

      const key = `${FETCH_HEALTH_WIDGETS_REDIS_KEY}${JSON.stringify(HEALTH_POST_WIDGETS)}`;
      const widgetsCache = await $redisClient?.get(key);

      if (!widgetsCache) {
        pipeline.push({
          headers,
          method: 'GET',
          path: `/posts/widgets/?includes=${HEALTH_POST_WIDGETS.join(',')}`,
        });
      }

      log('pipeline', pipeline);
      const response = await gatewayFetch('batch-requests', {
        method: 'post',
        body: { pipeline },
      });

      const articleStatus = response.data?.[0].status;
      if (articleStatus && ![200, 301].includes(articleStatus)) {
        throw createError({
          statusCode: articleStatus,
          message: `health article inside batch-requests returned ${articleStatus}`,
          stack: new Error().stack,
        });
      }

      const preparedResponse = {
        article: parseBodyItem(response.data[0]),
        comments: parseBodyItem(response.data[1]),
        widgets: widgetsCache ?? parseBodyItem(response.data[2]),
      };

      log('article', preparedResponse.article);
      log('comments', preparedResponse.comments);
      log('widgets', preparedResponse.widgets);

      if (preparedResponse.article.status === 200) {
        preparedResponse.article.body = formattingIMApitoDefaultApi(preparedResponse.article.body);
      }

      return preparedResponse;
    },
    async getRecipeData(slug: string) {
      const pipeline = [
        {
          method: 'GET',
          path: `/recipes/by-slug/${slug}`,
          headers,
        },
        {
          headers: {
            ...headers,
            'x-app': 'lifehacker-web',
          },
          method: 'GET',
          // format_ver=1 означает что ответ будет плоским, без children
          path: `/comments/by-recipe-slug/${slug}?format_ver=1&sort=oldest`,
        },
      ];

      const key = `${FETCH_RECIPE_WIDGETS_REDIS_KEY}${JSON.stringify(RECIPE_POST_WIDGETS)}`;
      const widgetsCache = await $redisClient?.get(key);
      if (!widgetsCache && !widgetsStore.recipesAboveComments) {
        pipeline.push({
          headers,
          method: 'GET',
          path: `/recipes/widgets/?includes=${RECIPE_POST_WIDGETS.join(',')}`,
        });
      }

      log('pipeline', pipeline);
      const response = await gatewayFetch('batch-requests', {
        method: 'post',
        body: { pipeline },
      });

      const articleStatus = response.data?.[0].status;
      if (articleStatus && ![200, 301].includes(articleStatus)) {
        throw createError({
          statusCode: articleStatus,
          message: `recipe inside batch-requests returned ${articleStatus}`,
          stack: new Error().stack,
        });
      }

      const preparedResponse = {
        recipe: parseBodyItem(response.data[0]),
        comments: parseBodyItem(response.data[1]),
        widgets: widgetsCache ?? parseBodyItem(response.data[2]),
      };

      log('recipe', preparedResponse.recipe);
      log('comments', preparedResponse.comments);
      log('widgets', preparedResponse.widgets);

      return preparedResponse;
    },
  };
};

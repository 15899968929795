import type { PageType } from '~/services/health/pageType';
import { defineStore } from 'pinia';
import { wrapPiniaStore } from '@devhacker/shared/utils/wrapPiniaStore';

export const useHealthStore = wrapPiniaStore(
  defineStore('health', {
    state: () => ({
      pageType: null as PageType | null,
    }),

    actions: {
      setPageType(pageType: PageType | null) {
        this.pageType = pageType;
      },
    },

    getters: {
      isHealthCategory: (state): boolean => state.pageType === 'category-health',
      isHealthArticle: (state): boolean => state.pageType === 'health',
      isHealthPage: (state): boolean => state.pageType === 'page',
    },
  }),
);

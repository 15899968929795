import { isHomePage } from '~/utils/router';
import { defineStore } from 'pinia';
import { wrapPiniaStore } from '@devhacker/shared/utils/wrapPiniaStore';

export type AdfoxParams = {
  lh_adfox_homepage: boolean;
  lh_adfox_rtn: boolean;
  lh_adfox_noad: string;
  lh_adfox_noadsense: string;
  lh_adfox_pk: string;
  lh_adfox_postid: string;
  lh_adfox_streams: string;
  lh_adfox_tags: string;
  lh_adfox_terms: string;
  lh_adfox_ipv6: number;
};

export type AdfoxParamsWithIp = AdfoxParams & {
  lh_adfox_ipv6: 1 | 0;
};

const defaultAdfox: AdfoxParams = {
  lh_adfox_homepage: false,
  lh_adfox_rtn: false,
  lh_adfox_noad: '',
  lh_adfox_noadsense: '',
  lh_adfox_pk: '',
  lh_adfox_postid: '',
  lh_adfox_streams: '',
  lh_adfox_tags: '',
  lh_adfox_terms: '',
  lh_adfox_ipv6: 0,
};

type AdfoxParamsState = {
  isIpV6: boolean;
  byPath: {
    [path: string]: AdfoxParams;
  };
};

export const useAdfoxStore = wrapPiniaStore(
  defineStore('adfox', {
    state: (): AdfoxParamsState => ({
      isIpV6: false,
      byPath: {},
    }),

    getters: {
      currentAdfox: (state): AdfoxParamsWithIp => {
        const route = useRoute();
        const currentPath = route.fullPath.replace(route.hash, '');
        const currentData = state.byPath[currentPath] ?? defaultAdfox;

        // Ensure `lh_adfox_homepage` is true on the homepage
        if (isHomePage(route.name)) {
          currentData.lh_adfox_homepage = true;
        }

        return { ...currentData, lh_adfox_ipv6: state.isIpV6 ? 1 : 0 };
      },
    },

    actions: {
      async addAdfoxIpV6() {
        const response = await useNuxtApp().$wordpressApi.defineIpv();
        this.isIpV6 = response.data.v === 6;
        useNuxtApp().$logger('adfox', this.isIpV6 ? 'ipv6' : 'ipv4');
      },
      addAdfoxParamsByPath(path: string, adfoxParams: AdfoxParams) {
        this.byPath[path] = adfoxParams;
      },
    },
  }),
);

import {
  createAxiosLikeFetch,
  type AxiosLikeFetchResponse,
} from '@devhacker/shared/libs/fetch/createAxiosLikeFetch';
import { createLoggerInterceptors } from '@devhacker/shared/libs/logger';
import { WORDPRESS_API_URL } from '~/constants/config';
import { TIMEOUT } from '~/constants';
import type { MascotMenu } from '~/types/store/mascot';

interface MascotMenus {
  items: {
    footer: MascotMenu;
    burger: MascotMenu;
  };
}

export interface IMascotApi {
  fetchMascotInfo: () => Promise<AxiosLikeFetchResponse<MascotMenus>>;
}

const URL_PREFIX = 'api/20/v1';

export const createMascotApi = ({ $logger }: any): IMascotApi => {
  const mascotFetch = createAxiosLikeFetch({
    baseURL: `${WORDPRESS_API_URL}/${URL_PREFIX}`,
    timeout: TIMEOUT,
    ...createLoggerInterceptors($logger, 'mascot'),
  });

  return {
    fetchMascotInfo: () => {
      return mascotFetch('/mascot');
    },
  };
};

/* eslint-disable @typescript-eslint/no-unused-vars */
// @ts-ignore
import { string, number, boolean, array, object, url, nullable, record } from 'valibot';
import { ImgSchema } from './generalSchemas';

const CoverMetaSizeSchema = object({
  file: string('Error on cover meta size file'),
  width: number('Error on  cover meta size width'),
  height: number('Error on cover meta size height'),
  'mime-type': string('Error on cover meta size mime-type'),
  filesize: number('Error on cover meta size filesize'),
  path: string('Error on cover meta size path'),
  full_path: url('Error on cover meta size full_path'),
});

// used in post schema but tmp not necessary
const CoverMetaSchema = object({
  width: number('Error on cover meta width'),
  height: number('Error on cover meta height'),
  file: string('Error on cover meta file'),
  filesize: number('Error on cover meta filesize'),
  // For 10 posts 10 different array of keys, so just use record
  sizes: record(string(), CoverMetaSizeSchema, 'Error on cover meta sizes'),
});

const SearchPostSchema = object({
  // this version can't parse empty array
  // post_cover_meta: union([CoverMetaSchema, array()]),
  // post_img: union([array(ImgSchema), array(void_)]),

  id: string('Error on id'),
  post_title: string('Error on post_title'),
  post_author: number('Error on post_author'),
  post_excerpt: string('Error on post_excerpt'),
  post_name: string('Error on post_name'),
  post_type: string('Error on post_type'),
  post_date: string('Error on post_date'),
  post_date_gmt: string('Error on post_date_gmt'),
  post_taxonomy_slug: array(string('Error on post_taxonomy_slug')),
  post_tag_slug_ru: array(string('Error on post_tag_slug_ru')),
  post_author_nicename: string('Error on post_author_nicename'),
  post_author_name: string('Error on post_author_name'),
  post_author_link: nullable(string('Error on post_author_link')),
  post_cover: string('Error on post_cover'),
  post_lh_advert: boolean('Error on post_lh_advert'),
  post_is_promo: boolean('Error on post_is_promo'),
  post_default_img: array(ImgSchema),
  post_category: array(number('Error on post_category')),
  post_category_slug_ru: array(string('Error on post_category_slug_ru')),
  post_category_slug: array(string('Error on post_category_slug')),
  post_category_links: array(
    object({
      id: number('Error on post_category_links id'),
      slug: string('Error on post_category_links slug'),
      slug_ru: string('Error on post_category_links slug_ru'),
      path: string('Error on post_category_links path'),
      link: url('Error on post_category_links link'),
    }),
  ),
  post_comments_enabled: boolean('Error on post_comments_enabled'),
  post_more_meta: string('Error on post_more_meta'),
});

export const FullSearchDataSchema = object({
  meta: object({
    count: number(),
    // We don't use it, so no details
    header: object({}),
  }),
  data: array(SearchPostSchema),
  status: boolean(),
});

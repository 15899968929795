import fetchApiGateway from '~/utils/api/fetch-apigateway';
import fetchSinglepost from '~/utils/api/fetch-singlepost';
import {
  isPreviewPage,
  isArticlePage,
  isAmpPage,
  isHealthArticleOrSubCategoryRoute,
  isHealthCategoryOrPageRoute,
  isAmpPageEndsWith,
  isTheRecipeAmpPage,
} from '~/utils/router';
import { useHealthStore } from '~/store/health';
import removeHostFromUrl from '@devhacker/shared/utils/removeHostFromUrl';

export default defineNuxtRouteMiddleware(async (to, from) => {
  if (import.meta.client) return;
  if ((isAmpPage(to.name) || isAmpPageEndsWith(to.fullPath)) && !isTheRecipeAmpPage(to.name))
    return fetchSinglepost(to);

  const healthStore = useHealthStore();
  const shouldCheckHealthPage =
    isHealthArticleOrSubCategoryRoute(to.name) || isHealthCategoryOrPageRoute(to.name);

  if (shouldCheckHealthPage) {
    const { $healthApi, $logger } = useNuxtApp();

    const { type, url } = await $healthApi.fetchTypeBySlug(to.path);

    // Correct path of health page
    const correctPath = removeHostFromUrl(url);

    // to.path can have wrong category or subcategory before slug
    if (correctPath !== to.path) {
      $logger('Redirected to correct health path');

      return navigateTo(correctPath, {
        redirectCode: 301,
      });
    }

    healthStore.setPageType(type);
  }

  if (
    isArticlePage(to.name) ||
    isPreviewPage(to.name) ||
    healthStore.isHealthArticle ||
    healthStore.isHealthPage
  ) {
    return await fetchApiGateway(to);
  }
});

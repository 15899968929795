<template>
  <NuxtLayout :name="layout">
    <div class="archive-page">
      <CategoriesList
        v-if="!isHealthHome && breadcrumbs.length > 0"
        class="archive-page__breadcrumbs"
        :categories="breadcrumbs"
        is-breadcrumbs
        last-blocked
      />
      <div
        v-if="!isHomePage"
        class="archive-page__top"
      >
        <ArchivePageTitle
          class="archive-page__title"
          :title="title"
          :icon="icon"
        />
        <TypeSwitch
          v-if="isTopPage"
          class="archive-page__switch"
          :title="title"
          :list="$options.topTypeList"
          @change-active="onChangeActive"
        />
      </div>

      <ArticleCardContainer
        :all-images-lazy="isHomePage"
        :page-title="title"
      />
      <a
        v-if="isHomePage && isButtonVisible"
        class="show-more"
        href="/list/?page=5"
        @click="onClickShowMore"
      >
        Показать ещё
      </a>
    </div>
  </NuxtLayout>
</template>

<script lang="ts">
  import ArticleCardContainer from '~/containers/ArticleCardContainer.vue';
  import ArchivePageTitle from '~/components/ArchivePageTitle.vue';
  import TypeSwitch from '~/components/TypeSwitch/TypeSwitch.vue';
  import {
    isTopPage,
    isHomePage,
    isArchivePage,
    isListAllPage,
    isHomeHealth,
    isHealthPath,
  } from '~/utils/router';
  import CategoriesList from '~/components/CategoriesList.vue';

  import { getActivePage, getFullUrl } from '~/utils';
  import { usePageStore } from '~/store/pagesData';
  import { getArticleFetchApi } from '~/utils/apiUtils';
  import { useSeoStore } from '~/store/seo';

  // данные переключателя типа из страницы с лучшими статьями
  export const topTypeList: Array<TypeListItem> = [
    {
      id: Symbol(),
      value: '/top/week/',
      activeValues: ['/top/week/', '/top/'],
      name: 'За неделю',
    },
    {
      id: Symbol(),
      value: '/top/month/',
      activeValues: ['/top/month/'],
      name: 'За месяц',
    },
    {
      id: Symbol(),
      value: '/top/year/',
      activeValues: ['/top/year/'],
      name: 'За год',
    },
  ];

  export default defineNuxtComponent({
    name: 'ArchivePage',

    components: {
      TypeSwitch,
      ArticleCardContainer,
      ArchivePageTitle,
      CategoriesList,
    },

    async setup() {
      // нет возможности менять динамически через эту штуку
      // поэтому мы отключаем глобальный и используем локальный тут
      definePageMeta({ layout: false });

      const app = useNuxtApp();
      const seoStore = useSeoStore();
      const pageStore = usePageStore();
      const { $logger, $wordpressApi } = app;
      const route = useRoute();
      const log = $logger.withScope('🏛️ archive-page');

      const pagesDataRoute = route.fullPath.replace(route.hash, '');
      const isLoadedArticlesForCurrentRoute = pageStore.pagesData[pagesDataRoute];

      log('isLoadedArticlesForCurrentRoute', Boolean(isLoadedArticlesForCurrentRoute));

      const isList = route.name === 'list-all';
      const { page = 1, limit = 30 } = route.query;

      const fetchSeoPromise = isList
        ? null
        : await $wordpressApi.fetchSeo(route.path, page as number, limit as number).catch(() => {});

      if (fetchSeoPromise && fetchSeoPromise?.status === 200) {
        const path = useRoute().fullPath.replace(route.hash, '');
        seoStore.addSeoByPath(path, fetchSeoPromise.data);
      }

      if (isLoadedArticlesForCurrentRoute) return;

      const pageNumber = getActivePage(route);
      log('pageNumber', pageNumber);

      const fetchApi = getArticleFetchApi(route, app);
      const fetchArticles = fetchApi.fetchArticles(route, pageNumber);

      const articlesWithSeoPromise = Promise.all([fetchArticles]);
      const [{ data }] = await articlesWithSeoPromise;

      const { items, totalPages, title, type, id, icon, breadcrumb } = data;

      pageStore.addArchiveData({
        path: pagesDataRoute,
        data: {
          type,
          id,
          totalPages,
          articles: items,
          title: title || '',
          icon,
          breadcrumb,
        },
      });
    },

    topTypeList,
    computed: {
      layout() {
        const route = useRoute();
        return isHealthPath(route.path) ? 'health' : 'default';
      },

      articles(): Array<IArticle> {
        return usePageStore().currentArticles;
      },

      routeName() {
        return useRoute().name;
      },

      breadcrumbs() {
        const currentPageData = usePageStore().currentPageData;

        if (!currentPageData) {
          return [];
        }

        if ('breadcrumb' in currentPageData) {
          return currentPageData.breadcrumb || [];
        }

        return [];
      },

      isHomePage(): boolean {
        return isHomePage(this.routeName);
      },

      isTopPage(): boolean {
        return isTopPage(this.routeName);
      },

      isArchivePage(): boolean {
        return isArchivePage(this.routeName);
      },

      isHealthRoute(): boolean {
        return isHealthPath(this.routeName);
      },

      isHealthHome(): boolean {
        return isHomeHealth(this.routeName);
      },

      isHealthPath(): boolean {
        return isHealthPath(this.routeName);
      },

      isButtonVisible(): boolean {
        // не 120 потому что некоторые дублирующиеся статьи могут удаляться
        // например если статья пришла сначала в ?page=1, а потом в ?page=2
        return this.articles.length >= 115;
      },
      title(): string {
        const currentTitle = usePageStore().currentTitle;

        if (currentTitle) {
          return currentTitle;
        }

        // Для главной не приходит title в новом апи, хардкод для списка всех статей
        return isListAllPage(this.routeName) ? 'Все статьи' : '';
      },
      icon(): string {
        return usePageStore().currentIcon;
      },
    },

    mounted() {
      this.$logger('mounted', true);
    },

    methods: {
      onClickShowMore(): void {
        this.$sendYandexMetrika({
          level1: 'Клик_Показать еще_Списочная карточка',
          level4: getFullUrl('/list/?page=5'),
          level6: this.title,
        });
      },

      getTypePage(): string {
        switch (true) {
          case this.isTopPage:
            return 'Лучшее';
          case this.isHomePage:
            return 'Главная';
          case this.isArchivePage:
            return 'Архивы';
          case this.isHealthPath:
            return 'Здоровье';
          default:
            return '';
        }
      },

      onChangeActive(path: string): void {
        location.href = path;
      },
    },
  });
</script>

<style lang="scss" scoped>
  //@use "~/scss/variables.scss" as *;
  //@use "~/scss/mixins/index.scss" as *;

  .archive-page {
    background: white;
    padding-bottom: 1px;
    height: 100%;
    font-family: $mainFont;

    &__widget-under-news {
      text-align: center;
      margin: 8px 0;
    }

    &__top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0px 16px 0;

      @include tablet {
        padding: 16px 24px 0;
      }
    }

    &__breadcrumbs {
      padding: 0 16px 16px;

      @include tablet {
        padding: 16px 24px 8px;
      }
    }
  }

  .show-more {
    @include fontTextBtn;

    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    margin: 8px 16px 24px;
    width: calc(100% - 32px);
    height: 56px;
    background: var(--main-color);
    color: white;
    border-radius: 2px;

    @include tablet {
      width: calc(100% - 48px);
      margin: 8px 24px 32px;
    }
    @include hover {
      background: var(--hover-color);
    }
  }
</style>

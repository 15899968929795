import type { AxiosResponse } from 'axios';
import { parseData } from '@devhacker/shared/utils/parseData';
import { getSubId } from '~/utils';
import { GetArticleViewsCountSchema } from '~/schemas/views';
import { FetchCommentsCountSchema } from '~/schemas/talker';
import { defineStore } from 'pinia';
import { wrapPiniaStore } from '@devhacker/shared/utils/wrapPiniaStore';

export interface ArticlesState {
  userRelated: {
    favorites: Array<IFavoriteItem>;
  };
  articleTitle: string;
  commentsCount: {
    [propName: string]: number;
  };
  viewsCount: {
    [propName: string]: number;
  };
}

export const useArticlesStore = wrapPiniaStore(
  defineStore('articles', {
    state: (): ArticlesState => ({
      userRelated: {
        favorites: [] as IFavoriteItem[],
      },
      articleTitle: '',
      commentsCount: {} as Record<string, number>,
      viewsCount: {} as Record<string, number>,
    }),

    getters: {
      getCommentsCount: (state) => (postId: number) => {
        return state.commentsCount[String(postId)] || 0;
      },

      currentArticleTitle: (state) => () => {
        return state.articleTitle;
      },

      getViewsCount: (state) => (postId: number) => {
        return Number(state.viewsCount[String(postId)]) || 0;
      },

      getArticleFavoriteId: (state) => (postId: number) => {
        const found = state.userRelated.favorites.find((f: IFavoriteItem) => f.post_id === postId);
        return found ? found.id : 0;
      },

      getArticleFavorite: (state) => (postId: number) => {
        return state.userRelated.favorites.find((f: IFavoriteItem) => f.post_id === postId) || null;
      },
    },

    actions: {
      async fetchAndSetFavorites(params: any, isUpdating = true) {
        const subId = getSubId();
        if (subId) {
          const { $authApi } = useNuxtApp();
          const resp: AxiosResponse = await $authApi.fetchFavorites(params, subId);
          if (resp.status === 200) {
            const favorites = resp.data.data;
            const newValue = isUpdating
              ? favorites
              : [...favorites, ...this.userRelated.favorites].filter(this.onlyUnique);
            this.updateFavorites(newValue);
          }
          return resp;
        }
      },

      setCurrentTitle(payload: string) {
        this.updateCurrentTitle(payload);
      },

      async fetchCommentsCount(payload: number[]) {
        const resp = await useNuxtApp().$talkerApi.fetchCommentsCount(payload);
        const parsedResponse = parseData(resp.data, FetchCommentsCountSchema);

        if (resp.status === 200) {
          this.updateCommentCounts(parsedResponse.data);
        }
      },

      async fetchViewsCount(payload: number[]) {
        const resp = await useNuxtApp().$viewsApi.getArticleViewsCount(payload);
        const data = parseData(JSON.parse(resp.data, GetArticleViewsCountSchema));
        if (resp.status === 200) {
          this.updateViewsCounts(data.viewsCounts);
        }
      },

      onlyUnique(value: any, index: number, self: Array<any>) {
        return self.findIndex((item) => item.id === value.id) === index;
      },

      updateFavorites(favorites: Array<IFavoriteItem>) {
        // TODO: Check if reactivity working without vue set
        this.userRelated.favorites = [...favorites, ...this.userRelated.favorites].filter(
          this.onlyUnique,
        );
      },

      updateCurrentTitle(title: string) {
        this.articleTitle = title;
      },

      addFavorite(favorite: IFavoriteItem) {
        this.userRelated.favorites.push(favorite);
      },

      removeFavorite(postId: number) {
        this.userRelated.favorites = this.userRelated.favorites.filter(
          (favorite) => favorite.post_id !== postId,
        );
      },

      updateCommentCounts(commentCounts: { post_ID: number; count: number }[]) {
        commentCounts.forEach((item) => {
          // TODO: Check if reactivity working without vue set
          this.commentsCount[item.post_ID] = item.count;
        });
      },

      clearUserRelatedData() {
        this.userRelated = { favorites: [] };
      },

      updateViewsCounts(viewsCounts: { [propName: string]: number }) {
        // TODO: Check if reactivity working without vue set
        this.viewsCount = { ...this.viewsCount, ...viewsCounts };
      },
    },
  }),
);

import { has } from '~/utils';
import { defineStore } from 'pinia';
import { wrapPiniaStore } from '@devhacker/shared/utils/wrapPiniaStore';

export interface RecipesListWidgetState {
  // похожие рецепты
  similar: {
    // ключ - ид рецепта к которому относятся
    // значение - объект
    //  где idList - список ид похожих рецептов
    //  link - ссылка на страницу с похожими рецептами
    byId: {
      [id: string]: {
        idList: number[];
        link: string;
      };
    };
  };

  // cм. комментарии про similar
  related: {
    byId: {
      [id: string]: {
        idList: number[];
        link: string;
      };
    };
  };

  // хранилище similar и related рецептов
  byId: {
    [id: string]: WidgetRecipe;
  };

  // ид активного рецепта
  currentRecipeId: number | null;
}

export const useRecipesListWidgetStore = wrapPiniaStore(
  defineStore('recipesListWidget', {
    state: (): RecipesListWidgetState => ({
      similar: {
        byId: {},
      },
      related: {
        byId: {},
      },
      byId: {},
      currentRecipeId: null,
    }),

    getters: {
      currentRelatedRecipes(state): WidgetRecipe[] {
        if (state.currentRecipeId && has(state.related.byId, state.currentRecipeId.toString())) {
          return state.related.byId[state.currentRecipeId.toString()].idList
            .map((item) => state.byId?.[item.toString()])
            .filter((item) => !!item);
        }
        return [];
      },

      currentSimilarRecipes(state): WidgetRecipe[] {
        if (state.currentRecipeId && has(state.similar.byId, state.currentRecipeId.toString())) {
          return state.similar.byId[state.currentRecipeId.toString()].idList
            .map((item) => state.byId?.[item.toString()])
            .filter((item) => !!item);
        }
        return [];
      },

      currentSimilarLink(state) {
        if (state.currentRecipeId && has(state.similar.byId, state.currentRecipeId.toString())) {
          return state.similar.byId[state.currentRecipeId.toString()].link;
        }
        return '';
      },

      currentRelatedLink(state) {
        if (state.currentRecipeId && has(state.related.byId, state.currentRecipeId.toString())) {
          return state.related.byId[state.currentRecipeId.toString()].link;
        }
        return '';
      },
    },

    actions: {
      addSimilarRecipes(list: WidgetRecipe[], id: number, link?: string) {
        list.forEach((item) => {
          // TODO: Check if reactivity working without vue set
          this.byId[item.postId] = item;
        });

        // TODO: Check if reactivity working without vue set
        this.similar.byId[id] = {
          idList: list.map((item) => item.postId),
          link: link ?? '',
        };
      },

      addRelatedRecipes(list: WidgetRecipe[], id: number, link?: string) {
        list.forEach((item) => {
          // TODO: Check if reactivity working without vue set
          this.byId[item.postId] = item;
        });

        // TODO: Check if reactivity working without vue set
        this.related.byId[id] = {
          idList: list.map((item) => item.postId),
          link: link ?? '',
        };
      },

      updateCurrentRecipeId(payload: number | null) {
        this.currentRecipeId = payload;
      },
    },
  }),
);

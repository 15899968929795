import Echo from 'laravel-echo';
import TimeAgo from 'javascript-time-ago';
import ru from 'javascript-time-ago/locale/ru';
import events from './websocket-events';

import { getAccessToken } from '~/utils';
import type { TNotification } from '~/store/pusher';
import { PUSHER_API_URL } from '~/constants/config';
// @ts-expect-error no types for socket.io-client
import io from 'socket.io-client';
import AvatarBig from '~/assets/images/default_avatar-big.jpg';

TimeAgo.addLocale(ru);

const timeAgo = new TimeAgo('ru-RU');

let echo: Echo | null = null;

export function openNotificationLink(notification: TNotification) {
  const linkUrl = notification.redirect_link || notification.path;
  const link = document.createElement('a');
  link.href = linkUrl;
  return link.click();
}

export function getDefaultAvatar() {
  return AvatarBig;
}

export function getUserAvatarUrlFromUser(user: IUser) {
  let photoUrl = null;
  try {
    photoUrl = user.avatars[0].url;
  } catch (e) {
    photoUrl = getDefaultAvatar();
  }
  return photoUrl;
}

export function getEcho() {
  if (!echo) {
    echo = new Echo({
      broadcaster: 'socket.io',
      client: io,
      host: PUSHER_API_URL,
      auth: {
        headers: {
          Authorization: `Bearer ${getAccessToken()}`,
        },
      },
    });
  }
  return echo;
}

function getDateObject(dateGMT: string): Date {
  const dateGMTWithCommas = dateGMT.replace(/[-: ]/g, ',');

  const parts = dateGMTWithCommas.split(',').map((part) => +part);
  parts[1] -= 1;

  return new Date(...(parts as []));
}

function getWithLeadingZero(number: number) {
  const INT_NUMBER = +number;
  const RESULT = INT_NUMBER > 9 ? INT_NUMBER : `0${INT_NUMBER}`;
  return `${RESULT}`;
}

export function getHumanReadableDate(dateGMT: string) {
  const d = getDateObject(dateGMT);
  const localOffsetInMinutes = new Date().getTimezoneOffset();
  const localOffsetInHours = localOffsetInMinutes / 60;
  const hours = d.getHours() - localOffsetInHours;

  d.setHours(hours);

  // коммент отправлен меньше чем сутки назад, показываем человекочитаемую дату
  // @ts-ignore
  const timeFromNowToCommentDateInMs = new Date() - d;
  const timeInADayInMs = 24 * 60 * 60 * 1000;
  if (timeFromNowToCommentDateInMs < timeInADayInMs) {
    const formattedDate = timeAgo.format(d);
    if (formattedDate === '1 день назад') {
      return 'вчера';
    }
    return formattedDate;
  }

  // коммент отправлен более суток назад, показываем точную дату
  const dayPart1 = getWithLeadingZero(d.getDate());
  const dayPart2 = getWithLeadingZero(d.getMonth() + 1);
  const dayPart3 = d.getFullYear().toString().substr(-2);
  const day = `${dayPart1}.${dayPart2}.${dayPart3}`;
  const h = d.getHours().toString().length === 1 ? `0${d.getHours()}` : d.getHours();
  const m = d.getMinutes().toString().length === 1 ? `0${d.getMinutes()}` : d.getMinutes();
  const time = `${h}:${m}`;
  return `${day} ${time}`;
}

export function getPhotoStyle(notification: TNotification, onlyPhoto = false) {
  let photoUrl;
  switch (notification.event) {
    case 'comment_reply':
    case 'comment_publish':
      photoUrl = getUserAvatarUrlFromUser(notification.payload.extends.comment.user);
      return onlyPhoto
        ? photoUrl
        : {
            'background-image': `url(${photoUrl})`,
          };

    case 'comment_rated':
      photoUrl = getUserAvatarUrlFromUser(notification.payload.extends.like.user);
      return onlyPhoto
        ? photoUrl
        : {
            'background-image': `url(${photoUrl})`,
          };

    default:
      return {};
  }
}

export function getNotificationContent(notification: TNotification, getObject = false) {
  let displayName;
  let postTitle;
  let text;

  const isRecipe = notification?.payload?.extends?.postType === 'recipe';
  const likeText = isRecipe ? events.comment_rated_like_recipe : events.comment_rated_like;
  const dislikeText = isRecipe ? events.comment_rated_dislike_recipe : events.comment_rated_dislike;

  switch (notification.event) {
    case 'comment_reply':
      displayName = notification.payload.extends.comment.user.display_name;
      postTitle = notification.payload.extends.postTitle;
      if (getObject) {
        return {
          name: displayName,
          content: `${isRecipe ? events.comment_reply_recipe : events.comment_reply} <b>${postTitle}</b>`,
        };
      }

      return `<b>${displayName}</b> ${isRecipe ? events.comment_reply_recipe : events.comment_reply} <b>${postTitle}</b>`;

    case 'comment_rated':
      displayName = notification.payload.extends.like.user.display_name;
      postTitle = notification.payload.extends.postTitle;
      text = notification.payload.content === 'like' ? likeText : dislikeText;
      if (getObject) {
        return {
          name: displayName,
          content: `${text} <b>${postTitle}</b>`,
        };
      }

      return `<b>${displayName}</b> ${text} <b>${postTitle}</b>`;

    case 'comment_publish':
      displayName = notification.payload.extends.commentUserName;
      postTitle = notification.payload.extends.postTitle;
      if (getObject) {
        return {
          name: displayName,
          content: `оставил(а) комментарий к статье <b>${postTitle}</b>`,
        };
      }

      return `<b>${displayName}</b> оставил(а) комментарий к статье <b>${postTitle}</b>`;

    default:
      return '';
  }
}

export function getNotificationLink(notification: TNotification) {
  return notification.redirect_link || notification.path;
}

import { nanoid } from 'nanoid';
import { getEcho } from './utils.client';
import { getSubId } from '~/utils';
import { usePusherStore, type TNotification } from '~/store/pusher';

type pushBadgePayload = {
  image?: string;
  content?: string;
  clickHandler?: Function;
  isError?: boolean;
};

export const connectPrivateChannel = (cb?: Function) => {
  const integerId = getSubId();
  if (integerId) {
    getEcho()
      .channel(`pusher_database_private-channel.${integerId}`)
      .listen('SocketPrivateEvent', (event: TNotification) => {
        usePusherStore().handleWsEvent(event);
        if (cb) {
          cb(event);
        }
      });
  }
};

export const leavePrivateChannel = () => {
  const integerId = getSubId();
  if (integerId) {
    return getEcho().leaveChannel(`pusher_database_private-channel.${integerId}`);
  }
};

export const fetchUnviewedNotificationsCount = () => {
  const integerId = getSubId();
  if (integerId) {
    return usePusherStore().fetchUnviewedNotificationsCount();
  }
};

export const pushBadge = (payload: pushBadgePayload) => {
  const { image, content, clickHandler, isError } = payload;
  return usePusherStore().pushBadge({
    image,
    content,
    clickHandler,
    id: nanoid(),
    isError,
  });
};

export const pusherResetStore = () => usePusherStore().resetStore();

import { type IWidgetsState, useWidgetsStore } from '~/store/widgets';
import { useArticlesStore } from '~/store/articles';
import { useReactionsStore } from '~/store/reactions';
import { useIndexStore } from '~/store';
import { usePageStore } from '~/store/pagesData';
import { useFoldersStore } from '~/store/folders';

export const selectTaxonomyPostsWidgets = (widget: TWidget | WidgetInRecipes): boolean => {
  return Boolean(widget.type === 'taxonomy-posts' && widget?.items && widget.items.length < 3);
};

export const selectPostCollectionWidgets = (widget: TWidget | WidgetInRecipes): boolean => {
  return Boolean(widget.type === 'posts-collection' && Boolean(widget?.items));
};

// Получение списка id статей для виджетов с статьями
// selectWidget функция для определения нужных виджетов
export const getWidgetArticlesIds = (
  state: IWidgetsState,
  selectWidget: (widget: TWidget | WidgetInRecipes) => boolean,
): number[] => {
  const ids: number[] = [];

  const keys = Object.keys(state);
  keys.forEach((key) => {
    state[key as keyof IWidgetsState]?.forEach((widget: TWidget | WidgetInRecipes) => {
      if (selectWidget(widget)) {
        const currentIds = widget?.items?.reduce((acc: number[], article: { id: number }) => {
          return [...acc, article.id];
        }, []);
        ids.push(...currentIds);
      }
    });
  });

  return ids;
};

// Получение списка ид статей текущей страницы.
// currentArticles массив статей на архивной странице
// currentArticle объект статьи на внутренней
export const getCurrentPageArticlesIds = (
  currentArticles: IArticle[],
  currentArticle: ISingleArticle | null,
): number[] => {
  if (currentArticle) {
    return [currentArticle.id];
  }
  return currentArticles.reduce((acc: number[], item) => [...acc, item.id], []);
};

// Загрузка количества комментариев и просмотров.
// Количество просмотров загружается только у пользователей, авторизованных в вордпрессе.
// Для статей текущей страницы и для статей из необязательного параметра ids.
// Перед загрузкой проверяется то, что для данного ид не загружены данные ранее.
export const fetchCountOfCommentsAndViews = (ids: number[] = []) => {
  const articleStore = useArticlesStore();
  const reactionStore = useReactionsStore();
  const indexStore = useIndexStore();
  const pageStore = usePageStore();

  const currentPageArticlesIds: number[] = getCurrentPageArticlesIds(
    pageStore.currentArticles,
    pageStore.currentArticle,
  );

  let uniqueIds = [...new Set([...currentPageArticlesIds, ...ids])];
  uniqueIds = uniqueIds.filter((item) => isNaN(articleStore.commentsCount[String(item)]));

  if (uniqueIds.length) {
    articleStore.fetchCommentsCount(uniqueIds);
    reactionStore.fetchReactions(uniqueIds);

    if (indexStore.isAuthenticatedInWordpress) {
      articleStore.fetchViewsCount(uniqueIds);
    }
  }
};

// Загрузка избранного
// Для статей текущей страницы
// Проверяется были ли данные по статьям текущей страницы загружены ранее
export const fetchFavorites = () => {
  const widgetsState = useWidgetsStore().$state;
  const articleStore = useArticlesStore();
  const pageStore = usePageStore();

  const currentPageArticlesIds: number[] = getCurrentPageArticlesIds(
    pageStore.currentArticles,
    pageStore.currentArticle,
  );
  const taxonomyPostsIds: number[] = getWidgetArticlesIds(widgetsState, selectTaxonomyPostsWidgets);
  const postCollectionIds: number[] = getWidgetArticlesIds(
    widgetsState,
    selectPostCollectionWidgets,
  );

  let uniqueIds = [
    ...new Set([...currentPageArticlesIds, ...taxonomyPostsIds, ...postCollectionIds]),
  ];

  uniqueIds = uniqueIds.filter((id) => {
    const inFavorite = Boolean(
      articleStore.userRelated.favorites.find((favorite: IFavoriteItem) => favorite.post_id === id),
    );
    return !inFavorite;
  });

  if (uniqueIds.length) {
    articleStore.fetchAndSetFavorites({ post_ids: uniqueIds.join() });
  }
};

// Загрузка списка папок избранного
export const fetchFavoritesFolders = async () => {
  const foldersStore = useFoldersStore();
  await foldersStore.getFolders();
};

const task = (): void => {
  const { $logger } = useNuxtApp();
  const pageStore = usePageStore();
  const indexStore = useIndexStore();
  const widgetsStore = useWidgetsStore();

  const log = $logger.withScope('fetch-articles-data.client');

  // Загрузка количества комментариев и просмотров для виджетов "посты таксономии" и для статей на текущей странице.
  const taxonomyPostsIds: number[] = getWidgetArticlesIds(
    widgetsStore.$state,
    selectTaxonomyPostsWidgets,
  );
  const postCollectionIds: number[] = getWidgetArticlesIds(
    widgetsStore.$state,
    selectPostCollectionWidgets,
  );

  log('fetchCountOfCommentsAndViews', { taxonomyPostsIds, postCollectionIds });
  fetchCountOfCommentsAndViews([...taxonomyPostsIds, ...postCollectionIds]);

  // TODO: Check if is same as store subscribe
  pageStore.$onAction((action) => {
    if (action.name === 'updateCurrentArticles') {
      log('updateCurrentArticles inside fetch-articles-data.client.ts');
      fetchCountOfCommentsAndViews();
      fetchFavorites();
    }
  });

  indexStore.$onAction((action) => {
    if (action.name === 'updateWordpressAuthentication') {
      action.after(fetchCountOfCommentsAndViews);
    }
  });

  indexStore.$onAction((action) => {
    if (action.name === 'updateUser') {
      log('updateUser inside fetch-articles-data.client.ts');
      fetchFavorites();
      fetchFavoritesFolders();
    }
  });
};

export default defineNuxtPlugin(() => {
  onNuxtReady(async () => {
    task();
  });
});

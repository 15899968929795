import { useIndexStore } from '~/store';
import { useArticlesStore } from '~/store/articles';
import { useRecipesListWidgetStore } from '~/store/recipesListWidget';
import { type IWidgetsState, useWidgetsStore } from '~/store/widgets';
import { isAnyRecipePage } from '~/utils/router';

// Плагин для загрузки просмотров рецептов, которые отображаются в зонах виджетов. (см задачу CU-1fubaec)
//
// Алгоритм работы:
// 0  старт
// 1. если пользователь авторизован в админке
//    загрузить данные всех кастомных, related и similar виджетов
//    поставить обработчик на добавление рецептов similar и related и разлогин.
//        При добавлении новых виджетов загрузить просмотры для них.
//        При разлогине удалить обработчик и перейти в пункт 0
// 2. иначе
//    добавить обработчик на логин
//        в обработчике удалить обработчик и перейти в пункт 0
const task = () => {
  const articleStore = useArticlesStore();
  const isAuthenticatedInWordpress = useIndexStore().isAuthenticatedInWordpress;

  if (isAuthenticatedInWordpress) {
    const recipesListStore = useRecipesListWidgetStore();
    // 1
    const customWidgetsData = getCustomWidgetsRecipesId();

    const relatedWidgetsData = recipesListStore.currentRelatedRecipes.map((item) => item.postId);
    const similarWidgetsData = recipesListStore.currentSimilarRecipes.map((item) => item.postId);
    const payload = [
      ...new Set([...customWidgetsData, ...relatedWidgetsData, ...similarWidgetsData]),
    ];
    articleStore.fetchViewsCount(payload);

    const handleAddWidgets = (list: WidgetRecipe[]): void => {
      const ids = list.map((item) => item.postId);
      articleStore.fetchViewsCount(ids);
    };

    // Pinia version, TODO: check if works the same as vuex
    const recipeListStoreUnsub = recipesListStore.$onAction((action) => {
      if (action.name === 'addSimilarRecipes' || action.name === 'addRelatedRecipes') {
        handleAddWidgets(action.args[0]);
      }
    });

    const indexStoreUnsub = useIndexStore().$onAction((action) => {
      if (action.name === 'updateWordpressAuthentication') {
        const isLoggedIn = action.args[0];
        if (!isLoggedIn) {
          recipeListStoreUnsub();
          indexStoreUnsub();
          task();
        }
      }
    });

    // Old vuex version
    // const unsubscribe = store.subscribe((mutation) => {
    //   switch (mutation.type) {
    //     case ADD_RELATED_RECIPES:
    //     case ADD_SIMILAR_RECIPES:
    //       handleAddWidgets(mutation.payload);
    //       break;
    //     case UPDATE_WORDPRESS_AUTHENTICATION:
    //       !mutation.payload.newState && unsubscribe();
    //       !mutation.payload.newState && task(store);
    //       break;
    //   }
    // });
  } else {
    // 2
    const indexStoreUnsub = useIndexStore().$onAction((action) => {
      if (action.name === 'updateWordpressAuthentication') {
        const isLoggedIn = action.args[0];
        if (!isLoggedIn) {
          task();
          indexStoreUnsub();
        }
      }
    });
  }
};

export default defineNuxtPlugin((nuxtApp) => {
  if (isAnyRecipePage(nuxtApp._route.name)) {
    onNuxtReady(async () => {
      task();
    });
  }
});

// Получение списка id виджетов с рецептами с типом custom
function getCustomWidgetsRecipesId(): number[] {
  const ids: number[] = [];
  const widgetsState = useWidgetsStore().$state;

  const keys = Object.keys(widgetsState);
  keys.forEach((key) => {
    widgetsState[key as keyof IWidgetsState]?.forEach((widget: TWidget | WidgetInRecipes) => {
      if (widget?.type === 'recipes-list' && 'list' in widget && widget.list === 'custom') {
        const currentIds = widget?.recipes?.reduce((acc: number[], item) => {
          return [...acc, item.postId];
        }, []);
        if (currentIds) {
          ids.push(...currentIds);
        }
      }
    });
  });

  return ids;
}

import { createAxiosLikeFetch } from '@devhacker/shared/libs/fetch/createAxiosLikeFetch';
import { createLoggerInterceptors } from '@devhacker/shared/libs/logger';
import { PUSHER_API_URL } from '~/constants/config';
import { TIMEOUT } from '~/constants';
import { authErrorHandler } from '@/utils/errorHandlerAuth';
import { authRequestInterceptor } from '~/utils/interceptorsRequestAuth';

export const createPusherApi = ({ $logger, route }: any) => {
  const loggerInterceptors = createLoggerInterceptors($logger, 'pusher');
  const pusherFetch = createAxiosLikeFetch({
    baseURL: PUSHER_API_URL,
    timeout: TIMEOUT,
    onRequest: [loggerInterceptors.onRequest, (config) => authRequestInterceptor(config)],
    onRequestError: [
      loggerInterceptors.onRequestError,
      (config) => authErrorHandler(config, route),
    ],
    onResponse: loggerInterceptors.onResponse,
    onResponseError: [
      loggerInterceptors.onResponseError,
      (config) => authErrorHandler(config, route),
    ],
  });

  return {
    fetchNotifications: (_accessToken: string, integerId: number, params: any) => {
      const url = `/api/v1/kolokolchick/user/${integerId}`;
      return pusherFetch(url, { params });
    },

    resetNotifications: (integerId: number) => {
      const url = `/api/v1/kolokolchick/user/${integerId}/notifications/mass-viewing`;
      return pusherFetch(url, { method: 'patch' });
    },
  };
};

import io from 'socket.io-client';

type CustomWindow = {
  io: any;
};

declare const window: CustomWindow;

export default defineNuxtPlugin(() => {
  window.io = io;
});

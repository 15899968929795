<template>
  <div class="health-service">
    <div class="health-service__header">
      <a
        href="/health/"
        class="health-service__bread-crumb health-service__bread-crumb--hover"
      >
        Здоровье /
      </a>
      <span class="health-service__bread-crumb"> Сервисы </span>
      <h1 class="health-service__title">Сервисы</h1>
    </div>
    <div
      class="health-service__services"
      :style="cssVariables"
    >
      <div
        v-for="(service, index) in services"
        :key="index"
        class="service"
      >
        <ArticleCardSimple
          :article="service"
          :target="'_blank'"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import ArticleCardSimple from '@/components/lh-ui/ArticleCard/ArticleCardSimple/ArticleCardSimple.vue';
  import { getMetaForHealthStaticPage } from '~/utils/healthUtils';

  export default defineNuxtComponent({
    name: 'HealthServices',

    components: {
      ArticleCardSimple,
    },

    setup() {
      definePageMeta({ layout: 'health' });
    },

    layout: 'health',

    data() {
      return {
        services: [
          {
            title: 'Калькулятор сна: во сколько лечь, чтобы встать бодрым',
            url: '/special/sleepycalc/',
            image:
              'https://cdn.lifehacker.ru/wp-content/uploads/2018/11/2500x1250_1542804782-768x384.jpg',
          },
          {
            title: 'Алкогольный калькулятор: проверьте, насколько вы пьяны и когда придёте в себя',
            url: '/special/alcocalculator/',
            image:
              'https://cdn.lifehacker.ru/wp-content/uploads/2019/05/share-hub-v3_1607677729-768x384.jpg',
          },
          {
            title: 'Калькулятор: как рассчитать норму калорий',
            url: '/special/rasschitat-normu-kalorij/',
            image:
              'https://cdn.lifehacker.ru/wp-content/uploads/2020/06/Frame-8131_1591647384-768x384.jpg',
          },
          {
            title: 'Калькулятор: как сделать антисептик дома и не ошибиться в ингредиентах',
            url: '/special/kalkuljator-antiseptika/',
            image:
              'https://cdn.lifehacker.ru/wp-content/uploads/2020/05/Frame-8127_1589287929-768x384.jpg',
          },
          {
            title: 'Спокойное место — ваш укромный уголок для релаксации',
            url: '/special/calm/',
            image:
              'https://cdn.lifehacker.ru/wp-content/uploads/2016/07/tixoe-mesto_1467890866-640x320.jpg',
          },
        ],
      };
    },

    head() {
      const title = 'Сервисы — Лайфхакер Здоровье';
      const description = {
        hid: 'description',
        property: 'description',
        content:
          'Проект «Лайфхакер Здоровье» — это проверенные калькуляторы и другие онлайн-инструменты для оценки самочувствия и улучшения качества жизни. В создании сервисов мы опираемся на научные факты, современные международные рекомендации и базы данных.',
      };

      return getMetaForHealthStaticPage('health/services', title, description);
    },

    computed: {
      cssVariables(): Record<string, string> {
        return {
          '--right-border-card': this.services.length % 2 === 0 ? '0px' : '1px',
        };
      },
    },
  });
</script>

<style lang="scss" scoped>
  .health-service {
    background-color: #fff;
    &__header {
      padding: 18px 16px 0;

      @include tablet {
        padding: 18px 24px 0;
      }
    }

    &__bread-crumb {
      text-decoration: none;
      font-size: 14px;
      line-height: 20px;
      font-weight: 400;
      font-family: $mainFont;
      color: #5c5c5c;

      &--hover {
        @include hover {
          color: var(--main-color);
        }
      }
    }

    &__title {
      font-size: 28px;
      line-height: 36px;

      margin-top: 24px;
      font-family: $secondFont;

      @include tablet {
        @include H1HeadlineText;
      }
    }

    &__services {
      margin-top: 20px;
      .service {
        border-top: 1px solid #e7e7e7;

        @include tablet {
          border: 1px solid #e7e7e7;
          min-width: 50%;
          max-width: 50%;
          &:nth-child(odd) {
            border-left: none;
          }
          &:nth-child(even) {
            border-left: none;
            border-right: none;
          }
          &:nth-child(n + 3) {
            border-top: none;
          }
          &:last-child {
            border-right: var(--right-border-card) solid #e7e7e7;
          }
        }
      }

      @include tablet {
        display: flex;
        flex-wrap: wrap;
      }

      &:deep() {
        .article-card-simple {
          border: none;
          width: 100%;
          height: 100%;

          &__title {
            font-size: 22px;
            line-height: 30px;
            @include lineClampEllipsis(3);
            @include tablet {
              font-size: 24px;
              line-height: 32px;
            }
          }

          .image-wrapp {
            @include big-mobile {
              height: 144px;
              min-width: 100%;
            }
            @include tablet {
              height: 184px;
            }
          }
        }
      }
    }
  }
</style>

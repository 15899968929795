import payload_plugin_ljKcX9H4Cq from "/home/gitlab-runner/builds/-zGqAZ5s/0/devhacker/lh-website-client/node_modules/.pnpm/@pinia+nuxt@0.9.0_magicast@0.3.5_pinia@2.3.0_typescript@5.7.3_vue@3.5.13_typescript@5.7.3___rollup@4.30.1/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_FqEF8eTZDc from "/home/gitlab-runner/builds/-zGqAZ5s/0/devhacker/lh-website-client/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.13.13_db0@0.1.4_eslint@9.18.0_jiti@2.4.2__io_g4zrfzqw4g7t4qvnvgxmvvnwjm/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_RKHQbIs2Ml from "/home/gitlab-runner/builds/-zGqAZ5s/0/devhacker/lh-website-client/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.13.13_db0@0.1.4_eslint@9.18.0_jiti@2.4.2__io_g4zrfzqw4g7t4qvnvgxmvvnwjm/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_6PY5qBR8Hd from "/home/gitlab-runner/builds/-zGqAZ5s/0/devhacker/lh-website-client/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.13.13_db0@0.1.4_eslint@9.18.0_jiti@2.4.2__io_g4zrfzqw4g7t4qvnvgxmvvnwjm/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_b55ivxOTpG from "/home/gitlab-runner/builds/-zGqAZ5s/0/devhacker/lh-website-client/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.13.13_db0@0.1.4_eslint@9.18.0_jiti@2.4.2__io_g4zrfzqw4g7t4qvnvgxmvvnwjm/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_1IFTAWvOYt from "/home/gitlab-runner/builds/-zGqAZ5s/0/devhacker/lh-website-client/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.13.13_db0@0.1.4_eslint@9.18.0_jiti@2.4.2__io_g4zrfzqw4g7t4qvnvgxmvvnwjm/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_uY3Sm2XJXo from "/home/gitlab-runner/builds/-zGqAZ5s/0/devhacker/lh-website-client/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.13.13_db0@0.1.4_eslint@9.18.0_jiti@2.4.2__io_g4zrfzqw4g7t4qvnvgxmvvnwjm/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_FJYt60Cl6e from "/home/gitlab-runner/builds/-zGqAZ5s/0/devhacker/lh-website-client/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.13.13_db0@0.1.4_eslint@9.18.0_jiti@2.4.2__io_g4zrfzqw4g7t4qvnvgxmvvnwjm/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_50BRjPjiLj from "/home/gitlab-runner/builds/-zGqAZ5s/0/devhacker/lh-website-client/node_modules/.pnpm/@pinia+nuxt@0.9.0_magicast@0.3.5_pinia@2.3.0_typescript@5.7.3_vue@3.5.13_typescript@5.7.3___rollup@4.30.1/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/home/gitlab-runner/builds/-zGqAZ5s/0/devhacker/lh-website-client/.nuxt/components.plugin.mjs";
import prefetch_client_TL8N83GRtq from "/home/gitlab-runner/builds/-zGqAZ5s/0/devhacker/lh-website-client/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.13.13_db0@0.1.4_eslint@9.18.0_jiti@2.4.2__io_g4zrfzqw4g7t4qvnvgxmvvnwjm/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import nuxt2_plugin_client_1ilvK0imTR from "/home/gitlab-runner/builds/-zGqAZ5s/0/devhacker/lh-website-client/node_modules/.pnpm/@devhacker+shared@0.6.1-nuxt3.40_e6we3cxkics2rrf3gurklnxave/node_modules/@devhacker/shared/libs/logger/nuxt2-plugin.client.ts";
import nuxt_pinia_plugin_ABY9ZcqZOJ from "/home/gitlab-runner/builds/-zGqAZ5s/0/devhacker/lh-website-client/node_modules/.pnpm/@devhacker+shared@0.6.1-nuxt3.40_e6we3cxkics2rrf3gurklnxave/node_modules/@devhacker/shared/libs/logger/nuxt-pinia-plugin.ts";
import isAmp_AGoOr6WOLu from "/home/gitlab-runner/builds/-zGqAZ5s/0/devhacker/lh-website-client/plugins/isAmp.ts";
import auth_client_4lHQFpoKNF from "/home/gitlab-runner/builds/-zGqAZ5s/0/devhacker/lh-website-client/plugins/auth.client.ts";
import socket_client_qBDI6bzE0L from "/home/gitlab-runner/builds/-zGqAZ5s/0/devhacker/lh-website-client/plugins/socket.client.ts";
import api_GFfDXud5Cr from "/home/gitlab-runner/builds/-zGqAZ5s/0/devhacker/lh-website-client/plugins/api.ts";
import head_content_from_server_client_Mnrlx1wyab from "/home/gitlab-runner/builds/-zGqAZ5s/0/devhacker/lh-website-client/plugins/head-content-from-server.client.ts";
import init_client_lHDJZN4lq2 from "/home/gitlab-runner/builds/-zGqAZ5s/0/devhacker/lh-website-client/plugins/init.client.ts";
import push_notifications_client_9sl3USyx58 from "/home/gitlab-runner/builds/-zGqAZ5s/0/devhacker/lh-website-client/plugins/push-notifications.client.ts";
import calcCssVariableVw_client_J4uoB1fHNm from "/home/gitlab-runner/builds/-zGqAZ5s/0/devhacker/lh-website-client/plugins/calcCssVariableVw.client.ts";
import fetch_articles_data_client_Jpf0B8eAia from "/home/gitlab-runner/builds/-zGqAZ5s/0/devhacker/lh-website-client/plugins/fetch-articles-data.client.ts";
import data_layer_client_rNqARIqKVx from "/home/gitlab-runner/builds/-zGqAZ5s/0/devhacker/lh-website-client/plugins/data-layer.client.ts";
import ga_data_client_F2kohkFa2S from "/home/gitlab-runner/builds/-zGqAZ5s/0/devhacker/lh-website-client/plugins/ga.data.client.ts";
import topMail_CuWewC9XQa from "/home/gitlab-runner/builds/-zGqAZ5s/0/devhacker/lh-website-client/plugins/topMail.ts";
import usingMouse_client_NkPlNgbo4V from "/home/gitlab-runner/builds/-zGqAZ5s/0/devhacker/lh-website-client/plugins/usingMouse.client.ts";
import fetchRecipesWidgetViews_client_LCTyNFzoxr from "/home/gitlab-runner/builds/-zGqAZ5s/0/devhacker/lh-website-client/plugins/fetchRecipesWidgetViews.client.ts";
import quickPolls_client_AkFiLHGmFi from "/home/gitlab-runner/builds/-zGqAZ5s/0/devhacker/lh-website-client/plugins/quickPolls.client.ts";
import touchEvents_fM5I0uoijX from "/home/gitlab-runner/builds/-zGqAZ5s/0/devhacker/lh-website-client/plugins/touchEvents.ts";
import snowplow_client_bLV8iXtEU5 from "/home/gitlab-runner/builds/-zGqAZ5s/0/devhacker/lh-website-client/plugins/snowplow.client.ts";
import yandexMetrika_client_89SdT1XyDr from "/home/gitlab-runner/builds/-zGqAZ5s/0/devhacker/lh-website-client/plugins/yandexMetrika.client.ts";
import yandexMetrika_events_client_ThiZBjLPT4 from "/home/gitlab-runner/builds/-zGqAZ5s/0/devhacker/lh-website-client/plugins/yandexMetrika.events.client.ts";
export default [
  payload_plugin_ljKcX9H4Cq,
  revive_payload_client_FqEF8eTZDc,
  unhead_RKHQbIs2Ml,
  router_6PY5qBR8Hd,
  payload_client_b55ivxOTpG,
  navigation_repaint_client_1IFTAWvOYt,
  check_outdated_build_client_uY3Sm2XJXo,
  chunk_reload_client_FJYt60Cl6e,
  plugin_vue3_50BRjPjiLj,
  components_plugin_KR1HBZs4kY,
  prefetch_client_TL8N83GRtq,
  nuxt2_plugin_client_1ilvK0imTR,
  nuxt_pinia_plugin_ABY9ZcqZOJ,
  isAmp_AGoOr6WOLu,
  auth_client_4lHQFpoKNF,
  socket_client_qBDI6bzE0L,
  api_GFfDXud5Cr,
  head_content_from_server_client_Mnrlx1wyab,
  init_client_lHDJZN4lq2,
  push_notifications_client_9sl3USyx58,
  calcCssVariableVw_client_J4uoB1fHNm,
  fetch_articles_data_client_Jpf0B8eAia,
  data_layer_client_rNqARIqKVx,
  ga_data_client_F2kohkFa2S,
  topMail_CuWewC9XQa,
  usingMouse_client_NkPlNgbo4V,
  fetchRecipesWidgetViews_client_LCTyNFzoxr,
  quickPolls_client_AkFiLHGmFi,
  touchEvents_fM5I0uoijX,
  snowplow_client_bLV8iXtEU5,
  yandexMetrika_client_89SdT1XyDr,
  yandexMetrika_events_client_ThiZBjLPT4
]
import { stringify } from 'qs';
import { TIMEOUT } from '~/constants';
import { WORDPRESS_API_URL } from '~/constants/config';
import { getApiEndpointDependingOnRoute, getParamsByRoute, getUrl } from '~/utils/apiUtils';
import type { IAdsFormPayload } from '~/types';
import { formatToOldApiInterceptor } from '~/utils/content/formatToOldApi';

import {
  createAxiosLikeFetch,
  type AxiosLikeFetchResponse,
} from '@devhacker/shared/libs/fetch/createAxiosLikeFetch';
import { createLoggerInterceptors } from '@devhacker/shared/libs/logger';

export const URL_PREFIX_IRON_MAIDEN = 'api/lh-iron-maiden/v1';

const PATHS = {
  HOME_POSTS: '/home-posts',
  AUTHOR: '/author',
  TOPICS: '/category',
  TAGS: '/tag',
  STREAMS: '/streams',
  TOP: '/top',
  POSTS_BY_DATE: '/posts-by-date',
  SINGLE_POST: '/single-post',
  PREVIEW: '/preview',
  SEO: '/seo',
  SCRIPTS: '/scripts',
  MENUS: '/menus',
  SIDEBARS: '/sidebars',
  LOGGED_IN: '/logged_in',
  CONTACT_FORM: '/ads-form',
  SEARCH: '/aggregate-search',
} as const;

export interface IronMaidenApi {
  fetchArticles: (
    route: any,
    page: number,
    limit?: number,
  ) => Promise<AxiosLikeFetchResponse<object>>;
  fetchSinglePost: (slug: string) => Promise<AxiosLikeFetchResponse<object>>;
  fetchPreviewSinglePost: (
    articleId: string,
    // previewType: 'admin-post-preview' | 'public-post-preview',
    previewType: string,
    previewTypeHash: string,
  ) => Promise<AxiosLikeFetchResponse<object>>;
  fetchLastNArticles: (limit: number) => Promise<AxiosLikeFetchResponse<object>>;
  sendContactForm: (payload: IAdsFormPayload) => Promise<AxiosLikeFetchResponse<object>>;
  search: (payload: string[]) => Promise<AxiosLikeFetchResponse<{ items: Array<any> }>>;
}

export const createIronMaidenApi = ({ $logger, version }: any): IronMaidenApi => {
  const ironMaidenFetch = createAxiosLikeFetch({
    baseURL: `${WORDPRESS_API_URL}/${URL_PREFIX_IRON_MAIDEN}`,
    timeout: TIMEOUT,
    headers: { 'Content-Type': 'application/json' },
    ...createLoggerInterceptors($logger, 'iron-maiden'),
  });

  const defaultParams = version.length ? { version } : {};

  const getParams = (params: any = {}) => ({ ...defaultParams, ...params });

  return {
    fetchArticles: async (route, page, limit = 30) => {
      const url = getApiEndpointDependingOnRoute(route, PATHS);

      const params = getParams({
        page,
        limit,
        ...getParamsByRoute(route),
      });

      const response = await ironMaidenFetch(url, { params });
      return formatToOldApiInterceptor(response);
    },

    fetchSinglePost: async (slug) => {
      const url = getUrl(PATHS.SINGLE_POST, slug);

      const response = await ironMaidenFetch(url);
      return formatToOldApiInterceptor(response, true);
    },

    fetchPreviewSinglePost: async (
      articleId,
      previewType = 'admin-post-preview',
      previewTypeHash,
    ) => {
      const url = getUrl(PATHS.PREVIEW, String(articleId));
      const response = await ironMaidenFetch(url, {
        headers: {
          ...useRequestHeaders(),
        },
        params: getParams({
          preview_type: previewType,
          ...(previewTypeHash ? { preview_type_hash: previewTypeHash } : {}),
        }),
      });
      return formatToOldApiInterceptor(response, true);
    },

    fetchLastNArticles: async (limit) => {
      const url = getUrl(PATHS.HOME_POSTS);
      const response = await ironMaidenFetch(url, {
        params: {
          page: 1,
          limit,
        },
      });
      return formatToOldApiInterceptor(response);
    },

    sendContactForm: (payload: IAdsFormPayload) => {
      const url = getUrl(PATHS.CONTACT_FORM);

      return ironMaidenFetch(url, {
        method: 'post',
        body: stringify(payload),
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      });
    },

    search: async (payload: string[]) => {
      const url = getUrl(PATHS.SEARCH);
      const response = await ironMaidenFetch(url, {
        method: 'post',
        body: { 'slug-list': payload },
        headers: { 'Content-Type': 'application/json' },
      });
      return formatToOldApiInterceptor(response);
    },
  };
};

import { useRoute } from '#imports';

import { getVersionFromQuery } from '~/utils';
import { createWordpressApi } from '~/api/wordpress';
import { createIronMaidenApi } from '~/api/ironMaiden';
import { createViewsApi } from '~/api/views';
import { createTalkerApi } from '~/api/talker';
import { createPushApi } from '~/api/push';
import { createSearchApi } from '~/api/search';
import { createSpecialApi } from '~/api/special';
import { createQuizApi } from '~/api/quiz';
import { createMascotApi } from '~/api/mascot';
import { createHealthApi } from '~/api/health';
import { createGatewayApi } from '~/api/api-gateway';
import { createPusherApi } from '~/api/pusher';
import { createAuthApi } from '~/api/auth';
import { createReactionsApi } from '~/api/reactions';
import { useWidgetsStore } from '~/store/widgets';

export default defineNuxtPlugin((nuxtApp) => {
  const { $store, $logger, $redisClient } = nuxtApp;

  const route = useRoute();
  const version = getVersionFromQuery(route.query);
  const widgetsStore = useWidgetsStore();

  nuxtApp.provide('wordpressApi', createWordpressApi({ $logger, version }));
  nuxtApp.provide('ironMaidenApi', createIronMaidenApi({ $logger, version }));
  nuxtApp.provide('viewsApi', createViewsApi({ $fetch, $logger }));
  nuxtApp.provide('talkerApi', createTalkerApi({ $logger }));
  nuxtApp.provide('pushApi', createPushApi({ $logger }));
  nuxtApp.provide('searchApi', createSearchApi({ $logger }));
  nuxtApp.provide('specialApi', createSpecialApi({ $logger }));
  nuxtApp.provide('quizApi', createQuizApi({ $logger }));
  nuxtApp.provide('mascotApi', createMascotApi({ $logger }));
  nuxtApp.provide('healthApi', createHealthApi({ $logger, $store }));
  nuxtApp.provide('apiGateway', createGatewayApi({ $logger, widgetsStore, $redisClient }));
  nuxtApp.provide('pusherApi', createPusherApi({ $logger, $store, route }));
  nuxtApp.provide('authApi', createAuthApi({ $logger, $store, route }));
  nuxtApp.provide('reactionsApi', createReactionsApi({ $logger, $store, route }));
});

import { isHealthPath, isAnyRecipePage, isPreviewPage } from '~/utils/router';
import {
  YA_METRIKA_ID,
  YA_METRIKA_ID2,
  YA_METRIKA_ID_RECIPE,
  YA_METRIKA_ID_HEALTH,
} from '@/constants/config';
import { usePageStore } from '~/store/pagesData';
// import { type Category } from "~/api/health/category";

export default defineNuxtPlugin((nuxtApp) => {
  const notificationDeniedScript = `
  Notification.requestPermission().then(function(result) {
    if (result === "denied") {ym(1915831, 'reachGoal', 'notification-denied');}
  })
`;

  const notificationDefaultScript = `
  Notification.requestPermission().then(function(result) {
    if (result === "default") {ym(1915831, 'reachGoal', 'notification-default');}
  })
`;

  const notificationGrantedScript = `
  Notification.requestPermission().then(function(result) {
    if (result === "granted") {ym(1915831, 'reachGoal', 'notification-granted');}
  })
`;

  const topMailRuNoScript = `<div><img src="https://top-fwz1.mail.ru/counter?id=3339350;js=na" style="position:absolute;left:-9999px;" alt="Top.Mail.Ru" /></div>`;

  // TODO NUXT3: log
  // const log = $logger.withScope('🍇 yandexMetrika');
  const inited: number[] = [];

  initRouteSpecific();

  function initRouteSpecific() {
    const route = useRoute();
    const currentArticle = usePageStore().currentArticle;
    // TODO NUXT3: category: Category, after __previous folder is  removed
    const healthCategory = currentArticle?.categories.filter((category: any) => {
      return category.slug === 'health' || category.slug === 'sport';
    });
    if (healthCategory && healthCategory.length) {
      const healthInited = inited.includes(YA_METRIKA_ID_HEALTH);

      if (!healthInited) {
        initYaMetrika(YA_METRIKA_ID_HEALTH);
      }
    }

    // превью не считаем
    if (isPreviewPage(route.name)) {
      return;
    }

    if (!inited.includes(YA_METRIKA_ID)) {
      initYaMetrika(YA_METRIKA_ID);
    }

    if (!inited.includes(YA_METRIKA_ID2)) {
      initYaMetrika(YA_METRIKA_ID2);
    }

    const recipeInited = inited.includes(YA_METRIKA_ID_RECIPE);
    if (!recipeInited && isAnyRecipePage(route.name)) {
      initYaMetrika(YA_METRIKA_ID_RECIPE);
    }

    const healthInited = inited.includes(YA_METRIKA_ID_HEALTH);
    if (!healthInited && isHealthPath(route.path)) {
      initYaMetrika(YA_METRIKA_ID_HEALTH);
    }
  }

  function initYaMetrika(id: number) {
    // TODO NUXT3: log
    // log('init', id);
    try {
      window.ym(id, 'init', {
        clickmap: true,
        trackLinks: true,
        accurateTrackBounce: true,
        webvisor: true,
      });
      inited.push(id);
    } catch (error) {
      console.warn('Yandex Metrika init error', error);
    }
  }

  const noscript = document.createElement('noscript');
  noscript.innerHTML = topMailRuNoScript;
  document.body.appendChild(noscript);

  useHead({
    script: [
      // https://app.clickup.com/t/86c218rgb
      {
        key: 'notification-denied',
        innerHTML: notificationDeniedScript,
        type: 'text/javascript',
        tagPosition: 'bodyOpen',
      },
      {
        key: 'notification-default',
        innerHTML: notificationDefaultScript,
        type: 'text/javascript',
        tagPosition: 'bodyOpen',
      },
      {
        key: 'notification-granted',
        innerHTML: notificationGrantedScript,
        type: 'text/javascript',
        tagPosition: 'bodyOpen',
      },
    ],
  });
});

import type { Settings } from '~/libs/structuredContentSanitizer';

const replaceableTags = {
  img: 'amp-image-wrapper',
  iframe: 'amp-iframe-wrapper',

  core__embed: 'core__embed-amp',
  core__gallery: 'core__gallery-amp',
  'lh__read-also': 'lh__read-also-amp',
  'lh__promo-block': 'lh__promo-block-amp',
  lh__carousel: 'lh__carousel-amp',
  lh__spoiler: 'lh__spoiler-amp',
  core__buttons: 'core__buttons-amp',
  lh__greybox: 'lh__greybox-amp',
  lh__whitebox: 'lh__whitebox-amp',
  'lh-reference': 'lh-reference-amp',
  'lh__qna-block': 'lh__qna-block-amp',
  lh__appbox: 'lh__appbox-amp',
  'lh__email-block': 'lh__email-block-amp',
  'lh__speech-bquote': 'lh__speech-bquote-amp',
  'lh__author-bquote': 'lh__author-bquote-amp',
  'lh__cut-bquote': 'lh__cut-bquote-amp',
  'lh__simply-bquote': 'lh__simply-bquote-amp',
  'lh__review-bquote': 'lh__review-bquote-amp',
  'lh__characters-list': 'lh__characters-list-amp',
  'core__table-of-contents': 'core__table-of-contents-amp',
  'lh__goods-gallery': 'lh__goods-gallery-amp',
  'lh__nds-calculator': 'lh__nds-calculator-amp',
  'lh__bmi-calculator': 'lh__bmi-calculator-amp',
  'lh__age-calculator': 'lh__age-calculator-amp',
  'lh__ovulation-calculator': 'lh__ovulation-calculator-amp',
  'lh__ads-page-contacts-list': 'lh__ads-page-contacts-list-amp',
  'lh__ads-page-useful-info': 'lh__ads-page-useful-info-amp',
  'lh__ads-page-contact-us-form': 'lh__ads-page-contact-us-form-amp',
  lh__goods: 'lh__goods-amp',
};

const patternsForIframes = {
  // youtube: /youtube/gm,
  // viqeo: /^youtube/gm,
};

const replaceableIframes = {
  // youtube: 'amp-youtube',
  // viqeo: 'amp-viqeo-player',
};

const patternsForSrcId = {
  // youtube: /(youtu.be\/|youtube.com\/|youtube-nocookie.com\/(watch\?(.*&)?v=|(embed|v)\/))([^?&"'>]+)/,
  // youtube: /(youtube|youtube-nocookie|youtu).*?(?:[\/]|v=)([a-zA-Z0-9-_]{11})/,
};
// Список разрешенных в амп версии html тегов
const allowedHTMLTags: string[] = [
  // ...Object.values(replaceableIframes),
  ...Object.values(replaceableTags),
  'text',
  '#text',
  'script',
  '#cdata-section',
  'core__columns',
  'core__column',
  'core__group',
  'amp-viqeo-player',
  'lh__quiz',
  'lh__poll',
  'lh-gallery',
  'lh-appbox',
  'lh-appbox-fallback',
  'lh-appbox-error',
  'lh-spoiler',
  'lh-carousel',
  'lh-email-block',
  'lh-qna-block',
  'core__html',
  'html-fallback-amp',
  'address',
  'article',
  'aside',
  'footer',
  'header',
  'h1',
  'h2',
  'h3',
  'h4',
  'h5',
  'h6',
  'hgroup',
  'main',
  'nav',
  'section',
  'blockquote',
  'dd',
  'div',
  'dl',
  'dt',
  'figcaption',
  'figure',
  'hr',
  'li',
  'ol',
  'p',
  'pre',
  'ul',
  'a',
  'abbr',
  'b',
  'bdi',
  'bdo',
  'br',
  'cite',
  'code',
  'data',
  'dfn',
  'em',
  'i',
  'kbd',
  'mark',
  'q',
  'rb',
  'rp',
  'rt',
  'rtc',
  'ruby',
  's',
  'samp',
  'small',
  'span',
  'strong',
  'sub',
  'sup',
  'time',
  'u',
  'var',
  'wbr',
  'caption',
  'col',
  'colgroup',
  'table',
  'tbody',
  'td',
  'tfoot',
  'th',
  'thead',
  'tr',
  'del',
];

const globalAttributes = [
  'accesskey',
  'class',
  'contenteditable',
  /^data-.+/,
  'dir',
  'draggable',
  'hidden',
  'id',
  'lang',
  'spellcheck',
  'tabindex',
  'title',
  'translate',

  // gutenberg attrs
  'title',
  'items',
  'emoji',
  'url',
  'border_color',
  'button_link',
  'button_label',
  'is_ad',
  'is_promo',
  'logo',
  'logo_link',
  'title_close',
  'developer',
  'developer_url',
  'platform',
  'name',
  'price',
  'icon_url',
  'fallback',
  'data-template',
  'button',
  'layout',
  'placeholder',
  'subtitle',
  'success',
  'details',
  'image_url',
  'text',
  'providerNameSlug',
  'originUrl',
  'vid',
  'oembed',
  'ownerId',
  'hash',
  'isWhiteBox',
  'type',
  'descriptor',
  'content',
  'src',
  'width',
  'height',
  'data-type',
  'headings',
  'posts',
];

const globalAttributesValues = {
  class: {
    pattern: /^((?!amp).)*$/im,
  },
  id: {
    pattern: /^((?!amp).)*$/im,
  },
};

const tagAttributesSpecification: Settings['tagAttributesSpecification'] = {
  p: {
    global: true,
    attributes: {
      style: {
        // в стилях нет слова important, который запрещен в AMP
        pattern: /^((?!important).)*$/,
      },
    },
  },
  span: {
    global: true,
    attributes: {
      style: {
        // в стилях нет слова important, который запрещен в AMP
        pattern: /^((?!important).)*$/,
      },
    },
  },
  section: {
    global: true,
    attributes: {
      style: {
        // в стилях нет слова important, который запрещен в AMP
        pattern: /^((?!important).)*$/,
      },
    },
  },
  a: {
    global: true,
    attributes: {
      href: {
        // see https://github.com/ampproject/amphtml/blob/main/validator/validator-main.protoascii#L1333
        pattern:
          /^(http|#|\/|https|mailto|maps|geo|chrome|fb-me|fb-messenger|feed|tel|tg|twitter|viber|whatsapp).+/,
      },
      rel: true,
      target: {
        allowedValues: ['_blank'],
      },
      name: true,
      style: {
        // в стилях нет слова important, который запрещен в AMP
        pattern: /^((?!important).)*$/,
      },
    },
  },
  'amp-image-wrapper': {
    global: true,
    attributes: {
      src: {
        mandatory: true,
        pattern: /^https:\/\/.+/,
      },
      width: true,
      height: true,
      srcset: true,
      sizes: true,
      alt: true,
    },
  },
  'amp-iframe-wrapper': {
    global: true,
    attributes: {
      src: {
        mandatory: true,
        pattern: /^https:\/\/.+/,
      },
      layout: true,
      width: true,
      height: true,
      alt: true,
    },
  },
  'amp-youtube': {
    global: true,
    attributes: {
      'data-videoid': true,
      width: true,
      height: true,
      layout: true,
      src: false,
    },
  },
  'amp-viqeo-player': {
    global: true,
    attributes: {
      'data-videoid': true,
      'data-profileid': true,
      width: true,
      height: true,
      layout: true,
    },
  },
  'lh-gallery': {
    global: false,
    attributes: {
      border: {
        mandatory: false,
      },
      images: {
        mandatory: true,
      },
    },
  },
  'lh-appbox': {
    global: false,
    attributes: {
      appbox: {
        mandatory: true,
      },
      originalShots: {
        mandatory: true,
      },
      shotsDirPath: {
        mandatory: true,
      },
      isEditor: {
        mandatory: true,
      },
    },
  },
  'lh-appbox-fallback': {
    global: false,
    attributes: {
      store: {
        mandatory: true,
      },
      app_id: {
        mandatory: true,
      },
      app_url: {
        mandatory: true,
      },
      show_screenshots: {
        mandatory: true,
      },
      old_price: true,
    },
  },
  'lh-appbox-error': {
    global: false,
  },
  'lh-spoiler': {
    global: false,
    attributes: {
      title: {
        mandatory: true,
      },
      titleOpen: {
        mandatory: true,
      },
      content: {
        mandatory: true,
      },
    },
  },
  'lh-carousel': {
    global: false,
    attributes: {
      title: {
        mandatory: true,
      },
      posts: {
        mandatory: true,
      },
    },
  },
  'lh-email-block': {
    global: false,
    attributes: {
      action: {
        mandatory: true,
      },
      type: {
        mandatory: true,
      },
    },
  },
  'lh-qna-block': {
    global: false,
    attributes: {
      title: {
        mandatory: true,
      },
      subtitle: {
        mandatory: true,
      },
      submit: {
        mandatory: true,
      },
      successMessage: {
        mandatory: true,
      },
      placeholder: {
        mandatory: true,
      },
    },
  },
  'lh__goods-gallery-amp': {
    global: false,
    attributes: {
      items: {
        mandatory: true,
      },
    },
  },

  'lh__goods-amp': {
    global: false,
    attributes: {
      link: {
        mandatory: true,
      },
      image: {
        mandatory: true,
      },
      title: {
        mandatory: false,
      },
      price: {
        mandatory: false,
      },
      button_text: {
        mandatory: false,
      },
    },
  },
};

export const settings: Settings = {
  patternsForIframes,
  patternsForSrcId,
  replaceableIframes,
  replaceableTags,
  allowedHTMLTags,
  globalAttributes,
  globalAttributesValues,
  tagAttributesSpecification,
};

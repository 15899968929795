<template>
  <div
    v-if="errorCode === 503"
    class="the-recipe-amp-error"
  >
    <AmpError />
  </div>

  <div
    v-else-if="recipe"
    class="the-recipe-amp"
  >
    <component
      :is="'amp-geo'"
      layout="nodisplay"
    />

    <main class="the-recipe-amp__main">
      <RecipeCardAmp
        :id="recipe.id"
        class="the-recipe-amp__card"
        :title="recipe.title"
        :author="recipe.author"
        :expert="recipe.expert"
        :cooking-time="recipe.totalTime"
        :cover="cover"
      />

      <section class="the-recipe-amp__article">
        <section class="the-recipe-amp__description">
          <TheContent
            v-for="item of sanitizedDescription"
            :key="item.id"
            :elem="item"
          />
        </section>

        <PrintButton
          class="the-recipe-amp__print-button"
          :link="toPrintUrl"
        />

        <BaseList
          class="the-recipe-amp__list"
          title="Время приготовления"
          :items="cookingTimeItems"
        />

        <BaseList
          v-if="portions"
          class="the-recipe-amp__list"
          type="portions"
          title=""
          :items="[portions]"
        />

        <BaseList
          v-for="(item, idx) in recipe.ingredientsSections"
          :key="idx"
          class="the-recipe-amp__list"
          :items="item.ingredients"
          :title="item.title"
          title-tag-name="h2"
        />

        <!-- Реклама внутри рецепта -->
        <component
          :is="'amp-ad'"
          v-if="!isGoogleAdsDisabled"
          class="the-recipe-amp__ad the-recipe-amp__ad_large-margin amp-ad-container amp-ad-container--other-country"
          width="100vw"
          height="320"
          type="adsense"
          :data-ad-client="adsenseAdClient"
          :data-ad-slot="adsenseAdSlot"
          data-auto-format="rspv"
          data-full-width=""
        >
          <div placeholder />
          <div fallback />
        </component>

        <component
          :is="'amp-ad'"
          class="the-recipe-amp__ad the-recipe-amp__ad_large-margin amp-ad-container"
          :class="{ 'amp-ad-container--supported-countries': !isGoogleAdsDisabled }"
          width="340"
          height="300"
          type="adfox"
          data-owner-id="365868"
          data-adfox-params="{'p1': 'dbsfj','p2': 'fqzb'}"
        >
          <div placeholder />
          <div fallback />
        </component>

        <CookingStepsAmp
          class="the-recipe-amp__cooking-steps"
          :steps="recipe.parsedInstructions"
        />
      </section>

      <div class="the-recipe-amp__categories">
        <div class="the-recipe-amp__categories-wrapper">
          <RecipeCategoryLink
            v-for="(item, index) of recipe.breadcrumbs"
            :key="item.title + index"
            :text="item.title + (item.own === 'kitchen' ? ' кухня' : '')"
            :link="item.link"
          />
        </div>
      </div>

      <AmpMessage
        class="the-recipe-amp__message"
        :link="recipeUrl"
      />

      <ArticleNote
        class="the-recipe-amp__notes"
        :notes="notes"
      />

      <ShareBlock
        class="the-recipe-amp__share-block"
        type="recipe"
      />
    </main>

    <!-- Похожие рецепты -->
    <RecommendedPostsContainer
      :id="recipe.id"
      class="the-recipe-amp__recommended-recipes similar"
      :recipes="similar"
      :show-title="false"
      next-page-hide
    />

    <!-- Реклама под рецептом -->
    <component
      :is="'amp-ad'"
      v-if="!isGoogleAdsDisabled"
      class="the-recipe-amp__ad the-recipe-amp__ad_large-margin amp-ad-container--other-country"
      width="100vw"
      height="320"
      type="adsense"
      :data-ad-client="adsenseAdClient"
      :data-ad-slot="adsenseAdSlot"
      data-auto-format="rspv"
      data-full-width=""
    >
      <div placeholder />
      <div fallback />
    </component>

    <component
      :is="'amp-ad'"
      class="the-recipe-amp__ad the-recipe-amp__ad_large-margin"
      width="340"
      height="300"
      type="adfox"
      data-owner-id="365868"
      data-adfox-params="{'p1': 'dbsfj','p2': 'fqzb'}"
    >
      <div placeholder />
      <div fallback />
    </component>

    <CommentsContainerAmp
      :id="recipe.id"
      class="the-recipe-amp__comments"
      :article-url="recipeUrl"
    />

    <!--Рекомендованные посты для рецептов, -->
    <!--выводится специфическая категория, определяется на бэке -->
    <!--.recomended используется для собыйтий в амп, не изменять без правок в yaAmpEvets.ts -->
    <RecommendedPostsContainer
      :id="recipe.id"
      class="the-recipe-amp__recommended-recipes recommended"
      next-page-hide
    />

    <!-- AdFox START -->
    <!--yandex_ponomar-ip-->
    <!--Площадка: Время Есть 2022 / AMP / AMP_Smi2 smi-24-->
    <!--Категория: <не задана>-->
    <!--Тип баннера: Adaptive AMP-->
    <!-- <component
    :is="'amp-ad'"
      class="the-recipe-amp__ad the-recipe-amp__ad_large-margin"
      width="100vw"
      height="320"
      type="adfox"
      next-page-hide
      data-owner-id="365868"
      data-adfox-params="{'p1': 'cwdyg','p2': 'fqzb'}"
    >
      <div placeholder />
      <div fallback />
    </amp-ad> -->

    <RecipeAnalytics />
  </div>
</template>

<script lang="ts">
  import { getImagesBySizes } from '@devhacker/shared/utils/image';
  import CommentsContainerAmp from '~/components/Amp/Comments/CommentsContainer.amp.vue';
  import RecommendedPostsContainer from '~/containers/RecommendedPostsContainer.amp/RecommendedPostsContainer.amp';
  import RecipeCardAmp from '~/components/RecipeCard.amp.vue';
  import AmpMessage from '~/components/AmpMessage.amp.vue';
  import ShareBlock from '~/components/ShareBlock.amp/ShareBlock.amp.vue';
  import ArticleNote, { getArticleNoteProps } from '~/components/ArticleNote.amp.vue';
  import RecipeCategoryLink from '~/components/RecipeCategoryLink.amp.vue';
  import BaseList from '~/components/old-recipes/BaseList.amp.vue';
  import CookingStepsAmp from '~/components/CookingSteps/CookingSteps.amp.vue';
  import RecipeAnalytics from '~/components/Analytics/RecipeAnalytics.amp.vue';
  import PrintButton from '~/components/old-recipes/PrintButton/PrintButton.amp.vue';
  import TheContent from '~/components/content/TheContent';
  import AmpError from '~/components/AmpError.amp.vue';

  import { normalizeFullRecipe } from '~/libs/normalizeRecipe';
  import { ARTICLE_IMAGE_SIZES } from '~/constants/imageSizes';
  import type { TArticleNoteItem } from '~/types';
  import { getPrepTimeFormattedSimplified, trailingSlash } from '~/utils';
  import { sanitizeAmp } from '~/libs/sanitize-amp';
  import { getErrorCodeForFetch } from '~/utils/errors';
  import {
    DISABLE_GOOGLE_ADS,
    AMP_GOOGLE_ADSENSE_AD_CLIENT,
    AMP_GOOGLE_ADSENSE_AD_SLOT_RECIPE,
  } from '~/constants';
  import { getQueryValue } from '~/utils/router';
  import { useAmpCustomElement } from '@devhacker/shared/composables/useAmpCustomElement';

  interface ICookingTimeDescriptionItem {
    title: string;
    description?: string;
    amount: string | number;
  }

  export default defineNuxtComponent({
    name: 'TheRecipeAmp',

    amp: 'only',

    components: {
      RecipeCardAmp,
      CommentsContainerAmp,
      RecommendedPostsContainer,
      AmpMessage,
      ShareBlock,
      ArticleNote,
      RecipeCategoryLink,
      BaseList,
      CookingStepsAmp,
      RecipeAnalytics,
      PrintButton,
      TheContent,
      AmpError,
    },

    async setup() {
      definePageMeta({
        layout: 'default-amp',
      });

      useAmpCustomElement('amp-ad', 'amp-geo');

      const errorCode = ref<number | null>(null);
      const slug = getQueryValue(useRoute().params.slug);
      const similar = ref<RelatedRecipesItem[]>([]);
      const recipe = ref<null | FullRecipe>(null);
      const state = ref();
      const recipeUrl = ref('');
      const toPrintUrl = computed(() => recipeUrl.value + '#toPrint');

      try {
        const {
          data: response,
          status,
          error,
        } = await useAsyncData(() => useNuxtApp().$wordpressApi.fetchRecipe(slug));

        if (error.value || response.value?.status !== 200) {
          console.error(`${response.value?.status} error in pages/TheRecipe.amp.vue`);
          throw new Error(`${response.value?.status} error in pages/TheRecipe.amp.vue`);
        }

        state.value = status;
        recipe.value = normalizeFullRecipe(response.value.data);
        similar.value = response.value.data.similar.slice(0, 4);

        const seo = response.value.data.seo;
        recipeUrl.value = trailingSlash(seo?.canonical ?? '');

        useHead({
          // title
          ...(seo?.title ? { title: seo?.title } : {}),
          meta: [
            // description
            ...(seo?.description
              ? [{ hid: 'description', name: 'description', content: seo?.description }]
              : []),
            // og
            ...(seo?.title ? [{ hid: 'og:title', property: 'og:title', content: seo?.title }] : []),
            ...(seo?.ogImage
              ? [{ hid: 'og:image', property: 'og:image', content: seo?.ogImage }]
              : []),
            ...(seo?.description
              ? [{ hid: 'og:description', property: 'og:description', content: seo?.description }]
              : []),
            ...(seo?.canonical
              ? [{ hid: 'og:url', property: 'og:url', content: seo?.canonical }]
              : []),
            ...(seo?.description
              ? [{ hid: 'description', name: 'description', content: seo?.description }]
              : []),
            // twitter
            ...(seo?.title
              ? [{ hid: 'twitter:title', property: 'twitter:title', content: seo?.title }]
              : []),
            ...(seo?.description
              ? [
                  {
                    hid: 'twitter:description',
                    property: 'twitter:description',
                    content: seo?.description,
                  },
                ]
              : []),
            ...(seo?.ogImage
              ? [{ hid: 'twitter:image', property: 'twitter:image', content: seo?.ogImage }]
              : []),

            { hid: 'twitter:card', property: 'twitter:card', content: 'summary_large_image' },
          ],
          link: [
            // canonical
            ...(seo?.canonical
              ? [{ hid: 'canonical', rel: 'canonical', href: seo?.canonical }]
              : []),
          ],
          script: [
            {
              hid: 'application/ld+json',
              type: 'application/ld+json',
              // class: 'yoast-schema-graph yoast-schema-graph--main',
              innerHTML: JSON.stringify(seo?.schemaGraph),
            },
          ],
          bodyAttrs: {
            class: 'amp-geo-pending amp-iso-country',
          },
          // __dangerouslyDisableSanitizers: ['script'],
        });
      } catch (error) {
        useHead({
          title: 'Страница не найдена - Лайфхакер',
        });

        errorCode.value = getErrorCodeForFetch(error);
        // this.$nuxt.context.res.statusCode = errorCode;
        // this.$sentry.captureException(error);
        console.error(error);
        throw createError({
          statusCode: errorCode.value,
          statusMessage: (error as Error)?.message,
        });
      }

      return {
        recipe,
        similar,
        errorCode,
        state,
        recipeUrl,
        toPrintUrl,
      };
    },

    computed: {
      isGoogleAdsDisabled: () => DISABLE_GOOGLE_ADS,
      adsenseAdClient: () => AMP_GOOGLE_ADSENSE_AD_CLIENT,
      adsenseAdSlot: () => AMP_GOOGLE_ADSENSE_AD_SLOT_RECIPE,

      cover(): {
        normal: string;
        retina: string;
      } {
        if (!this.recipe) {
          return {
            normal: '',
            retina: '',
          };
        }
        const { images } = this.recipe;

        const covers = getImagesBySizes(
          [ARTICLE_IMAGE_SIZES.L.width, ARTICLE_IMAGE_SIZES.L.width * 2],
          images,
        );

        return {
          normal: covers[0]?.url ?? '',
          retina: covers[1]?.url ?? '',
        };
      },

      /**
       * Данные для отображения под статьей.
       *
       * @return Пропсы для компонента ArticleNote
       */
      notes(): TArticleNoteItem[] {
        if (!this.recipe) {
          return [];
        }
        const { caption, customAuthors } = this.recipe;
        const description = 'Над рецептом работали: ';
        return getArticleNoteProps(undefined, { text: caption }, customAuthors, description);
      },

      cookingTimeItems(): ICookingTimeDescriptionItem[] {
        if (!this.recipe) {
          return [];
        }

        const { totalTime, activeTime } = this.recipe;

        const result: ICookingTimeDescriptionItem[] = [
          this.createDescriptionItem('Общее', getPrepTimeFormattedSimplified(totalTime), ''),
        ];
        if (activeTime) {
          result.push(
            this.createDescriptionItem(
              'Активное',
              getPrepTimeFormattedSimplified(activeTime),
              '(требуется ваше участие)',
            ),
          );
        }

        return result;
      },
      portions(): ICookingTimeDescriptionItem | null {
        const portions = this.recipe?.yield;
        if (portions && portions > 0) {
          return this.createDescriptionItem('Количество порций', portions, '');
        }
        return null;
      },
      sanitizedDescription(): TContent[] {
        return sanitizeAmp(this.recipe?.description ?? []);
      },
    },

    methods: {
      createDescriptionItem(
        title: string,
        amount: string | number,
        description?: string,
      ): ICookingTimeDescriptionItem {
        return {
          title,
          amount,
          description,
        };
      },
    },
  });
</script>

<style lang="scss">
  :root {
    --main-color: #{$main};
    --preview-header-color: #a0270f;
    --bg-color-substrate: #fbf8f8;
  }

  body {
    &.amp-geo-pending {
      .amp-ad-container {
        &.amp-ad-container {
          display: none;
        }
      }
    }

    &.amp-iso-country {
      &-ru,
      &-uz,
      &-tm,
      &-kg,
      &-am,
      &-kz,
      &-by,
      &-tj,
      &-md,
      &-az,
      &-ua {
        .amp-ad-container {
          &--supported-countries {
            display: flex;
          }

          &--other-country {
            display: none;
          }
        }
      }

      & {
        &:not(
            .amp-iso-country-ru,
            .amp-iso-country-uz,
            .amp-iso-country-tm,
            .amp-iso-country-kg,
            .amp-iso-country-am,
            .amp-iso-country-kz,
            .amp-iso-country-by,
            .amp-iso-country-tj,
            .amp-iso-country-md,
            .amp-iso-country-az,
            .amp-iso-country-ua
          ) {
          .amp-ad-container {
            &--supported-countries {
              display: none;
            }

            &--other-country {
              display: flex;
            }
          }
        }
      }
    }
  }
</style>

<style lang="scss" scoped>
  .the-recipe-amp {
    max-width: 640px;
    margin: 0 auto 16px;

    &-error {
      max-width: 640px;
      margin: 0 auto;
    }

    &__comments {
      margin-bottom: 16px;
    }
    &__recommended-recipes {
      margin: 16px 0px;
    }
    &__main {
      background: white;
    }
    &__ad {
      display: block;
      @include margin-x(auto);

      &_small-margin {
        @include margin-y(8px);
      }
      &_large-margin {
        @include margin-y(16px);
      }
    }

    &__share-block,
    &__message,
    &__notes,
    &__categories,
    &__description,
    &__list,
    &__cooking-steps,
    &__print-button {
      @include margin-x(16px);
    }

    &__description {
      @include fontNormalText;

      margin-top: 24px;
      margin-bottom: 16px;
    }

    &__list {
      margin-bottom: 24px;
    }

    &__categories {
      margin-bottom: 22px;
    }

    &__notes {
      margin-bottom: 24px;
    }

    &__main {
      padding-bottom: 24px;
      border: 1px solid $borderColor;
      border-top: none;
    }

    &__print-button {
      margin-bottom: 24px;
    }

    &__card {
      width: calc(100% + 2px);
      margin-left: -1px;
    }
    &__categories {
      overflow: hidden;
    }

    &__categories-wrapper {
      margin-left: -8px;
    }
  }

  .the-recipe-amp {
    max-width: 640px;
    margin: 0 auto 16px;

    &-error {
      max-width: 640px;
      margin: 0 auto;
    }

    &__comments {
      margin-bottom: 16px;
    }
    &__recommended-recipes {
      margin: 16px 0px;
    }
    &__main {
      background: white;
    }
    &__ad {
      display: block;
      @include margin-x(auto);

      &_small-margin {
        @include margin-y(8px);
      }
      &_large-margin {
        @include margin-y(16px);
      }
    }

    &__share-block,
    &__message,
    &__notes,
    &__categories,
    &__description,
    &__list,
    &__cooking-steps,
    &__print-button {
      @include margin-x(16px);
    }

    &__description {
      @include fontNormalText;

      margin-top: 24px;
      margin-bottom: 16px;
    }

    &__list {
      margin-bottom: 24px;
    }

    &__categories {
      margin-bottom: 22px;
    }

    &__notes {
      margin-bottom: 24px;
    }

    &__main {
      padding-bottom: 24px;
      border: 1px solid $borderColor;
      border-top: none;
    }

    &__print-button {
      margin-bottom: 24px;
    }

    &__card {
      width: calc(100% + 2px);
      margin-left: -1px;
    }
    &__categories {
      overflow: hidden;
    }

    &__categories-wrapper {
      margin-left: -8px;
    }
  }
</style>

// Importing necessary functions from Pinia and Nuxt app context
import { useNuxtApp } from '#app';
import { defineStore } from 'pinia';
import { wrapPiniaStore } from '@devhacker/shared/utils/wrapPiniaStore';

// Типизировать payload методов
export const useQuickPollStore = wrapPiniaStore(
  defineStore('quickPolls', {
    state: () => ({
      polls: {
        byId: {} as Record<string, QuickPoll>,
      },
      pollsByLocation: {
        byId: {} as Record<string, string>,
      },
    }),

    actions: {
      async fetchQuickPoll(poll: any) {
        try {
          const { $quizApi } = useNuxtApp();

          const response = await $quizApi.getQuickPoll(poll.id);
          const responsePoll = response.data.data.data;

          this.polls.byId[poll.id] = {
            ...responsePoll,
            state: poll.viewed ? 'viewed' : 'initial',
            selectedAnswer: poll.answer ?? null,
            viewed: poll.viewed ?? false,
            location: poll.location,
          };

          if (poll.answer) {
            await this.fetchQuickPollStat(poll.id);
          }
        } catch (error) {
          console.error('Error fetching quick poll:', error);
        }
      },

      async fetchQuickPollStat(quickPollId: number) {
        try {
          const { $quizApi } = useNuxtApp();
          const response = await $quizApi.getQuickPollStatistics(quickPollId);
          const statistics = response.data.data.clicks;

          this.polls.byId[quickPollId].statistics = statistics;
          this.polls.byId[quickPollId].state = 'answered';
        } catch (error) {
          console.error('Error fetching quick poll stats:', error);
        }
      },

      async updateQuickPollAnswer(id: string, answerId: number) {
        try {
          const { $quizApi } = useNuxtApp();
          await $quizApi.setQuickPollAnswerId(answerId);
          this.polls.byId[id].selectedAnswer = answerId;
        } catch (error) {
          console.error('Error updating quick poll answer:', error);
        }
      },

      setQuickPollLocation(payload: any) {
        const { quickPollId, widgetId } = payload;
        this.pollsByLocation.byId[widgetId] = quickPollId;
      },

      setCurrentQuickPoll(poll: any) {
        Object.keys(this.polls.byId).forEach((id) => {
          const pollState = this.polls.byId[id];
          if (pollState.location === poll.location) {
            this.polls.byId[poll.id].isCurrent = pollState.id === poll.id;
          }
        });
      },

      setQuickPollStat(id: string, statistics: QuickPoll['statistics']) {
        this.polls.byId[id].statistics = statistics;
      },

      setQuickPollState(id: string, newState: QuickPoll['state']) {
        this.polls.byId[id].state = newState;
      },

      setQuickPollSelectedAnswer(payload: any) {
        const { quickPollId, answerId } = payload;
        this.polls.byId[quickPollId].selectedAnswer = answerId;
      },

      clearQuickPollLocation() {
        this.pollsByLocation.byId = {};
      },
    },
  }),
);
